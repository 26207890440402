import React, { useEffect, useContext } from 'react'

import styled from 'styled-components'
import Header from '../components/layout/Header'
import { MentorContext } from '../contexts/mentorContext'
import ContactUsImg from '../resources/images/ContactUs.jpg'

const Contact = () => {
  const { programContact } = useContext(MentorContext)
  useEffect(() => {
    document.title = 'Contact Us | California Mentor'
  }, [])
  return (
    <>
      <Header back />
      <ContactStyles>
        {!programContact || programContact.length === 0 ? (
          <h1 className="heading">No program contact information currently available!</h1>
        ) : (
          <>
            <h1 className="heading">Want to know more? Contact Us!</h1>
            <img src={ContactUsImg} alt="" />
            <div className="program-details">
              <h1 className="program-name">{programContact.contactTitle}</h1>
              <p className="program-info">
                {programContact.firstName} {programContact.lastName}, {programContact.position}
              </p>
              <p className="program-info">{programContact.phone}</p>
              <p className="program-info">{programContact.email}</p>
            </div>
          </>
        )}
      </ContactStyles>
    </>
  )
}

const ContactStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 5rem auto;
  margin-bottom: 10rem;
  justify-content: center;
  .heading {
    margin: 2rem auto;
    text-align: center;
    font-family: var(--fontPrimary, fontPrimary);
    font-size: 3rem;
    color: var(--black, black);
  }
  & img {
    border-radius: 0.5rem 0.5rem 0 0;
    width: 100%;
    object-fit: contain;
    max-height: max(10rem, 30vh);
    aspect-ratio: 4/3;
  }
  .program-details {
    background-color: var(--lightGrey, lightGrey);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 10px;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .program-name {
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeDescription, fontSizeDescription);
    color: var(--primary, primary);
    margin-bottom: 1rem;
  }
  .program-info {
    font-family: var(--fontPrimary, fontPrimary);
    font-size: 1.5rem;
    color: var(--black, black);
    margin: 0.2rem 0;
  }
`

export default Contact
