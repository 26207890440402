import React, { useEffect, useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Header from '../components/layout/Header'
import Story from '../components/welcome/Story'
import Hero from '../components/hero/Hero'
import HeroBox from '../components/hero/HeroBox'
import { Button } from '@material-ui/core'

import RegionSelectApiDropdown from '../components/RegionSelectApiDropdown'
import welcomeImage from '../resources/images/Welcome_Home_Life.jpg'

import { ProgramContext } from '../contexts/programContext'
import { AuthContext } from '../contexts/authContext'
import { RegionContext } from '../contexts/regionContext'
import Loader from '../libs/loader'

const boxDetails = {
  title: 'Welcome to',
  title2: "California MENTOR's",
  titleBlue: 'FHA Home Life',
  description: `Discover Family Host Home living!  Get a glimpse of
                our available FHA homes, as well as the type of providers we are proud
                to be working with.  Our goal is to ensure that the pre-placement process
                is informative, thoughtful, and simple.`,
}

const Welcome = () => {
  const { authStatus } = useContext(AuthContext)
  const { region, setCurrentRegion } = useContext(RegionContext)
  const { programs, isLoadingPrograms } = useContext(ProgramContext)

  useEffect(() => {
    document.title = 'Welcome | California Mentor'
  }, [])

  return (
    <>
      <Header
        color="#FFB767"
        link={true}
        title="How California MENTOR is responding to COVID-19"
        href="https://www.thementornetwork.com/coronavirus/"
      />
      <Hero src={welcomeImage} alt="Welcome page Hero">
        <HeroBox
          title={boxDetails.title}
          title2={boxDetails.title2}
          titleBlue={boxDetails.titleBlue}
          description={boxDetails.description}
        />
      </Hero>
      <Story />
      <RegionStyles>
        <div>
          <h2 className="title">Choose your regional center to view the FHA program in your area.</h2>
        </div>
        {isLoadingPrograms ? (
          <Loader />
        ) : (
          <>
            <div className="dropdown">
              <RegionSelectApiDropdown outlined font18 />
            </div>
            <div>
              {programs && programs.length > 1 ? (
                <Link to="/region" onClick={() => setCurrentRegion(region)}>
                  <Button
                    style={{
                      backgroundColor: 'rgb(8, 102, 173)',
                      color: '#FFFFFF',
                      padding: '.5rem 5.5rem',
                      fontSize: '1.6rem',
                    }}
                    size="large"
                    variant="contained">
                    View
                  </Button>
                </Link>
              ) : (
                <>
                  {(authStatus && authStatus === 3) || (authStatus && authStatus === 1) ? (
                    <Link to="/" onClick={() => setCurrentRegion(region)}>
                      <Button
                        style={{
                          backgroundColor: 'rgb(8, 102, 173)',
                          color: '#FFFFFF',
                          padding: '.5rem 5.5rem',
                          fontSize: '1.6rem',
                        }}
                        size="large"
                        variant="contained">
                        View
                      </Button>
                    </Link>
                  ) : (
                    <Link to="/signin" onClick={() => setCurrentRegion(region)}>
                      <Button
                        style={{
                          backgroundColor: 'rgb(8, 102, 173)',
                          color: '#FFFFFF',
                          padding: '.5rem 5.5rem',
                          fontSize: '1.6rem',
                        }}
                        size="large"
                        variant="contained">
                        View
                      </Button>
                    </Link>
                  )}
                </>
              )}

              {/* )} */}
            </div>
          </>
        )}
      </RegionStyles>
    </>
  )
}
const RegionStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5rem;
  .title {
    color: var(--black, black);
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeTitle, fontSizeTitle);
    margin-bottom: 1rem;
    font-weight: 600;
  }
  .dropdown {
    color: var(--black, black);
    font-family: var(--fontPrimary, fontPrimary);
    margin: 3rem;
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 103px;
  }
`

export default Welcome
