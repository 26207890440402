import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { useLocalStorageState } from '../../hooks/useLocalStorageState'

const SevitaBanner = () => {
  const classes = useStyles()
  const [openBanner, setOpenBanner] = useLocalStorageState('openBanner', true)

  const handleClose = (event) => {
    setOpenBanner(false)
  }

  return (
    <Fragment>
      {openBanner ? (
        <SevitaBannerStyles>
          <div className="alert-content">
            <h5 className="heading">The MENTOR Network is now Sevita</h5>
            <a href="https://sevitahealth.com/brand" rel="noreferrer" target="_blank" className="more">
              <span>Learn More about our new brand</span>
            </a>
          </div>
          <CloseIcon className={classes.icon} onClick={handleClose} />
        </SevitaBannerStyles>
      ) : null}
    </Fragment>
  )
}

const SevitaBannerStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
  padding: 5rem 10rem;
  .heading {
    color: #007f6e;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.6;
    font-family: var(--fontUtileDisplaySemibold, fontUtileDisplaySemibold);
  }
  a {
    color: #ee8922;
    display: inline-block;
    padding-right: 33px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIyIDIyIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGZpbGw9IiNFRDhCMDAiIGQ9Ik0xMy42MyAxMC45OTRMOC43MzQgMTUuODljLS4yNy4yNy0uMjcuNzA0IDAgLjk3Mi4yNjguMjcuNzAzLjI3Ljk3MiAwTDE0Ljk3IDExLjZjLjE2NS0uMTY1LjIxNi0uMzkuMTc5LS42MDUuMDM4LS4yMTItLjAxNC0uNDM5LS4xNzktLjYwNEw5LjcwNiA1LjEyNWMtLjI2OS0uMjY4LS43MDQtLjI2OC0uOTcyIDAtLjI3LjI3LS4yNy43MDQgMCAuOTc0bDQuODk2IDQuODk1ek0wIDExYzAgNi4wNzUgNC45MjQgMTEgMTEgMTFzMTEtNC45MjUgMTEtMTFTMTcuMDc1IDAgMTEgMCAwIDQuOTI1IDAgMTF6bTEuMzc1IDBjMC01LjMxNiA0LjMxLTkuNjI1IDkuNjI1LTkuNjI1IDUuMzE2IDAgOS42MjUgNC4zMSA5LjYyNSA5LjYyNSAwIDUuMzE2LTQuMzEgOS42MjUtOS42MjUgOS42MjUtNS4zMTYgMC05LjYyNS00LjMxLTkuNjI1LTkuNjI1eiIvPjwvc3ZnPg==);
    background-position: calc(100% - 10px) 3px;
    background-size: 17px;
    background-position-y: 2px;
    background-repeat: no-repeat;
    transition: all 0.5s;
    :hover {
      color: #007f6e;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDIyIDIyIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGZpbGw9IiMwMDgxNkQiIGQ9Ik0xMy42MyAxMC45OTRMOC43MzQgMTUuODljLS4yNy4yNy0uMjcuNzA0IDAgLjk3Mi4yNjguMjcuNzAzLjI3Ljk3MiAwTDE0Ljk3IDExLjZjLjE2NS0uMTY1LjIxNi0uMzkuMTc5LS42MDUuMDM4LS4yMTItLjAxNC0uNDM5LS4xNzktLjYwNEw5LjcwNiA1LjEyNWMtLjI2OS0uMjY4LS43MDQtLjI2OC0uOTcyIDAtLjI3LjI3LS4yNy43MDQgMCAuOTc0bDQuODk2IDQuODk1ek0wIDExYzAgNi4wNzUgNC45MjQgMTEgMTEgMTFzMTEtNC45MjUgMTEtMTFTMTcuMDc1IDAgMTEgMCAwIDQuOTI1IDAgMTF6bTEuMzc1IDBjMC01LjMxNiA0LjMxLTkuNjI1IDkuNjI1LTkuNjI1IDUuMzE2IDAgOS42MjUgNC4zMSA5LjYyNSA5LjYyNSAwIDUuMzE2LTQuMzEgOS42MjUtOS42MjUgOS42MjUtNS4zMTYgMC05LjYyNS00LjMxLTkuNjI1LTkuNjI1eiIvPjwvc3ZnPg==);
    }
  }
  .more {
    font-size: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #ee8922;
    line-height: 1.6;
    font-family: var(--fontPoppins, fontPoppins);
    font-weight: 600;
  }
`

const useStyles = makeStyles(() => ({
  icon: {
    color: '#ee8922',
    fontSize: '3rem',
    margin: ' 0 1rem',
    transition: '0.3s',
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
      color: '#007f6e',
    },
  },
}))
export default SevitaBanner
