import React from 'react'
import './App.css'
import GlobalStyles from './GlobalStyles'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { createTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles'
// import CssBaseline from '@material-ui/core/CssBaseline'

import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn, AuthIsAdmin } from './contexts/authContext'
import RegionProvider from './contexts/regionContext'
import ProgramProvider from './contexts/programContext'
import CaseManagersProvider from './contexts/caseManagersContext'
import MentorEventProvider from './contexts/mentorContext'
import CaseManagerInfoProvider from './contexts/caseManagerInfoContext'
import HomesProvider from './contexts/homesContext'

import ScrollToTop from './Hoc/ScrollToTop'

import SignIn from './routes/auth/signIn'
import Register from './routes/auth/register'
import VerifyCode from './routes/auth/verify'

import RequestCode from './routes/auth/requestCode'
import ForgotPassword from './routes/auth/forgotPassword'
// import ChangePassword from './routes/auth/changePassword'

import Welcome from './routes/Welcome.jsx'
import VirtualTour from './routes/VirtualTour'
import HomeDetail from './routes/HomeDetail.jsx'
import Contact from './routes/Contact'
import Mentors from './routes/mentors/Mentors'
import RegisterMentorEvent from './routes/mentors/RegisterMentorEvent'
import Region from './routes/region/Region'
import Landing from './routes/landing/Landing'

import CaseManagers from './routes/admin/caseManagers/CaseManagers'
import editCaseManagers from './routes/admin/caseManagers/editCaseManagers'
import addEditMentorEvents from './routes/admin/addEditMentorEvents'
import MentorEventRegistrations from './routes/admin/MentorEventRegistrations'

import addHomes from './routes/admin/homes/AddHomes'
import HomeList from './routes/homeList/HomeList'
import HomePreferenceForm from './routes/HomePreferenceForm'
import Map from './routes/Map'

import GlobalFooter from './components/layout/GlobalFooter'
import GlobalHeader from './components/layout/GlobalHeader'
import NotFound from './routes/NotFound'
import SevitaBanner from './components/layout/SevitaBanner'

let lightTheme = createTheme({
  typography: {
    fontSize: 22,
  },
  overrides: {
    // typography: {
    //   fontSize: '1.5em',
    // },
    // MuiInput: {
    //   fontSize: '1.2em',
    // },
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },
  },
  palette: {
    type: 'light',
    primary: {
      contrastText: '#ffffff',
      main: 'rgb(8, 102, 173)',
    },
    secondary: {
      contrastText: '#ffffff',
      main: 'rgb(199, 36, 42)',
    },
  },
})
lightTheme = responsiveFontSizes(lightTheme)

const SignInRoute: React.FunctionComponent = () => (
  <Router>
    <ScrollToTop />
    <SevitaBanner />
    <GlobalHeader />
    <Switch>
      <Route path="/signin" exact component={SignIn} />
      <Route path="/register" exact component={Register} />
      <Route path="/verify" exact component={VerifyCode} />
      <Route path="/requestcode" exact component={RequestCode} />
      <Route path="/forgotpassword" exact component={ForgotPassword} />
      <Route path="/region" exact component={Region} />
      <Route path="/welcome" exact component={Welcome} />
      <Route path="/" exact component={Welcome} />
      <Route component={NotFound} />
    </Switch>
    <GlobalFooter />
  </Router>
)

const CaseManagerRoute: React.FunctionComponent = () => (
  <Router>
    <ScrollToTop />
    <SevitaBanner />
    <GlobalHeader />
    <Switch>
      {/* <Route path="/changepassword" exact component={ChangePassword} /> */}
      <Route path="/home-list" exact component={HomeList} />
      <Route path="/home-preference" exact component={HomePreferenceForm} />
      <Route path="/region" exact component={Region} />
      <Route path="/virtual-tour" exact component={VirtualTour} />
      <Route path="/home-detail" exact component={HomeDetail} />
      <Route path="/home-detail/map" exact component={Map} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/mentors/register" exact component={RegisterMentorEvent} />
      <Route path="/mentors" exact component={Mentors} />
      <Route path="/welcome" exact component={Welcome} />
      <Route path="/" exact component={Landing} />
      <Route component={NotFound} />
    </Switch>
    <GlobalFooter />
  </Router>
)
const AdminRoute: React.FunctionComponent = () => (
  <Router>
    <ScrollToTop />
    <SevitaBanner />
    <GlobalHeader />
    <Switch>
      <Route path="/home-list" exact component={HomeList} />
      <Route path="/home-preference" exact component={HomePreferenceForm} />
      <Route path="/region" exact component={Region} />
      <Route path="/virtual-tour" exact component={VirtualTour} />
      <Route path="/home-detail" exact component={HomeDetail} />
      <Route path="/home-detail/map" exact component={Map} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/mentors" exact component={Mentors} />

      <Route path="/admin-case-managers" exact component={CaseManagers} />
      <Route path="/admin-case-managers/edit" exact component={editCaseManagers} />

      <Route path="/admin-mentor-events/add" exact component={addEditMentorEvents} />
      <Route path="/admin-mentor-events/edit" exact component={addEditMentorEvents} />
      <Route path="/admin-mentor-events/registrations" exact component={MentorEventRegistrations} />

      <Route path="/admin-homes/edit" exact component={addHomes} />
      <Route path="/admin-homes/add" exact component={addHomes} />

      {/* <Route path="/changepassword" exact component={ChangePassword} /> */}
      <Route path="/welcome" exact component={Welcome} />
      <Route path="/" exact component={Landing} />
      <Route component={NotFound} />
    </Switch>
    <GlobalFooter />
  </Router>
)

const App: React.FunctionComponent = () => (
  <ThemeProvider theme={lightTheme}>
    {/* <CssBaseline /> */}
    <GlobalStyles />
    <ToastContainer />
    <AuthProvider>
      <RegionProvider>
        <ProgramProvider>
          <AuthIsSignedIn>
            <CaseManagerInfoProvider>
              <MentorEventProvider>
                <HomesProvider>
                  <CaseManagerRoute />
                </HomesProvider>
              </MentorEventProvider>
            </CaseManagerInfoProvider>
          </AuthIsSignedIn>
          <AuthIsAdmin>
            <CaseManagersProvider>
              <MentorEventProvider>
                <HomesProvider>
                  <AdminRoute />
                </HomesProvider>
              </MentorEventProvider>
            </CaseManagersProvider>
          </AuthIsAdmin>
          <AuthIsNotSignedIn>
            <SignInRoute />
          </AuthIsNotSignedIn>
        </ProgramProvider>
      </RegionProvider>
    </AuthProvider>
  </ThemeProvider>
)

export default App
