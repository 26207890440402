import React, { useState } from 'react'
import { useLocalStorageState } from '../hooks/useLocalStorageState'

const defaultState = {
  homesInitialStatus: { Available: 'Y', Unavailable: 'N', InProgress: 'Y' },
}

export const HomesContext = React.createContext(defaultState)

const HomesProvider = ({ children }) => {
  const [comingSoonHomes, setComingSoonHomes] = useLocalStorageState('comingSoonHomes', [])
  const [availableHomes, setAvailableHomes] = useLocalStorageState('availableHomes', [])
  const [unavailableHomes, setUnavailableHomes] = useLocalStorageState('unavailableHomes', [])
  const [availableLastEvaluatedKey, setAvailableLastEvaluatedKey] = useLocalStorageState(
    'availableLastEvaluatedKey',
    {}
  )
  const [comingSoonLastEvaluatedKey, setComingSoonLastEvaluatedKey] = useLocalStorageState(
    'comingSoonLastEvaluatedKey',
    {}
  )
  const [unavailableLastEvaluatedKey, setUnavailableLastEvaluatedKey] = useLocalStorageState(
    'unavailableLastEvaluatedKey',
    {}
  )
  const [homeToUpdate, setHomeToUpdate] = useLocalStorageState('homeToUpdate', [])
  const [homeDetails, setHomeDetails] = useLocalStorageState('homeDetails', [])
  const [homeStatus, setHomeStatus] = useState(defaultState.homesInitialStatus)

  const [homePreferences, setHomePreferences] = useLocalStorageState('homePreferences', [])
  const [preferenceSearch, setPreferenceSearch] = useLocalStorageState('preferenceSearch', false)

  const [availablePaginated, setAvailablePaginated] = useLocalStorageState('availablePaginated', false)
  const [unavailablePaginated, setUnavailablePaginated] = useLocalStorageState('unavailablePaginated', false)
  const [comingSoonPaginated, setComingSoonPaginated] = useLocalStorageState('comingSoonPaginated', false)

  const [availablePage, setAvailablePage] = useState(1)
  const [unavailablePage, setUnavailablePage] = useState(1)
  const [inProcessPage, setInProcessPage] = useState(1)

  const state = {
    comingSoonHomes,
    availableHomes,
    unavailableHomes,
    homeToUpdate,
    homeStatus,
    homeDetails,
    setHomeStatus,
    setComingSoonHomes,
    setAvailableHomes,
    setUnavailableHomes,
    setHomeToUpdate,
    setHomeDetails,
    availableLastEvaluatedKey,
    setAvailableLastEvaluatedKey,
    comingSoonLastEvaluatedKey,
    setComingSoonLastEvaluatedKey,
    unavailableLastEvaluatedKey,
    setUnavailableLastEvaluatedKey,
    homePreferences,
    setHomePreferences,
    preferenceSearch,
    setPreferenceSearch,
    availablePaginated,
    setAvailablePaginated,
    unavailablePaginated,
    setUnavailablePaginated,
    comingSoonPaginated,
    setComingSoonPaginated,
    availablePage,
    setAvailablePage,
    unavailablePage,
    setUnavailablePage,
    inProcessPage,
    setInProcessPage,
  }

  return <HomesContext.Provider value={state}>{children}</HomesContext.Provider>
}

export default HomesProvider
