import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Paper,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import Header from '../components/layout/Header'
import { selectErrorHelper, selectIsError } from '../libs/Utils'
import { BASE_URL } from '../libs/tools'
import { showSuccessToast, showErrorToast } from '../libs/Utils'

import { AuthContext } from '../contexts/authContext'
import { RegionContext } from '../contexts/regionContext'
import { ProgramContext } from '../contexts/programContext'
import { HomesContext } from '../contexts/homesContext'

const defaultValues = {
  BdsAvil: '',
  TotBeds: '',
  FullBaths: '',
  Stairs: '',
  SepBath: '',
  Cats: '',
  Dogs: '',
  MultPers: '',
}
const getPreferenceValues = (val) => {
  const keys = Object.keys(val)
  const result = []
  keys.forEach((key, index) => {
    if (val[key] === 'Y' || val[key] === 'N') {
      result.push({
        [key]: val[key],
        eq: '=',
      })
    } else if (val[key].length > 0) {
      result.push({
        [key]: val[key],
        eq: '>=',
      })
    }
  })
  return result
}

const HomePreferenceForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [values, setValues] = useState(defaultValues)
  const { region } = useContext(RegionContext)
  const { program } = useContext(ProgramContext)
  const { authStatus, sessionInfo, getRefreshSession } = useContext(AuthContext)
  const isAdmin = authStatus && authStatus === 3
  const localStorageToken = window.localStorage.getItem('accessToken')

  const {
    setComingSoonHomes,
    setAvailableHomes,
    setUnavailableHomes,
    setAvailableLastEvaluatedKey,
    setComingSoonLastEvaluatedKey,
    setUnavailableLastEvaluatedKey,
    setHomePreferences,
    setPreferenceSearch,
    setAvailablePage,
  } = useContext(HomesContext)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values,
    validationSchema: Yup.object({
      BdsAvil: Yup.number(),
      TotBeds: Yup.number(),
      FullBaths: Yup.number(),
      Stairs: Yup.string(),
      SepBath: Yup.string(),
      MultPers: Yup.string(),
      Cats: Yup.number(),
      Dogs: Yup.number(),
    }),
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm)
      // resetPreferences(resetForm)
    },
  })

  const submitForm = (values, resetForm) => {
    let isActive = true
    const preferences = getPreferenceValues(values)

    const getPreferecesHomesData = async () => {
      setIsLoading(true)
      try {
        const statusData = {
          key: `ORG#CSS#ST#CA#REG#${region}#PROG#${program}`,
          statuses: isAdmin
            ? {
                Available: 'Y',
                Unavailable: 'Y',
                InProgress: 'Y',
              }
            : {
                Available: 'Y',
                Unavailable: 'N',
                InProgress: 'Y',
              },
          preferences,
          //  [
          //   {
          //     TotBeds: 'values.TotBeds',
          //     eq: '>=',
          //   },
          //   // values.BdsAvil.length > 0 && {
          //   //   BdsAvil: values.BdsAvil,
          //   //   eq: '>=',
          //   // },
          //   {
          //     FullBaths: '0',
          //     eq: '>=',
          //   },
          //   // {
          //   //   Stairs: values.Stairs,
          //   //   eq: '=',
          //   // },
          //   // {
          //   //   Cats: values.Cats,
          //   //   eq: '>=',
          //   // },
          //   // {
          //   //   Dogs: values.Dogs,
          //   //   eq: '>=',
          //   // },
          //   // {
          //   //   SepBath: values.SepBath,
          //   //   eq: '=',
          //   // },
          //   // {
          //   //   MultPers: values.MultPers,
          //   //   eq: '=',
          //   // },
          // ],
          lastEvaulatedKey: {},
        }
        const response = await axios.post(`${BASE_URL}/casemanagers/getHomes`, statusData, {
          headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
        })
        console.log('🚀 ~ file: HomePreferenceForm.jsx ~ line 156 ~ getPreferecesHomesData ~ response', response)
        console.log('🚀 ~ file: HomePreferenceForm.jsx ~ line 156 ~ getPreferecesHomesData ~ response', response.status)
        const AvailableLastEvaluatedKey =
          response.data.results[0].response?.LastEvaluatedKey === undefined
            ? {}
            : response.data.results[0].response.LastEvaluatedKey

        const ComingSoonLastEvaluatedKey =
          response.data.results[2].response?.LastEvaluatedKey === undefined
            ? {}
            : response.data.results[2].response.LastEvaluatedKey

        const UnavailableLastEvaluatedKey =
          response.data.results[1].response?.LastEvaluatedKey === undefined
            ? {}
            : response.data.results[1].response.LastEvaluatedKey

        const isAvailableHomesDataReceived =
          Object.keys(response.data.results[0]).length !== 0 ? response.data.results[0].response.Items : []
        const isComingSoonHomesDataReceived =
          Object.keys(response.data.results[2]).length !== 0 ? response.data.results[2].response.Items : []
        const isUnavailablHomesDataReceived =
          Object.keys(response.data.results[1]).length !== 0 ? response.data.results[1].response.Items : []
        if (isActive) {
          await setAvailableHomes(isAvailableHomesDataReceived)
          await setAvailableLastEvaluatedKey(AvailableLastEvaluatedKey)
        }
        if (isActive) {
          await setComingSoonHomes(isComingSoonHomesDataReceived)
          await setComingSoonLastEvaluatedKey(ComingSoonLastEvaluatedKey)
        }
        if (isActive) {
          await setUnavailableHomes(isUnavailablHomesDataReceived)
          await setUnavailableLastEvaluatedKey(UnavailableLastEvaluatedKey)
        }
        if (
          isUnavailablHomesDataReceived.length === 0 &&
          isComingSoonHomesDataReceived.length === 0 &&
          isAvailableHomesDataReceived.length === 0
        ) {
          showErrorToast('No Homes Found Matching Your Preferences, Please Search Again')
        } else {
          showSuccessToast('Preferences Homes Returned')
          setHomePreferences(preferences)
          setPreferenceSearch(true)
          setAvailablePage(1)
          // resetForm()
          if (response.status === 200) {
            history.push({
              pathname: '/home-list',
              state: { prevRoute: 'home-preference' },
            })
          }
        }
        setIsLoading(false)
      } catch (err) {
        // console.log('🚀 ~ file: HomePreferenceForm.jsx ~ line 211 ~ getPreferecesHomesData ~ err', err)
        // console.log('🚀 ~ file: Response Status ~ err', err.response.status)
          // const get403 = err && err?.toString().replace(/[^0-9]/g, '')
        if (err && err.response.status === 403) {
          getRefreshSession()
          showErrorToast('Error Getting Homes,Please Try Again')
          setIsLoading(false)
        } else {
          showErrorToast('Error Getting Homes')
          setIsLoading(false)
        }
        showErrorToast('Error Last Handler')
        setIsLoading(false)
      }
    }
    if (isActive) {
      getPreferecesHomesData()
    }
    return () => {
      isActive = false
    }
  }
  useEffect(() => {
    document.title = 'Preference Form | California Mentor'
  }, [])
  const history = useHistory()
  return (
    <>
      <Header back color="white" size="2rem" title="Preference Form" />
      <Grid container style={{ marginBottom: 60 }} direction="row" justifyContent="center" alignItems="center">
        <Grid xs={11} sm={6} lg={4} container direction="row" justifyContent="space-between" alignItems="center" item>
          <Paper style={{ width: '100%', padding: 16, marginTop: 60, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
            <Grid container direction="column" justifyContent="space-between" alignItems="center">
              {/* Title */}

              <form onSubmit={formik.handleSubmit}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Box m={2} direction="row">
                    <Typography
                      style={{ color: 'rgb(66, 66, 66)', fontWeight: 500, fontFamily: 'Roboto' }}
                      variant="h6">{`Number Of Bedrooms Available`}</Typography>
                  </Box>
                  <Box m={2} direction="row">
                    <FormControl error={selectIsError(formik, 'BdsAvil')} style={{ width: '100%' }}>
                      <Select id="BdsAvil" name="BdsAvil" variant="outlined" {...formik.getFieldProps('BdsAvil')}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="6">6+</MenuItem>
                      </Select>
                      {selectErrorHelper(formik, 'BdsAvil')}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Box m={2} direction="row">
                    <Typography
                      style={{ color: 'rgb(66, 66, 66)', fontWeight: 500, fontFamily: 'Roboto' }}
                      variant="h6">{`Number Of Bedrooms `}</Typography>
                  </Box>
                  <Box m={2} direction="row">
                    <FormControl error={selectIsError(formik, 'TotBeds')} style={{ width: '100%' }}>
                      <Select id="TotBeds" name="TotBeds" variant="outlined" {...formik.getFieldProps('TotBeds')}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="6">6+</MenuItem>
                      </Select>
                      {selectErrorHelper(formik, 'TotBeds')}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Box m={2} direction="row">
                    <Typography
                      style={{ color: 'rgb(66, 66, 66)', fontWeight: 500, fontFamily: 'Roboto' }}
                      variant="h6">{`Number Of Full Bathrooms `}</Typography>
                  </Box>
                  <Box m={2} direction="row">
                    <FormControl error={selectIsError(formik, 'FullBaths')} style={{ width: '100%' }}>
                      <Select id="FullBaths" name="FullBaths" variant="outlined" {...formik.getFieldProps('FullBaths')}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4+</MenuItem>
                      </Select>
                      {selectErrorHelper(formik, 'FullBaths')}
                    </FormControl>
                  </Box>
                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Box m={2} direction="row">
                    <Typography
                      style={{ color: 'rgb(66, 66, 66)', fontWeight: 500, fontFamily: 'Roboto' }}
                      variant="h6">{`Wheelchair Accessible?`}</Typography>
                  </Box>
                  <Box m={2} direction="row">
                    <FormControl error={selectIsError(formik, 'Stairs')} style={{ width: '100%' }}>
                      <Select id="Stairs" name="Stairs" variant="outlined" {...formik.getFieldProps('Stairs')}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Y">Yes</MenuItem>
                        <MenuItem value="N">No</MenuItem>
                      </Select>
                      {selectErrorHelper(formik, 'Stairs')}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Box m={2} direction="row">
                    <Typography
                      style={{ color: 'rgb(66, 66, 66)', fontWeight: 500, fontFamily: 'Roboto' }}
                      variant="h6">{`Separate Bath?`}</Typography>
                  </Box>
                  <Box m={2} direction="row">
                    <FormControl error={selectIsError(formik, 'SepBath')} style={{ width: '100%' }}>
                      <Select id="SepBath" name="SepBath" variant="outlined" {...formik.getFieldProps('SepBath')}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Y">Yes</MenuItem>
                        <MenuItem value="N">No</MenuItem>
                      </Select>
                      {selectErrorHelper(formik, 'SepBath')}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Box m={2} direction="row">
                    <Typography
                      style={{ color: 'rgb(66, 66, 66)', fontWeight: 500, fontFamily: 'Roboto' }}
                      variant="h6">{`Cats Ok?`}</Typography>
                  </Box>
                  <Box m={2} direction="row">
                    <FormControl error={selectIsError(formik, 'Cats')} style={{ width: '100%' }}>
                      <Select id="Cats" name="Cats" variant="outlined" {...formik.getFieldProps('Cats')}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="1">Yes</MenuItem>
                        <MenuItem value="0">No</MenuItem>
                      </Select>
                      {selectErrorHelper(formik, 'Cats')}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Box m={2} direction="row">
                    <Typography
                      style={{ color: 'rgb(66, 66, 66)', fontWeight: 500, fontFamily: 'Roboto' }}
                      variant="h6">{`Dogs Ok?`}</Typography>
                  </Box>
                  <Box m={2} direction="row">
                    <FormControl error={selectIsError(formik, 'Dogs')} style={{ width: '100%' }}>
                      <Select id="Dogs" name="Dogs" variant="outlined" {...formik.getFieldProps('Dogs')}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="1">Yes</MenuItem>
                        <MenuItem value="0">No</MenuItem>
                      </Select>
                      {selectErrorHelper(formik, 'Dogs')}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Box m={2} direction="row">
                    <Typography
                      style={{ color: 'rgb(66, 66, 66)', fontWeight: 500, fontFamily: 'Roboto' }}
                      variant="h6">{`Multiple Person`}</Typography>
                  </Box>
                  <Box m={2} direction="row">
                    <FormControl error={selectIsError(formik, 'MultPers')} style={{ width: '100%' }}>
                      <Select
                        id="MultPers"
                        name="MultPers"
                        variant="outlined"
                        // displayEmpty
                        {...formik.getFieldProps('MultPers')}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {/* <MenuItem value="" disabled></MenuItem> */}
                        <MenuItem value="Y">Yes</MenuItem>
                        <MenuItem value="N">No</MenuItem>
                      </Select>
                      {selectErrorHelper(formik, 'MultPers')}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Box m={1}>
                    <Button
                      disabled={isLoading}
                      style={{ marginRight: 20 }}
                      type="submit"
                      variant="contained"
                      color="primary">
                      {isLoading && (
                        <>
                          <h4 style={{ marginRight: '10px', fontFamily: 'Roboto', fontSize: '13px' }}>Search</h4>{' '}
                          <CircularProgress style={{ color: 'white' }} size={20} />{' '}
                        </>
                      )}
                      {!isLoading && 'Search'}
                    </Button>
                    <Button
                      onClick={() => history.goBack()}
                      color="secondary"
                      variant="contained"
                      disabled={isLoading}
                      style={{ marginRight: 20 }}>
                      {isLoading && (
                        <>
                          <h4 style={{ marginRight: '10px', fontFamily: 'Roboto', fontSize: '13px' }}>Cancel</h4>{' '}
                          <CircularProgress style={{ color: 'white' }} size={20} />{' '}
                        </>
                      )}
                      {!isLoading && 'Cancel'}
                    </Button>
                  </Box>
                </Grid>
              </form>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default HomePreferenceForm
