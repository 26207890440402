import { createGlobalStyle } from 'styled-components'
import PoppinsMedium from './resources/fonts/Poppins-Medium.ttf'
import UtileDisplaySemibold from './resources/fonts/UtileDisplay-Semibold.woff'
import UtileDisplaySemibold2 from './resources/fonts/UtileDisplay-Semibold.woff2'

const GlobalStyles = createGlobalStyle`

@font-face {
        font-family: 'poppins';
        src: url(${PoppinsMedium}) format('truetype');
    }

 @font-face {
  font-family: 'UtileDisplay-Semibold';
  src:  url(${UtileDisplaySemibold}) format('woff') ;
  src:  url(${UtileDisplaySemibold2}) format('woff2') ;
 }

  html {
    --red: rgb(199, 36, 42);
    --red: rgb(199, 36, 42);
    --redLight: rgb(236,28,36);
    --primary: rgb(8, 102, 173);
    --primaryDark: rgb(16, 81, 130);
    --primaryLight: rgb(89,183,232);
    --secondary: rgb(255,127,39);
    --secondary-dark: rgb(217, 121, 56);
    --green: rgb(16,108,103);
    --greenLight: rgb(23, 145, 139);
    --gainsboro:rgb(209,228,237);
    --black: rgb(66, 66, 66);
    --lavender: rgb(230,241,245);
    --gray: var(--grey);
    --lightGrey: #e1e1e1;
    --lightGray: var(--lightGrey);
    --offWhite: #ededed;
    --white: rgb(254,254,254);
    --maxWidth: 1000px;
    --bs: 0 12px 24px 0 rgba(0,0,0,0.09);
    --bpLargest: 1200px;
    --fontPrimary:"Roboto" , "sans-serif";
    ${'' /* --fontPrimary:"Roboto" , "sans-serif" !important; */}
    --fontPrimaryBold:"Roboto", "sans-serif" ;
    --fontItalic:"Dancing Script", "sans-serif" ;
    --fontPoppins: "poppins" !important;
    --fontUtileDisplaySemibold: "UtileDisplay-Semibold", "serif", "sans-serif";
    --fontSizeDisplay:4.5rem;
    --fontSizeDisplayBlue:5.5rem;
    --fontSizeDescription:1.9rem;
    --fontSizeTitle:2.3rem;
    --fontSize:5.8rem;
    box-sizing: border-box;
    font-size: 62.5%;
    scroll-behavior: smooth;
    @media (max-width: 1200px) {
        font-size: 50%};
      }
  }
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    font-family: 'Roboto' !important;
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    line-height: 1.2;
    background-color:'white' !important;
  }
  a {
    text-decoration: none;
    color: var(--black,black);
  }
  a:hover {
    text-decoration: none;
  }
  button {
    font-family: 'Roboto', "sans-serif "!important;
  }
   .link {
    color: var(--secondary,secondary);
    font-size: var(--fontSizeDescription, fontSizeDescription);
  }
`

export default GlobalStyles
