import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const LandingCards = ({ title, description, img, link }) => {
  return (
    <LandingCardStyles>
      <Link to={link}>
        <img src={img} alt="" />
        <div className="info-container">
          <h3 className="title">{title}</h3>
          <p className="description">{description}</p>
        </div>
      </Link>
    </LandingCardStyles>
  )
}

const LandingCardStyles = styled.li`
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* padding-bottom: 1rem; */
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s;

  &:hover {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  }
  & img {
    border-radius: 0.5rem 0.5rem 0 0;
    width: 100%;
    object-fit: cover;
    max-height: max(10rem, 30vh);
    aspect-ratio: 4/3;
  }

  /* & img ~ * {
    margin-left: 1rem;
    margin-right: 1rem;
  } */

  & > :last-child {
    margin-bottom: 0;
  }

  .title {
    font-family: var(--fontPrimary, fontPrimary);
    font-size: 2.5rem;
    text-transform: uppercase;
    margin: 2rem;
    color: var(--green, green);
    /* color: #106c67; */
    /* text-decoration:underline; */
    font-weight: 700;
  }
  .description {
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeDescription, fontSizeDescription);
    margin: 1.3rem;
    color: var(--black, black);
    margin-bottom: 3rem;
    text-align: center;
  }
`

export default LandingCards
