import React, { useState } from 'react'
import { useLocalStorageState } from '../hooks/useLocalStorageState'

const defaultState = {
  programs: [],
  program: [],
}

export const ProgramContext = React.createContext(defaultState)

const ProgramProvider = ({ children }) => {
  const [programs, setPrograms] = useLocalStorageState('programs', [])
  const [program, setProgram] = useLocalStorageState('program', [])
  const [isLoadingPrograms, setLoadingPrograms] = useState(false)

  const state = {
    programs,
    program,
    setProgram,
    setPrograms,
    isLoadingPrograms,
    setLoadingPrograms,
  }

  return <ProgramContext.Provider value={state}>{children}</ProgramContext.Provider>
}

export default ProgramProvider
