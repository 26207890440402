import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import moment from 'moment'
// import { makeStyles } from '@material-ui/core/styles'

import { HomesContext } from '../../contexts/homesContext'

const handleBorderBottomColor = (status) => {
  switch (status) {
    case 'Avail':
      return 'border-bottom-color: var(--green, green) ; color: var(--green, green)'
    case 'Unavail':
      return 'border-bottom-color: var(--red, red); color: var(--red, red)'

    default:
      return 'border-bottom-color: var(--primary, primary); color: var(--primary, primary)'
  }
}

const HomeListCard = ({ homeData, authStatus }) => {
  const { setHomeDetails, setHomeToUpdate } = useContext(HomesContext)
  // const classes = useStyles()
  // const { mentorName, location, image, id, status } = home
  const homeLocationData = homeData.SK.S.split('$')
  const cityState = `${homeLocationData[2]}, ${homeLocationData[3]}`
  const formatDate =
    homeData.home.homeInfo?.availabilityDate !== '' && typeof homeData.home.homeInfo?.availabilityDate !== 'undefined'
      ? moment(
          homeData.home.homeInfo.availabilityDate &&
            homeData.home.homeInfo?.availabilityDate !== '' &&
            homeData.home.homeInfo.availabilityDate?.slice(0, 10)
        ).format('ll')
      : null

  // const isAdmin = authStatus && authStatus === 3
  return (
    <HomeListCardStyles status={homeData.home.status}>
      <Link to={`/home-detail`} onClick={() => setHomeDetails(homeData)}>
        {homeData?.home.status === 'IP' && formatDate !== null ? (
          <Button size="medium" color="primary" variant="contained" style={{ position: 'absolute' }}>
            {formatDate}
          </Button>
        ) : null}

        <img src={homeData.home.homeInfo.picUrl} alt="" />
        <div className="info-container">
          <h3 className="mentorName">{homeData.home.homeInfo.names[0]} Home</h3>
          <p className="location">{cityState}</p>
        </div>
        {/* {isAdmin ? (
          <div>
            <Link to={`/admin-homes/edit`} onClick={() => setHomeToUpdate(homeData)}>
              <Button className={classes.buttonEdit} size="small" variant="contained">
                Edit
              </Button>
            </Link>
            <Link to={`/admin-homes/delete/${id}`}>
              <Button className={classes.buttonDelete} size="small" variant="contained">
                Delete
              </Button>
            </Link>
          </div>
        ) : null} */}
      </Link>
    </HomeListCardStyles>
  )
}

const HomeListCardStyles = styled.li`
  position: relative;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* padding-bottom: 1rem; */
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s;
  border-bottom-style: solid;
  border-bottom-color: var(--green, green);
  /* border-bottom-color: green; */

  ${({ status }) => handleBorderBottomColor(status)};
  border-width: 0 0 5px 0;

  &:hover {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  }
  & img {
    border-radius: 0.5rem 0.5rem 0 0;
    width: 100%;
    object-fit: cover;
    max-height: max(10rem, 30vh);
    aspect-ratio: 4/3;
  }

  /* & img ~ * {
    margin-left: 1rem;
    margin-right: 1rem;
  } */

  & > :last-child {
    margin-bottom: 0;
  }
  .mentorName {
    font-family: var(--fontPrimary, fontPrimary);
    font-size: 1.9rem;
    font-size: var(--fontSizeDescription, fontSizeDescription);
    /* text-transform: uppercase; */
    margin: 2rem;
    color: var(--green, green);
    ${({ status }) => handleBorderBottomColor(status)};
    /* color: #106c67; */
    /* text-decoration:underline; */
    font-weight: 700;
  }
  .location {
    font-family: var(--fontPrimary, fontPrimary);
    /* font-size: var(--fontSizeDescription, fontSizeDescription); */
    font-size: 1.5rem;
    margin: 1.2rem;
    color: var(--black, black);
    margin-bottom: 3rem;
    text-align: center;
  }
`

// const useStyles = makeStyles(() => ({
//   buttonEdit: {
//     color: 'white',
//     margin: '0 1rem 2rem 1rem',
//     padding: '.2rem 3rem',
//     fontSize: '1.4rem',
//     backgroundColor: 'rgb(16,108,103)',
//     '&:hover': {
//       backgroundColor: 'rgb(23, 145, 139)',
//     },
//   },
//   buttonDelete: {
//     color: 'white',
//     margin: '0 1rem 2rem 1rem',
//     padding: '.2rem 2rem',
//     fontSize: '1.4rem',
//     backgroundColor: 'rgb(199, 36, 42)',
//     '&:hover': {
//       backgroundColor: 'rgb(236,28,36)',
//     },
//   },
// }))

export default HomeListCard
