import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'

import { Button, TextField, Grid, FormControl, Paper, CircularProgress, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { BASE_URL, textErrorHelper } from '../../libs/tools'
import { showErrorToast, showSuccessToast } from '../../libs/Utils'

import Header from '../../components/layout/Header'
import { CaseManagerInfoContext } from '../../contexts/caseManagerInfoContext'
import { RegionContext } from '../../contexts/regionContext'
import { ProgramContext } from '../../contexts/programContext'
import { MentorContext } from '../../contexts/mentorContext'
import { AuthContext } from '../../contexts/authContext'

const defaultValues = {
  email: '',
  fName: '',
  lName: '',
  organization: '',
  phone: '',
  comments: '',
}

const RegisterMentorEvent = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState(defaultValues)
  const history = useHistory()
  const { caseManagerInfo } = useContext(CaseManagerInfoContext)
  const { mentorEvent, setMentorEvent } = useContext(MentorContext)
  const { region } = useContext(RegionContext)
  const { program } = useContext(ProgramContext)
  const { getRefreshSession, sessionInfo } = useContext(AuthContext)
  // const localStorageToken = window.localStorage.getItem('accessToken')

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values,
    validationSchema: Yup.object({
      email: Yup.string().email().required('Email is required'),
      fName: Yup.string()
        .min(2)
        .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed for this field ')
        .required('Required'),
      lName: Yup.string()
        .min(2)
        .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed for this field ')
        .required('Required'),
      organization: Yup.string()
        .min(2)
        .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field ')
        .required('Required'),
      phone: Yup.string().min(2).max(30).required('Phone  is not valid (e.g. +19998884242)'),
      comments: Yup.string()
        .max(100)
        .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field '),
    }),
    onSubmit: (values) => {
      submitForm(values)
    },
  })

  const submitForm = (values) => {
    setLoading(true)
    const registerCaseManager = {
      email: values.email,
      firstName: values.fName,
      lastName: values.lName,
      organization: values.organization,
      phone: values.phone,
      comments: values.comments.length < 1 ? '' : values.comments,
    }
    const registerForMentorEvent = async () => {
      try {
        const dataToPost = {
          programId: `ORG#CSS#ST#CA#REG#${region}#PROG#${program}`,
          mentorName: mentorEvent.name,
          caseManager: registerCaseManager,
        }
        const response = await axios.post(`${BASE_URL}/casemanagers/updateMentorEventRegistration`, dataToPost, {
          headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
        })
        showSuccessToast('Registration Successful')
        setLoading(false)
        setMentorEvent({})
        if (response) {
          history.push('/')
        }
      } catch (err) {
        if (err && err.response.status === 403) {
          await getRefreshSession()
          setLoading(false)
          showErrorToast('Registration Failed, Please Submit Again')
        } else {
          setLoading(false)
          showErrorToast('Registration Failed')
        }
      }
    }
    registerForMentorEvent()
  }

  useEffect(() => {
    if (caseManagerInfo || Object.keys(caseManagerInfo).length === 0) {
      document.title = 'Register Mentor Event | California Mentor'
      const phoneNumberWithExt =
        caseManagerInfo.phone.length < 12
          ? `1(${caseManagerInfo.phone.slice(2, 5)}) ${caseManagerInfo.phone.slice(5, 8)}-${caseManagerInfo.phone.slice(
              8,
              12
            )}`
          : `1(${caseManagerInfo.phone.slice(2, 5)}) ${caseManagerInfo.phone.slice(5, 8)}-${caseManagerInfo.phone.slice(
              8,
              12
            )} / Ext: ${caseManagerInfo.phone.slice(12)}`

      const caseManagerRegisterData = {
        email: caseManagerInfo.email,
        fName: caseManagerInfo.fName,
        lName: caseManagerInfo.lName,
        organization: caseManagerInfo.organization,
        phone: phoneNumberWithExt,
        comments: '',
      }
      setValues(caseManagerRegisterData)
    }
  }, [caseManagerInfo])

  return (
    <Fragment>
      <Header />
      <Grid container style={{ marginBottom: 60 }} direction="row" justifyContent="center" alignItems="center">
        <Grid xs={11} sm={6} lg={4} container direction="row" justifyContent="space-between" alignItems="center" item>
          <Paper style={{ width: '100%', padding: 16, marginTop: 60, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
            <Grid container direction="column" justifyContent="space-between" alignItems="center">
              <Box m={4}>
                <Typography
                  style={{ color: 'rgb(66, 66, 66)', fontWeight: 500, fontFamily: 'Roboto', wordBreak: 'break-all' }}
                  variant="h3">{`Meet The Mentor ${mentorEvent?.name} `}</Typography>
              </Box>
              <form onSubmit={formik.handleSubmit} style={{ width: '80%' }}>
                <Box m={2}>
                  <FormControl style={{ width: '100%', paddingBottom: 10 }}>
                    <TextField
                      fullWidth
                      label="Email"
                      id="email"
                      name="email"
                      disabled
                      variant="outlined"
                      placeholder="Edit Email"
                      {...formik.getFieldProps('email')}
                      {...textErrorHelper(formik, 'email')}
                    />
                  </FormControl>
                </Box>
                <Box m={2}>
                  <FormControl style={{ width: '100%', paddingBottom: 10 }}>
                    <TextField
                      fullWidth
                      label="First Name"
                      id="fName"
                      disabled
                      name="fName"
                      variant="outlined"
                      placeholder="Edit First Name"
                      {...formik.getFieldProps('fName')}
                      {...textErrorHelper(formik, 'fName')}
                    />
                  </FormControl>
                </Box>

                <Box m={2}>
                  <FormControl style={{ width: '100%', paddingBottom: 10 }}>
                    <TextField
                      id="lName"
                      name="lName"
                      disabled
                      label="Last Name"
                      variant="outlined"
                      placeholder="Edit Last Name"
                      {...formik.getFieldProps('lName')}
                      {...textErrorHelper(formik, 'lName')}
                    />
                  </FormControl>
                </Box>
                <Box m={2}>
                  <FormControl style={{ width: '100%', paddingBottom: 10 }}>
                    <TextField
                      id="organization"
                      name="organization"
                      label="Organization"
                      disabled
                      variant="outlined"
                      placeholder="Edit Organization"
                      {...formik.getFieldProps('organization')}
                      {...textErrorHelper(formik, 'organization')}
                    />
                  </FormControl>
                </Box>

                <Box m={2}>
                  <FormControl style={{ width: '100%', paddingBottom: 10 }}>
                    <TextField
                      id="phone"
                      name="phone"
                      label="Phone"
                      disabled
                      variant="outlined"
                      placeholder="Edit Phone"
                      {...formik.getFieldProps('phone')}
                      {...textErrorHelper(formik, 'phone')}
                    />
                  </FormControl>
                </Box>
                <Box m={2}>
                  <FormControl style={{ width: '100%', paddingBottom: 10 }}>
                    <TextField
                      id="comments"
                      name="comments"
                      label="Comments"
                      variant="outlined"
                      placeholder="Comments"
                      multiline
                      rows={6}
                      {...formik.getFieldProps('comments')}
                      {...textErrorHelper(formik, 'comments')}
                    />
                  </FormControl>
                </Box>

                <Box m={1}>
                  <Button
                    className={classes.buttonEdit}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}>
                    Register
                    {loading && (
                      <>
                        <CircularProgress style={{ color: 'white', marginLeft: '10px' }} size={24} />
                      </>
                    )}
                  </Button>
                  <Button
                    onClick={() => history.goBack()}
                    className={classes.buttonDelete}
                    size="large"
                    variant="contained"
                    disabled={loading}>
                    Cancel
                  </Button>
                </Box>
              </form>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  )
}

const useStyles = makeStyles(() => ({
  buttonEdit: {
    color: 'white',
    margin: '2rem 1rem 2rem 1rem',
    padding: '.5rem 3rem',
    fontSize: '1.4rem',
  },
  buttonDelete: {
    color: 'white',
    margin: '2rem 1rem 2rem 1rem',
    padding: '.5rem 2rem',
    fontSize: '1.4rem',
    backgroundColor: 'rgb(199, 36, 42)',
    '&:hover': {
      backgroundColor: 'rgb(236,28,36)',
    },
  },
}))

export default RegisterMentorEvent
