import React from 'react'
import { useLocalStorageState } from '../hooks/useLocalStorageState'

const defaultState = {
  caseManagerInfo: [],
}

export const CaseManagerInfoContext = React.createContext(defaultState)

const CaseManagerInfoProvider = ({ children }) => {
  const [caseManagerInfo, setCaseManagerInfo] = useLocalStorageState('caseManagerInfo', [])
  const [caseManagerEmail, setCaseManagerContactEmail] = useLocalStorageState('caseManagerEmail', [])

  const state = {
    caseManagerInfo,
    caseManagerEmail,
    setCaseManagerInfo,
    setCaseManagerContactEmail,
  }

  return <CaseManagerInfoContext.Provider value={state}>{children}</CaseManagerInfoContext.Provider>
}

export default CaseManagerInfoProvider
