import styled from 'styled-components'

const HeroBox = ({ title, title2, titleBlue, description, flip }) => {
  return (
    <HeroBoxStyles flip={flip}>
      <h3 className="title-bold">{title}</h3>
      <h3 className="title-bold">{title2}</h3>
      <h3 className="title-blue">{titleBlue}</h3>
      <div className="title-description">
        <p>{description}</p>
      </div>
    </HeroBoxStyles>
  )
}

const HeroBoxStyles = styled.div`
  position: absolute;
  background-color: rgb(8, 102, 173, 0.9);
  top: 12%;
  right: 4%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  border-radius: 15px;
  ${({ flip }) =>
    flip &&
    `right:auto;
      left: 5% !important;
      max-width: 500px;

  `}
  @media (max-width: 800px) {
    max-width: 350px;
  }

  .title-bold {
    color: var(--white, --white);
    font-size: var(--fontSizeDisplay, fontSizeDisplay);
    font-family: var(--fontPrimary, fontPrimary);
  }
  .title-blue {
    color: var(--primaryLight, --primaryLight);
    font-size: var(--fontSizeDisplayBlue, fontSizeDisplayBlue);
    margin-bottom: 2rem;
    font-family: var(--fontPrimary, fontPrimary);
  }
  .title-description {
    color: var(--white, --white);
    font-size: 1.9rem;
    margin-bottom: 0.5rem;
    line-height: 1.2;
    margin-bottom: 2rem;
    font-family: var(--fontPrimary, fontPrimary);
  }
`
export default HeroBox
