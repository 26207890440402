import React from 'react'
import { useFormikContext } from 'formik'
import { Typography, Grid } from '@material-ui/core'
import HomeDetail from './HomeDetail'

export default function ReviewForm() {
  const { values: formValues } = useFormikContext()
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Home summary
      </Typography>

      <Grid container spacing={2}>
        <HomeDetail formValues={formValues} />
      </Grid>
    </React.Fragment>
  )
}
