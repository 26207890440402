import React from 'react'
import { Typography, Box, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

function SubmitSuccess({setActiveStep}) {
  const history = useHistory()

  const startAddHomeForm =(step) => {
    setActiveStep(step)

  }
  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Home Created !!!!
      </Typography>
      <Box m={1}>
        <Button onClick={() => startAddHomeForm(0)} color="primary" variant="contained">
          Add Another Home
        </Button>
      </Box>
      <Box m={1}>
        <Button color="primary" variant="contained" onClick={() => history.push('/home-list')}>
          View Homes
        </Button>
      </Box>
      <Box m={1}>
        <Button color="primary" variant="contained" onClick={() => history.push('/admin-mentor-events/add')}>
          Add a Mentor Event
        </Button>
      </Box>
    </React.Fragment>
  )
}

export default SubmitSuccess
