import React, { useState } from 'react'
import { useLocalStorageState } from '../hooks/useLocalStorageState'

const defaultState = {
  caseMangersInitialStatus: { Approved: 'N', Denied: 'N', PendingDecision: 'Y' },
  setPendingCaseManagers: () => {},
}

export const CaseManagersContext = React.createContext(defaultState)

const CaseManagersProvider = ({ children }) => {
  const [pendingCaseManagers, setPendingCaseManagers] = useLocalStorageState('pendingCaseManagers', [])
  const [approvedCaseManagers, setApprovedCaseManagers] = useLocalStorageState('approvedCaseManagers', [])
  const [deniedCaseManagers, setDeniedCaseManagers] = useLocalStorageState('deniedCaseManagers', [])
  const [approvedLastEvaluatedKey, setApprovedLastEvaluatedKey] = useLocalStorageState('approvedLastEvaluatedKey', {})
  const [pendingLastEvaluatedKey, setPendingLastEvaluatedKey] = useLocalStorageState('pendingLastEvaluatedKey', {})
  const [deniedLastEvaluatedKey, setDeniedLastEvaluatedKey] = useLocalStorageState('deniedLastEvaluatedKey', {})
  const [caseManagerToUpdate, setCaseManagerToUpdate] = useLocalStorageState('caseManagerToUpdate', [])
  const [caseManagersStatus, setCaseManagersStatus] = useState(defaultState.caseMangersInitialStatus)

  const state = {
    caseManagersStatus,
    pendingCaseManagers,
    setPendingCaseManagers,
    setApprovedCaseManagers,
    setDeniedCaseManagers,
    setCaseManagersStatus,
    approvedLastEvaluatedKey,
    setApprovedLastEvaluatedKey,
    pendingLastEvaluatedKey,
    setPendingLastEvaluatedKey,
    deniedLastEvaluatedKey,
    setDeniedLastEvaluatedKey,
    approvedCaseManagers,
    deniedCaseManagers,
    setCaseManagerToUpdate,
    caseManagerToUpdate,
  }

  return <CaseManagersContext.Provider value={state}>{children}</CaseManagersContext.Provider>
}

export default CaseManagersProvider
