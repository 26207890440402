import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

import AdminLayout from '../../../Hoc/AdminLayout'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Checkbox,
  Box,
  FormGroup,
  FormControlLabel,
  Typography,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

import { BASE_URL } from '../../../libs/tools'

import { CaseManagersContext } from '../../../contexts/caseManagersContext'
import { AuthContext } from '../../../contexts/authContext'
import { showSuccessToast, showErrorToast } from '../../../libs/Utils'
import { makeStyles } from '@material-ui/core/styles'

// let appRender = 0

const CaseManagers = () => {
  // console.log('🚀 ~ file: CaseManagers.jsx ~ line 52 ~ appRender', appRender++)
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const {
    approvedCaseManagers,
    pendingCaseManagers,
    deniedCaseManagers,
    setCaseManagersStatus,
    caseManagersStatus,
    setPendingCaseManagers,
    setApprovedCaseManagers,
    setDeniedCaseManagers,
    setCaseManagerToUpdate,
    approvedLastEvaluatedKey,
    setApprovedLastEvaluatedKey,
    pendingLastEvaluatedKey,
    setPendingLastEvaluatedKey,
    deniedLastEvaluatedKey,
    setDeniedLastEvaluatedKey,
  } = useContext(CaseManagersContext)
  const { getRefreshSession, sessionInfo } = useContext(AuthContext)
  // const localStorageToken = window.localStorage.getItem('accessToken')
  const [approvedPaginated, setApprovedPaginated] = useState(false)
  const [pendingPaginated, setPendingPaginated] = useState(false)
  const [deniedPaginated, setDeniedPaginated] = useState(false)

  const itemsPerPage = 10

  //Pending Pagination & State
  const [pendingPage, setPendingPage] = useState(1)
  const [pendingCount, setPendingCount] = useState(0)
  const indexOfLastPendingItem = pendingPage * itemsPerPage
  const indexOfFirstPendingItem = indexOfLastPendingItem - itemsPerPage
  const currentPendingPage =
    pendingCaseManagers && pendingCaseManagers.slice(indexOfFirstPendingItem, indexOfLastPendingItem)

  //Approved Pagination & State
  const [approvedPage, setApprovedPage] = useState(1)
  const [approvedCount, setApprovedCount] = useState(0)
  const indexOfLastApprovedItem = approvedPage * itemsPerPage
  const indexOfFirstApprovedItem = indexOfLastApprovedItem - itemsPerPage
  const currentApprovedPage =
    approvedCaseManagers && approvedCaseManagers?.slice(indexOfFirstApprovedItem, indexOfLastApprovedItem)

  const [deniedPage, setDeniedPage] = useState(1)
  const [deniedCount, setDeniedCount] = useState(0)
  const indexOfLastDeniedItem = deniedPage * itemsPerPage
  const indexOfFirstDeniedItem = indexOfLastDeniedItem - itemsPerPage
  const currentDeniedPage =
    deniedCaseManagers && deniedCaseManagers.slice(indexOfFirstDeniedItem, indexOfLastDeniedItem)

  const [status, setStatus] = useState({
    Approved: false,
    Denied: false,
    PendingDecision: true,
  })

  const handleStatusChange = (event) => {
    setCaseManagersStatus({
      ...caseManagersStatus,
      [event.target.name]: event.target.value,
    })
    setStatus({ ...status, [event.target.name]: event.target.checked })
  }

  const handlePendingPageChange = (event, value) => {
    setPendingPage(value)
    if (pendingCaseManagers && pendingCaseManagers.length - indexOfLastPendingItem < 10) {
      const getPendingCaseManagerData = async () => {
        try {
          const statusData = { statuses: caseManagersStatus, lastEvaulatedKey: pendingLastEvaluatedKey }

          const response = await axios.post(`${BASE_URL}/admin/getCaseManagers`, statusData, {
            headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
          })
          ///Key
          const lastEvaluatedKeytFromApi = response.data.results.response.LastEvaluatedKey
          const isThereAkey = lastEvaluatedKeytFromApi ? lastEvaluatedKeytFromApi : {}
          setPendingLastEvaluatedKey(isThereAkey)
          //State
          const pendingCaseManagersData = response.data.results.response.Items
          const mergedState = pendingCaseManagers.concat(pendingCaseManagersData)
          setPendingCaseManagers(mergedState)
          setPendingPaginated(true)
          showSuccessToast('Additional Records Returned')
          // setLoading(false)
        } catch (err) {
          // setLoading(false)
          if (err && err.response.status === 403) {
            await getRefreshSession()
            // showErrorToast('Refreshing your token, Please Submit Again')
            showErrorToast('Error Loading Additional Records')
          } else {
            showErrorToast('Error Loading Additional Records')
          }
        }
      }
      if (Object.keys(pendingLastEvaluatedKey).length !== 0) {
        getPendingCaseManagerData()
      }
    }
  }

  const handleApprovedPageChange = (event, value) => {
    setApprovedPage(value)
    if (approvedCaseManagers && approvedCaseManagers.length - indexOfLastApprovedItem < 25) {
      const getApprovedCaseManagerData = async () => {
        try {
          const statusData = { statuses: caseManagersStatus, lastEvaulatedKey: approvedLastEvaluatedKey }

          const response = await axios.post(`${BASE_URL}/admin/getCaseManagers`, statusData, {
            headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
          })
          //Key
          const lastEvaluatedKeytFromApi = response.data.results.response.LastEvaluatedKey
          const isThereAkey = lastEvaluatedKeytFromApi ? lastEvaluatedKeytFromApi : {}
          setApprovedLastEvaluatedKey(isThereAkey)
          ///State
          const approvedCaseManagersPag = response.data.results.response.Items
          const mergedState = approvedCaseManagers.concat(approvedCaseManagersPag)
          setApprovedCaseManagers(mergedState)
          setApprovedPaginated(true)
          showSuccessToast('Additional Records Returned')
          // setLoading(false)
        } catch (err) {
          // setLoading(false)
          if (err && err.response.status === 403) {
            await getRefreshSession()
            // showErrorToast('Refreshing your token, Please Submit Again')
            showErrorToast('Error Loading Additional Records')
          } else {
            showErrorToast('Error Loading Additional Records')
          }
        }
      }
      if (Object.keys(approvedLastEvaluatedKey).length !== 0) {
        getApprovedCaseManagerData()
      }
    }
  }

  const handleDeniedPageChange = (event, value) => {
    setDeniedPage(value)
    if (deniedCaseManagers.length - indexOfLastDeniedItem < 20) {
      const getDeniedCaseManagerData = async () => {
        try {
          const statusData = { statuses: caseManagersStatus, lastEvaulatedKey: deniedLastEvaluatedKey }

          const response = await axios.post(`${BASE_URL}/admin/getCaseManagers`, statusData, {
            headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
          })

          ///Key
          const lastEvaluatedKeytFromApi = response.data.results.response.LastEvaluatedKey
          const isThereAkey = lastEvaluatedKeytFromApi ? lastEvaluatedKeytFromApi : {}
          setDeniedLastEvaluatedKey(isThereAkey)
          //State
          const deniedCaseManagersData = response.data.results.response.Items
          const mergedState = deniedCaseManagers.concat(deniedCaseManagersData)
          setDeniedCaseManagers(mergedState)
          setDeniedPaginated(true)
          showSuccessToast('Additional Records Returned')
          // setLoading(false)
        } catch (err) {
          // setLoading(false)
          if (err && err.response.status === 403) {
            await getRefreshSession()
            // showErrorToast('Refreshing your token, Please Submit Again')
            showErrorToast('Error Loading Additional Records')
          } else {
            showErrorToast('Error Loading Additional Records')
          }
        }
      }
      if (Object.keys(deniedLastEvaluatedKey).length !== 0) {
        getDeniedCaseManagerData()
      }
    }
  }

  useEffect(() => {
    document.title = 'Case Managers | California Mentor'
  }, [])

  useEffect(() => {
    setPendingCount(Math.ceil(pendingCaseManagers?.length / itemsPerPage))
    setApprovedCount(Math.ceil(approvedCaseManagers?.length / itemsPerPage))
    setDeniedCount(Math.ceil(deniedCaseManagers?.length / itemsPerPage))
  }, [approvedCaseManagers, pendingCaseManagers, deniedCaseManagers])

  useEffect(() => {
    let isActive = true
    let source = axios.CancelToken.source()
    const getCaseManagerData = async () => {
      setLoading(true)
      try {
        //   const helper = (status) => {
        //     status.Approved ? status.Approved = 'Y' : status.Approved = 'N';
        //     status.Approved ? status.Denied = 'Y' : status.Denied = 'N';
        //     status.Approved ? status.PendingDecision = 'Y' : status.PendingDecision = 'N';
        //     return status;
        // };
        const statusData = { statuses: caseManagersStatus, lastEvaulatedKey: {} }
        // const statusData = {
        //     "statuses": {
        //     "Approved": "Y",
        //     "Denied": "Y",
        //     "PendingDecision": "Y"
        //     },
        //     "lastEvaulatedKey": {
        //     }
        //     };
        // "lastEvaulatedKey": {
        //     "PK": {
        //     "S": "CASEMANAGERS#ST#PD"
        //     },
        //     "SK": {
        //     "S": "CMEMAIL#test33@test.com"
        //     }
        //     }
        //     };

        const response = await axios.post(`${BASE_URL}/admin/getCaseManagers`, statusData, {
          cancelToken: source.token,
          headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
        })
        // console.log('🚀 ~ file: CaseManagers.jsx ~ line 232 ~ getCaseManagerData ~ response', response)
        //Key Received Check
        const ApprLastEvaluatedKey =
          response.data.results[0].response?.LastEvaluatedKey === undefined
            ? {}
            : response.data.results[0].response.LastEvaluatedKey
        const PendingLastEvaluatedKey =
          response.data.results[2].response?.LastEvaluatedKey === undefined
            ? {}
            : response.data.results[2].response.LastEvaluatedKey
        const DeniedLastEvaluatedKey =
          response.data.results[1].response?.LastEvaluatedKey === undefined
            ? {}
            : response.data.results[1].response.LastEvaluatedKey

        //Data Received Check
        const isPendingDataReceived =
          Object.keys(response.data.results[2]).length !== 0 ? response.data.results[2].response.Items : []
        const isApprovedDataReceived =
          Object.keys(response.data.results[0]).length !== 0 ? response.data.results[0].response.Items : []
        const isDeniedDataReceived =
          Object.keys(response.data.results[1]).length !== 0 ? response.data.results[1].response.Items : []
        //Check if paginated, if not update the state
        if (!pendingPaginated) {
          if (isActive) {
            setPendingCaseManagers(isPendingDataReceived)
            setPendingPage(1)
            setPendingLastEvaluatedKey(PendingLastEvaluatedKey)
          }
        }
        if (!approvedPaginated) {
          if (isActive) {
            setApprovedCaseManagers(isApprovedDataReceived)
            setApprovedPage(1)
            setApprovedLastEvaluatedKey(ApprLastEvaluatedKey)
          }
        }
        if (!deniedPaginated) {
          if (isActive) {
            setDeniedCaseManagers(isDeniedDataReceived)
            setDeniedPage(1)
            setDeniedLastEvaluatedKey(DeniedLastEvaluatedKey)
          }
        }

        setLoading(false)
      } catch (err) {
        if (err && err.response.status === 403) {
          getRefreshSession()
        }
        if (axios.isCancel(err)) {
          console.log('AxiosCancel: caught cancel')
        } else {
          throw err
        }
        setLoading(false)
      }
    }
    if (isActive) {
      getCaseManagerData()
    }

    return () => {
      isActive = false
      source.cancel()
    }
  }, [
    approvedPaginated,
    caseManagersStatus,
    deniedPaginated,
    // localStorageToken,
    pendingPaginated,
    status,
    sessionInfo.accessToken,
  ])
  return (
    <AdminLayout title="Case Managers">
      <Box my={3}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={status.PendingDecision}
                onChange={handleStatusChange}
                name="PendingDecision"
                value={status.PendingDecision ? 'N' : 'Y'}
                color="default"
              />
            }
            label="Pending"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={status.Approved}
                onChange={handleStatusChange}
                name="Approved"
                value={status.Approved ? 'N' : 'Y'}
                style={{ color: 'rgb(16,108,103)' }}
              />
            }
            label="Approved"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={status.Denied}
                onChange={handleStatusChange}
                name="Denied"
                value={status.Denied ? 'N' : 'Y'}
                color="secondary"
              />
            }
            label="Denied"
          />
        </FormGroup>
      </Box>
      {loading ? (
        <CircularProgress thickness={7} style={{ color: '#98c5e9' }} />
      ) : (
        <>
          {status.PendingDecision ? (
            <Paper
              style={{ width: '60%', padding: 16, marginBottom: 32, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
              className="mb-5">
              <Box my={2}>
                <Typography className={classes.title} variant="h5">
                  Case Managers Pending
                </Typography>
              </Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCelltitle}>Last name</TableCell>
                    <TableCell className={classes.tableCelltitle}>First name</TableCell>
                    <TableCell className={classes.tableCelltitle}>Organization</TableCell>
                    <TableCell className={classes.tableCelltitle}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPendingPage
                    ? currentPendingPage.map((caseManager, i) => (
                        <TableRow key={caseManager.email}>
                          <TableCell>
                            <Link to={`/admin-case-managers/edit`} onClick={() => setCaseManagerToUpdate(caseManager)}>
                              {caseManager.lName}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Link to={`/admin-case-managers/edit`} onClick={() => setCaseManagerToUpdate(caseManager)}>
                              {caseManager.fName}
                            </Link>
                          </TableCell>
                          <TableCell>{caseManager.organization}</TableCell>
                          <TableCell>
                            {caseManager.status === 'denied' ? (
                              <span style={{ color: 'rgb(199, 36, 42)' }}> {caseManager.status}</span>
                            ) : caseManager.status === 'approved' ? (
                              <span style={{ color: 'rgb(16,108,103)' }}> {caseManager.status}</span>
                            ) : (
                              <>
                                <span style={{ color: 'black', textTransform: 'capitalize' }}></span>
                                <span style={{ color: 'black' }}> Pending Decision</span>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
              <Box mt={2}>
                <Pagination
                  mt="2"
                  color="standard"
                  // style={{ color: 'green' }}
                  count={pendingCount}
                  page={pendingPage}
                  siblingCount={1}
                  boundaryCount={1}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePendingPageChange}
                />
              </Box>
            </Paper>
          ) : null}
          {/* {loading ? (
            <CircularProgress thickness={7} style={{ color: '#98c5e9' }} />
          ) : ( */}
          <>
            {status.Approved ? (
              <Paper
                mb={6}
                style={{ width: '60%', padding: 16, marginBottom: 32, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
                className="mb-5">
                <Box my={2}>
                  <Typography className={classes.title} style={{ color: 'rgb(16,108,103)' }} variant="h5">
                    Case Managers Approved
                  </Typography>
                </Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCelltitle}>Last name</TableCell>
                      <TableCell className={classes.tableCelltitle}>First name</TableCell>
                      <TableCell className={classes.tableCelltitle}>Organization</TableCell>
                      <TableCell className={classes.tableCelltitle}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentApprovedPage
                      ? currentApprovedPage.map((caseManager, i) => (
                          <TableRow key={caseManager.email}>
                            <TableCell>
                              <Link
                                to={`/admin-case-managers/edit`}
                                onClick={() => setCaseManagerToUpdate(caseManager)}>
                                {caseManager.lName}
                              </Link>
                            </TableCell>
                            <TableCell>
                              <Link
                                to={`/admin-case-managers/edit`}
                                onClick={() => setCaseManagerToUpdate(caseManager)}>
                                {caseManager.fName}
                              </Link>
                            </TableCell>
                            <TableCell>{caseManager.organization}</TableCell>
                            <TableCell>
                              {caseManager.status === 'denied' ? (
                                <span style={{ color: 'rgb(199, 36, 42)' }}> {caseManager.status}</span>
                              ) : caseManager.status === 'Appr' ? (
                                <span style={{ color: 'rgb(16,108,103)' }}> Approved</span>
                              ) : (
                                <span style={{ color: 'black' }}> Approved</span>
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
                <Box mt={2}>
                  <Pagination
                    mt="2"
                    color="standard"
                    // style={{ color: 'green' }}
                    count={approvedCount}
                    page={approvedPage}
                    siblingCount={1}
                    boundaryCount={1}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleApprovedPageChange}
                  />
                </Box>
              </Paper>
            ) : null}
          </>
          {/* )} */}

          {status.Denied ? (
            <Paper style={{ width: '60%', padding: 16, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }} className="mb-5">
              <Box my={2}>
                <Typography className={classes.title} style={{ color: 'rgb(199, 36, 42)' }} variant="h5">
                  Case Managers Denied
                </Typography>
              </Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCelltitle}>Last name</TableCell>
                    <TableCell className={classes.tableCelltitle}>First name</TableCell>
                    <TableCell className={classes.tableCelltitle}>Organization</TableCell>
                    <TableCell className={classes.tableCelltitle}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentDeniedPage
                    ? currentDeniedPage.map((caseManager, i) => (
                        <TableRow key={caseManager.email}>
                          <TableCell>
                            <Link to={`/admin-case-managers/edit`} onClick={() => setCaseManagerToUpdate(caseManager)}>
                              {caseManager.lName}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Link to={`/admin-case-managers/edit`} onClick={() => setCaseManagerToUpdate(caseManager)}>
                              {caseManager.fName}
                            </Link>
                          </TableCell>
                          <TableCell>{caseManager.organization}</TableCell>
                          <TableCell>
                            {caseManager.status === 'Den' ? (
                              <span style={{ color: 'rgb(199, 36, 42)' }}> Denied</span>
                            ) : caseManager.status === 'approved' ? (
                              <span style={{ color: 'rgb(16,108,103)' }}> {caseManager.status}</span>
                            ) : (
                              <span style={{ color: 'black' }}> {caseManager.status}</span>
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
              <Box mt={2}>
                <Pagination
                  mt="2"
                  color="secondary"
                  // style={{ color: 'green' }}
                  count={deniedCount}
                  page={deniedPage}
                  siblingCount={1}
                  boundaryCount={1}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleDeniedPageChange}
                />
              </Box>
            </Paper>
          ) : null}
        </>
      )}
      {/* <div className="admin_progress">
        {loading ? <CircularProgress thickness={7} style={{ color: '#98c5e9' }} /> : null}
      </div> */}
    </AdminLayout>
  )
}

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
  paper: {
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
  title: {
    color: 'rgb(66, 66, 66)',
    fontWeight: 700,
    fontFamily: 'Roboto',
    textDecoration: 'underline',
  },
  tableCelltitle: {
    color: 'rgb(66, 66, 66)',
    fontFamily: 'Roboto',
    textDecoration: 'underline',
  },
})

export default CaseManagers
