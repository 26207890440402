import React from 'react'
import { useLocalStorageState } from '../hooks/useLocalStorageState'
const defaultState = {
  regions: [],
  region: [],
}

export const RegionContext = React.createContext(defaultState)

const RegionProvider = ({ children }) => {
  const [regions, setRegions] = useLocalStorageState('regions', [])
  const [region, setRegion] = useLocalStorageState('region', [])
  const [currentRegion, setCurrentRegion] = useLocalStorageState('currentRegion', [])

  const state = {
    regions,
    region,
    setRegion,
    setRegions,
    setCurrentRegion,
    currentRegion,
  }

  return <RegionContext.Provider value={state}>{children}</RegionContext.Provider>
}

export default RegionProvider
