import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { MentorContext } from '../../contexts/mentorContext'
import { CaseManagerInfoContext } from '../../contexts/caseManagerInfoContext'

import mentorPng from '../../resources/images/Mentor.png'

const MentorCards = ({ mentor, authStatus }) => {
  const classes = useStyles()

  const { setMentorEvent } = useContext(MentorContext)
  const { caseManagerInfo } = useContext(CaseManagerInfoContext)
  
  const caseManagerEmail = caseManagerInfo?.email
  const isAdmin = authStatus && authStatus === 3
  const isCaseManager = authStatus && authStatus === 1
  const isRegisteredForEvent =
    mentor.registeredCaseManagers.length >= 1 &&
    mentor.registeredCaseManagers.some((registered) => {
      if (registered.email === caseManagerEmail) {
        return true
      } else {
        return false
      }
    })

  const slicedStartDate = mentor.startDate.slice(0, 10)
  const slicedEndDate = mentor.endDate.slice(0, 10)
  const multipleDayEvent = slicedStartDate !== slicedEndDate
  return (
    <>
      {isAdmin ? (
        <MentorCardStyles>
          <Link to={`/admin-mentor-events/registrations`} onClick={() => setMentorEvent(mentor)}>
            <img src={mentor.image === '$$$$$' ? mentorPng : mentor.image} alt={mentor.name} />
            <h3 className="title">{mentor.name}</h3>
            {multipleDayEvent ? (
              <p className="date">
                {moment(mentor.startDate).format('MMMM Do, dddd @ h:mma - ')}
                {moment(mentor.endDate).format('MMMM Do, dddd @ h:mma ')}
              </p>
            ) : (
              <p className="date">
                {moment(mentor.startDate).format('MMMM Do, dddd @ h:mma -')}
                {moment(mentor.endDate).format(' h:mma ')}
              </p>
            )}
            <p className="location">{mentor.location}</p>
            <p className="description">{mentor.description}</p>
            <div>
              <Link to={`/admin-mentor-events/edit`} onClick={() => setMentorEvent(mentor)}>
                <Button className={classes.buttonEdit} size="small" variant="contained">
                  Edit
                </Button>
              </Link>
              {/* <Link to={`/admin-mentor-events/delete/${1}`}>
          <Button className={classes.buttonDelete} size="small" variant="contained">
            Delete
          </Button>
        </Link> */}
            </div>
          </Link>
        </MentorCardStyles>
      ) : isCaseManager && mentor && mentor.displayStatus === 'Y' ? (
        <MentorCardStyles>
          <img src={mentor.image === '$$$$$' ? mentorPng : mentor.image} alt={mentor.name} />
          <h3 className="title">{mentor.name}</h3>
          {multipleDayEvent ? (
            <p className="date">
              {moment(mentor.startDate).format('MMMM Do, dddd @ h:mma - ')}
              {moment(mentor.endDate).format('MMMM Do, dddd @ h:mma ')}
            </p>
          ) : (
            <p className="date">
              {moment(mentor.startDate).format('MMMM Do, dddd @ h:mma -')}
              {moment(mentor.endDate).format(' h:mma ')}
            </p>
          )}
          <p className="location">{mentor.location}</p>
          <p className="description">{mentor.description}</p>
          {isRegisteredForEvent ? (
            <p className="date" style={{ color: 'rgb(16,108,103)' }}>
              Already registered for this event!
            </p>
          ) : (
            <Link to={`/mentors/register`} onClick={() => setMentorEvent(mentor)}>
              <Button className={classes.buttonEdit} size="small" variant="contained">
                Register
              </Button>
            </Link>
          )}
        </MentorCardStyles>
      ) : null}
    </>
  )
}

const MentorCardStyles = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-bottom: 1rem;
  /* overflow: auto; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & img {
    border-radius: 0.5rem 0.5rem 0 0;
    width: 100%;
    object-fit: cover;
    max-height: max(10rem, 30vh);
    aspect-ratio: 4/3;
  }

  /* & img ~ * {
    margin-left: 1rem;
    margin-right: 1rem;
  } */

  & > :last-child {
    margin-bottom: 0;
  }

  .title {
    margin: 2rem 1.3rem;
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeTitle, fontSizeTitle);
    font-size: 2.8rem;
    word-break: break-all;
    color: var(--green, black);
  }
  .date {
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeDescription, fontSizeDescription);
    font-size: 2.3rem;
    margin: 2rem 1rem;
    color: var(--secondary, secondary);
    font-weight: 600;
  }
  .location {
    word-break: break-all;
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeDescription, fontSizeDescription);
    font-size: 2rem;
    margin: 2rem 1rem;
    text-transform: capitalize;
    color: var(--black, black);
    font-weight: 600;
  }
  .description {
    word-break: break-all;
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeDescription, fontSizeDescription);
    margin: 2rem 1rem;
    font-weight: 400;
    color: var(--black, black);
  }
`
const useStyles = makeStyles(() => ({
  buttonEdit: {
    color: 'white',
    margin: '0 1rem 2rem 1rem',
    padding: '.2rem 3rem',
    fontSize: '1.4rem',
    backgroundColor: 'rgb(16,108,103)',
    '&:hover': {
      backgroundColor: 'rgb(23, 145, 139)',
    },
  },
  buttonDelete: {
    color: 'white',
    margin: '0 1rem 2rem 1rem',
    padding: '.2rem 2rem',
    fontSize: '1.4rem',
    backgroundColor: 'rgb(199, 36, 42)',
    '&:hover': {
      backgroundColor: 'rgb(236,28,36)',
    },
  },
}))

export default MentorCards
