import React, { Fragment, useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'

import Header from '../../components/layout/Header'
import HomeListCard from './HomeListCard'
import { showErrorToast, showSuccessToast } from '../../libs/Utils'

import { makeStyles } from '@material-ui/core/styles'

import { Grid, Box, Paper, FormGroup, Checkbox, FormControlLabel, CircularProgress } from '@material-ui/core/'
import Button from '@material-ui/core/Button'
import Pagination from '@material-ui/lab/Pagination'

import EmptyFile from '../../resources/images/EmptyFile2.png'

import { BASE_URL } from '../../libs/tools'

import { AuthContext } from '../../contexts/authContext'
import { RegionContext } from '../../contexts/regionContext'
import { ProgramContext } from '../../contexts/programContext'
import { HomesContext } from '../../contexts/homesContext'

// let appRender = 0

const HomeList = (props) => {
  // console.log('🚀 ~ file: addEditHomes.jsx ~ line 72 ~ appRender', appRender++)
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const { region } = useContext(RegionContext)
  const { program } = useContext(ProgramContext)
  const { getRefreshSession, authStatus, sessionInfo } = useContext(AuthContext)

  const isAdmin = authStatus && authStatus === 3
  const {
    comingSoonHomes,
    availableHomes,
    unavailableHomes,
    homeStatus,
    setHomeStatus,
    setComingSoonHomes,
    setAvailableHomes,
    setUnavailableHomes,
    setHomeToUpdate,
    availableLastEvaluatedKey,
    setAvailableLastEvaluatedKey,
    comingSoonLastEvaluatedKey,
    setComingSoonLastEvaluatedKey,
    unavailableLastEvaluatedKey,
    setUnavailableLastEvaluatedKey,
    preferenceSearch,
    setPreferenceSearch,
    homePreferences,
    availablePaginated,
    setAvailablePaginated,
    unavailablePaginated,
    setUnavailablePaginated,
    comingSoonPaginated,
    setComingSoonPaginated,
    availablePage,
    setAvailablePage,
    unavailablePage,
    setUnavailablePage,
    inProcessPage,
    setInProcessPage,
  } = useContext(HomesContext)

  // const [availablePaginated, setAvailablePaginated] = useState(false)
  // const [unavailablePaginated, setUnavailablePaginated] = useState(false)
  // const [comingSoonPaginated, setComingSoonPaginated] = useState(false)

  // const localStorageToken = window.localStorage.getItem('accessToken')
  const headerTitle = `${program} Unit Homes`
  const itemsPerPage = 8

  //Available Pagination & State
  // const [availablePage, setAvailablePage] = useState(1)
  const [availableCount, setAvailableCount] = useState(0)
  const indexOfLastAvailableHome = availablePage * itemsPerPage
  const indexOfFirstAvailableHome = indexOfLastAvailableHome - itemsPerPage
  const currentAvailablePage =
    availableHomes && availableHomes.slice(indexOfFirstAvailableHome, indexOfLastAvailableHome)

  //Unavailable Pagination & State
  // const [unavailablePage, setUnavailablePage] = useState(1)
  const [unavailableCount, setUnavailableCount] = useState(0)
  const indexOfLastUnavailableHome = unavailablePage * itemsPerPage
  const indexOfFirstUnavailableHome = indexOfLastUnavailableHome - itemsPerPage
  const currentUnavailablePage =
    unavailableHomes && unavailableHomes.slice(indexOfFirstUnavailableHome, indexOfLastUnavailableHome)

  //Unavailable Pagination & State
  // const [inProcessPage, setInProcessPage] = useState(1)
  const [inProcessCount, setInProcessCount] = useState(0)
  const indexOfLastInProcessHome = inProcessPage * itemsPerPage
  const indexOfFirstInProcessHome = indexOfLastInProcessHome - itemsPerPage
  const currentInProcessPage =
    comingSoonHomes && comingSoonHomes.slice(indexOfFirstInProcessHome, indexOfLastInProcessHome)

  const [status, setStatus] = useState({
    Unavailable: false,
    Available: true,
    InProgress: true,
  })
  const history = useHistory()
  const handleStatusChange = (event) => {
    setHomeStatus({
      ...homeStatus,
      [event.target.name]: event.target.value,
    })
    setStatus({ ...status, [event.target.name]: event.target.checked })
  }
  const handlePreferenceChange = (event) => {
    setPreferenceSearch(event.target.checked)
    history.replace({ state: { prevRoute: '' } })
    setAvailablePaginated(false)
    setUnavailablePaginated(false)
    setComingSoonPaginated(false)
  }

  const handleAvailablePageChange = (event, value) => {
    setAvailablePage(value)
    if (availableHomes?.length - indexOfLastAvailableHome > 13) {
      const getAvailableHomesData = async () => {
        try {
          const statusData = {
            key: `ORG#CSS#ST#CA#REG#${region}#PROG#${program}`,
            statuses: homeStatus,
            preferences: preferenceSearch ? homePreferences : [],
            lastEvaulatedKey: availableLastEvaluatedKey,
          }
          const response = await axios.post(`${BASE_URL}/casemanagers/getHomes`, statusData, {
            headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
          })
          // console.log('%c$$$$$$$$$$$$$$$$$$$$ Available Homes Paginated  DATA RECEIVED', 'color:green')
          // console.log(response.data)
          //Key
          const lastEvaluatedKeytFromApi = response.data.results.response.LastEvaluatedKey
          const isThereAkey = lastEvaluatedKeytFromApi ? lastEvaluatedKeytFromApi : {}
          setAvailableLastEvaluatedKey(isThereAkey)
          ///State
          const AvailableHomesPagData = response.data.results.response.Items
          const mergedState = availableHomes.concat(AvailableHomesPagData)
          setAvailableHomes(mergedState)
          showSuccessToast('Additional Homes Returned')
          setAvailablePaginated(true)
          // setLoading(false)
        } catch (err) {
          // setLoading(false)
          if (err && err.response.status === 403) {
            await getRefreshSession()
            // showErrorToast('Refreshing your token, Please Submit Again')
            showErrorToast('Error Loading Additional Records')
          } else {
            showErrorToast('Error Loading Additional Homes')
          }
        }
      }
      if (Object.keys(availableLastEvaluatedKey).length !== 0) {
        getAvailableHomesData()
      }
    }
  }

  const handleComingSoonPageChange = (event, value) => {
    setInProcessPage(value)
    if (comingSoonHomes?.length - indexOfLastInProcessHome > 13) {
      const getComingSoonHomesData = async () => {
        try {
          const statusData = {
            key: `ORG#CSS#ST#CA#REG#${region}#PROG#${program}`,
            statuses: homeStatus,
            preferences: preferenceSearch ? homePreferences : [],
            lastEvaulatedKey: comingSoonLastEvaluatedKey,
          }
          const response = await axios.post(`${BASE_URL}/casemanagers/getHomes`, statusData, {
            headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
          })
          // console.log('%c$$$$$$$$$$$$$$$$$$$$ Coming Soon Homes Paginated DATA RECEIVED', 'color:green')
          // console.log(response.data)
          //Key
          const lastEvaluatedKeytFromApi = response.data.results.response.LastEvaluatedKey
          const isThereAkey = lastEvaluatedKeytFromApi ? lastEvaluatedKeytFromApi : {}
          setComingSoonLastEvaluatedKey(isThereAkey)
          ///State
          const comingSoonHomesPagData = response.data.results.response.Items
          const mergedState = comingSoonHomes.concat(comingSoonHomesPagData)
          setComingSoonHomes(mergedState)
          showSuccessToast('Additional Homes Returned')
          setComingSoonPaginated(true)
          // setLoading(false)
        } catch (err) {
          // setLoading(false)
          if (err && err.response.status === 403) {
            await getRefreshSession()
            // showErrorToast('Refreshing your token, Please Submit Again')
            showErrorToast('Error Loading Additional Records')
          } else {
            showErrorToast('Error Loading Additional Homes')
          }
        }
      }
      if (Object.keys(comingSoonLastEvaluatedKey).length !== 0) {
        getComingSoonHomesData()
      }
    }
  }

  const handleUnvailablePageChange = (event, value) => {
    setUnavailablePage(value)
    if (unavailableHomes?.length - indexOfLastUnavailableHome > 13) {
      const getUnavailableHomesData = async () => {
        try {
          const statusData = {
            key: `ORG#CSS#ST#CA#REG#${region}#PROG#${program}`,
            statuses: homeStatus,
            preferences: preferenceSearch ? homePreferences : [],
            lastEvaulatedKey: unavailableLastEvaluatedKey,
          }
          const response = await axios.post(`${BASE_URL}/casemanagers/getHomes`, statusData, {
            headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
          })
          // console.log('%c$$$$$$$$$$$$$$$$$$$$ UnAvailable Homes Paginated  DATA RECEIVED', 'color:green')
          // console.log(response.data)
          //Key
          const lastEvaluatedKeytFromApi = response.data.results.response.LastEvaluatedKey
          const isThereAkey = lastEvaluatedKeytFromApi ? lastEvaluatedKeytFromApi : {}
          setUnavailableLastEvaluatedKey(isThereAkey)
          ///State
          const UnavailableHomesPagData = response.data.results.response.Items
          const mergedState = unavailableHomes.concat(UnavailableHomesPagData)
          setUnavailableHomes(mergedState)
          showSuccessToast('Additional Homes Returned')
          setUnavailablePaginated(true)
          // setLoading(false)
        } catch (err) {
          // setLoading(false)
          if (err && err.response.status === 403) {
            await getRefreshSession()
            // showErrorToast('Refreshing your token, Please Submit Again')
            showErrorToast('Error Loading Additional Records')
          } else {
            showErrorToast('Error Loading Additional Homes')
          }
        }
      }
      if (Object.keys(unavailableLastEvaluatedKey).length !== 0) {
        getUnavailableHomesData()
      }
    }
  }

  useEffect(() => {
    setAvailableCount(Math.ceil(availableHomes?.length / itemsPerPage))
    setUnavailableCount(Math.ceil(unavailableHomes?.length / itemsPerPage))
    setInProcessCount(Math.ceil(comingSoonHomes?.length / itemsPerPage))
  }, [availableHomes, comingSoonHomes, unavailableHomes])

  useEffect(() => {
    document.title = 'Home Listing | California Mentor'
    return () => {
      setHomeStatus({
        Available: 'Y',
        Unavailable: 'N',
        InProgress: 'Y',
      })
    }
  }, [])

  useEffect(() => {
    let isActive = true
    let source = axios.CancelToken.source()
    const getHomesData = async () => {
      setIsLoading(true)
      try {
        // const statusData = {
        //   // programId: 'ORG#CSS#ST#CA#REG#Golden Gate Regional Center#PROG#Golden Gate FHA',
        // }
        const statusData = {
          key: `ORG#CSS#ST#CA#REG#${region}#PROG#${program}`,
          // statuses: {
          //   Available: 'Y',
          //   Unavailable: 'Y',
          //   InProgress: 'Y',
          // },
          statuses: homeStatus,
          preferences: preferenceSearch ? homePreferences : [],
          lastEvaulatedKey: {},
        }
        const response = await axios.post(`${BASE_URL}/casemanagers/getHomes`, statusData, {
          cancelToken: source.token,
          // headers: { Authorization: `Bearer ${localStorageToken}` },

          headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
        })

        const AvailableLastEvaluatedKey =
          response.data.results[0].response?.LastEvaluatedKey === undefined
            ? {}
            : response.data.results[0].response.LastEvaluatedKey

        const ComingSoonLastEvaluatedKey =
          response.data.results[2].response?.LastEvaluatedKey === undefined
            ? {}
            : response.data.results[2].response.LastEvaluatedKey

        const UnavailableLastEvaluatedKey =
          response.data.results[1].response?.LastEvaluatedKey === undefined
            ? {}
            : response.data.results[1].response.LastEvaluatedKey

        const isAvailableHomesDataReceived =
          Object.keys(response.data.results[0]).length !== 0 ? response.data.results[0].response.Items : []
        const isComingSoonHomesDataReceived =
          Object.keys(response.data.results[2]).length !== 0 ? response.data.results[2].response.Items : []
        const isUnavailablHomesDataReceived =
          Object.keys(response.data.results[1]).length !== 0 ? response.data.results[1].response.Items : []
        if (!availablePaginated) {
          if (isActive) {
            setAvailableHomes(isAvailableHomesDataReceived)
            setAvailablePage(1)
            setAvailableLastEvaluatedKey(AvailableLastEvaluatedKey)
          }
        }
        if (!comingSoonPaginated) {
          if (isActive) {
            setComingSoonHomes(isComingSoonHomesDataReceived)
            setInProcessPage(1)
            setComingSoonLastEvaluatedKey(ComingSoonLastEvaluatedKey)
          }
        }
        if (!unavailablePaginated) {
          if (isActive) {
            setUnavailableHomes(isUnavailablHomesDataReceived)
            setUnavailablePage(1)
            setUnavailableLastEvaluatedKey(UnavailableLastEvaluatedKey)
          }
        }
        setIsLoading(false)
      } catch (err) {
        const get403 = err && err?.toString().replace(/[^0-9]/g, '')
        if (err && err?.response?.status === 403) {
        // if (get403 === '403') {
          // console.log("🚀 ~ file: HomeList.jsx HOME ERROR THEN REFRESH ~ get403", )
          getRefreshSession()
          // setIsLoading(false)
        }
        if (axios.isCancel(err)) {
          console.log('AxiosCancel: caught cancel')
        } else {
          throw err
          
        }
        setIsLoading(false)
      }
    }
    if (isActive) {
      if (
        (preferenceSearch === false && !availablePaginated) ||
        (preferenceSearch === false && !unavailablePaginated) ||
        (preferenceSearch === false && !comingSoonPaginated)
      ) {
        // console.log('%c Get Not Search And Not Paginated Data', 'color:blue')
        getHomesData()
      }
    }

    if (isActive) {
      //restrict the gethomes api to only run when check box is checked true or false
      //and not on initial render when getting pushed from home-preference path
      if (
        preferenceSearch &&
        homePreferences.length >= 1 &&
        props.location.state?.prevRoute !== 'home-preference' &&
        !availablePaginated
      ) {
        // console.log('%c Get Preference Search Data', 'color:red')
        getHomesData()
      }
    }

    return () => {
      isActive = false
      source.cancel()
    }
  }, [region, program, homeStatus, status, preferenceSearch, homePreferences, sessionInfo.accessToken])
  // console.log(
  //   '🚀 ~ file: HomeList.jsx ~ line 386 ~ HomeList ~ authContext.sessionInfo.accessToken',
  //   sessionInfo.accessToken
  // )

  const renderHomes = (homesPerPage) => {
    // if (isLoading) {
    //   return <CircularProgress />
    // }
    return (
      <ul className="card-wrapper">
        {homesPerPage.map((home) => {
          return <HomeListCard key={home.SK.S} authStatus={authStatus} homeData={home} />
        })}
      </ul>
    )
  }

  return (
    <Fragment>
      <Header back color="white" size="2rem" title={headerTitle} />
      <Grid className={classes.root} container direction="row" justifyContent="center" alignItems="flex-start">
        <Grid lg={10} container direction="row" justifyContent="center" alignItems="center" item>
          <Box style={{ width: '100%', padding: 32, position: 'relative' }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              {isAdmin ? (
                <Box>
                  <Link to="/admin-homes/add">
                    <Button
                      className={classes.buttonAdd}
                      onClick={() => setHomeToUpdate([])}
                      size="large"
                      variant="contained"
                      color="primary">
                      Add Home
                    </Button>
                  </Link>
                </Box>
              ) : (
                <Box></Box>
              )}
              <Box my={3}>
                <FormGroup row>
                  {homePreferences?.length >= 1 || preferenceSearch ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={preferenceSearch}
                          onChange={handlePreferenceChange}
                          name="Preferences"
                          // value={status.Available ? 'N' : 'Y'}
                          color="primary"
                        />
                      }
                      label="Preferences"
                    />
                  ) : null}

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={status.Available}
                        onChange={handleStatusChange}
                        name="Available"
                        value={status.Available ? 'N' : 'Y'}
                        style={{ color: 'rgb(16,108,103)' }}
                      />
                    }
                    label="Available"
                  />
                  {isAdmin ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={status.Unavailable}
                          onChange={handleStatusChange}
                          name="Unavailable"
                          value={status.Unavailable ? 'N' : 'Y'}
                          color="secondary"
                        />
                      }
                      label="Unavailable"
                    />
                  ) : null}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={status.InProgress}
                        onChange={handleStatusChange}
                        name="InProgress"
                        value={status.InProgress ? 'N' : 'Y'}
                        color="primary"
                      />
                    }
                    label="Coming Soon"
                  />
                </FormGroup>
              </Box>
            </Grid>
            {isLoading ? (
              <div style={{ margin: '20vh auto', width: 'fit-content', textAlign: 'center' }}>
                <CircularProgress size={80} />
              </div>
            ) : (
              <Fragment>
                <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                  {status.Available ? (
                    <Paper className={classes.paper} style={{ width: '100%', padding: 32 }}>
                      <Box m={1}>
                        <HomeListStyles>
                          <h1 className="heading" style={{ color: 'rgb(16,108,103)' }}>
                            Available
                          </h1>
                          {currentAvailablePage?.length === 0 ? (
                            <div className="info-container">
                              <img className="not-found" src={EmptyFile} alt="" />
                            </div>
                          ) : (
                            <> {renderHomes(currentAvailablePage)}</>
                          )}
                        </HomeListStyles>
                      </Box>
                      <Box mt={2}>
                        <Pagination
                          mt="2"
                          color="standard"
                          // style={{ color: 'green' }}
                          count={availableCount}
                          page={availablePage}
                          siblingCount={1}
                          boundaryCount={1}
                          variant="outlined"
                          shape="rounded"
                          onChange={handleAvailablePageChange}
                        />
                      </Box>
                    </Paper>
                  ) : null}
                </Grid>
                {isAdmin ? (
                  <Fragment>
                    {' '}
                    {status.Unavailable ? (
                      <Paper className={classes.paper} style={{ width: '100%', padding: 32, margin: '50px  0' }}>
                        <Box m={1}>
                          <HomeListStyles>
                            <h1 className="heading" style={{ color: 'rgb(199, 36, 42)' }}>
                              Currently Unvailable
                            </h1>
                            {currentUnavailablePage?.length === 0 ? (
                              <div className="info-container">
                                <img className="not-found" src={EmptyFile} alt="" />
                              </div>
                            ) : (
                              <>{renderHomes(currentUnavailablePage)} </>
                            )}
                          </HomeListStyles>
                        </Box>
                        <Box mt={2}>
                          <Pagination
                            mt="2"
                            color="secondary"
                            // style={{ color: 'green' }}
                            count={unavailableCount}
                            page={unavailablePage}
                            siblingCount={1}
                            boundaryCount={1}
                            variant="outlined"
                            shape="rounded"
                            onChange={handleUnvailablePageChange}
                          />
                        </Box>
                      </Paper>
                    ) : null}
                  </Fragment>
                ) : null}

                {status.InProgress ? (
                  <Paper className={classes.paper} style={{ width: '100%', padding: 32, margin: '50px  0' }}>
                    <Box m={1}>
                      <HomeListStyles>
                        <h1 className="heading" style={{ color: 'rgb(8, 102, 173)' }}>
                          Coming Soon
                        </h1>
                        {currentInProcessPage?.length === 0 ? (
                          <div className="info-container">
                            <img className="not-found" src={EmptyFile} alt="" />
                          </div>
                        ) : (
                          <>{renderHomes(currentInProcessPage)} </>
                        )}
                      </HomeListStyles>
                    </Box>
                    <Box mt={2}>
                      <Pagination
                        mt="2"
                        color="primary"
                        // style={{ color: 'green' }}
                        count={inProcessCount}
                        page={inProcessPage}
                        siblingCount={1}
                        boundaryCount={1}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleComingSoonPageChange}
                      />
                    </Box>
                  </Paper>
                ) : null}
              </Fragment>
            )}
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  )
}

const HomeListStyles = styled.div`
  /* position: relative; */
  .heading {
    margin: 1rem auto;
    text-align: left;
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeDisplay, fontSizeDisplay);
    font-size: 2.5rem;
    color: var(--black, black);
  }
  .card-wrapper {
    position: relative;
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25ch, 1fr));
    grid-gap: 2.5rem;
    max-width: 100vw;
    width: 130ch;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 2rem auto;
  }
  .info-container {
    width: 100%;
    height: 100%;
  }
  .not-found {
    border-radius: 0.5rem 0.5rem 0 0;
    width: 100%;
    object-fit: contain;
    max-height: max(10rem, 50vh);
    aspect-ratio: 4/3;
  }
`

const useStyles = makeStyles({
  root: {
    minHeight: '100vh',
    position: 'relative',
  },
  paper: {
    position: 'relative',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
  title: {
    color: 'rgb(66, 66, 66)',
    fontWeight: 700,
    fontFamily: 'Roboto',
  },
  subtitle: {
    color: 'rgb(66, 66, 66)',
    fontWeight: 700,
    fontFamily: 'Roboto',
  },
  buttonAdd: {
    color: 'white',
    // margin: '0 1rem 2rem 1rem',
    // padding: '.2rem 2rem',
    fontSize: '1.4rem',
    backgroundColor: 'rgb(8, 102, 173)',
    '&:hover': {
      backgroundColor: 'rgb(11, 92, 153)',
    },
  },
})
export default HomeList
