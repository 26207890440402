import React, { useEffect, useContext, useMemo, useState } from 'react'
import { Grid, Typography, Box, Button } from '@material-ui/core'
import Resizer from 'react-image-file-resizer'
import { useDropzone } from 'react-dropzone'
import PhotoWidgetDropzone from '../../../../components/upload/PhotoWidgetDropzone'

import { showErrorToast, showSuccessToast } from '../../../../libs/Utils'
import { InputField, SelectField, DatePickerField } from '../../../../components/FormFields'
import { convertArrToObject } from '../../../../libs/Utils'
import {
  numberOptions,
  animalNumberOptions,
  yesNoOptions,
  states,
  citiesArray,
  homeStatusOptions,
  mentorTypeOptions,
} from '../../../../libs/selectFieldOptions'
import { RegionContext } from '../../../../contexts/regionContext'
import { ProgramContext } from '../../../../contexts/programContext'
import RegionSelectApiDropdownAddHome from '../../../../components/RegionSelectApiDropdownAddHome'

const convertedCities = convertArrToObject(citiesArray)
let appRender = 0
export default function HomeInfoForm(props) {
  const { regions, region } = useContext(RegionContext)
  const { programs } = useContext(ProgramContext)

  // const convertedRegions = convertArrToObject(regions)
  // const sortedPrograms = convertedPrograms.sort((a, b) => (a < b ? 1 : -1))
  const convertedPrograms = convertArrToObject(programs)
  const { formik, setMainHomeImage, mainHomeImage, homeImages, setHomeImages, formType } = props

  const {
    formField: {
      program,
      mentorNames,
      mentorType,
      bedsAvailable,
      cats,
      dogs,
      fullBathrooms,
      homeDescription,
      videoUrl,
      address,
      address2,
      city,
      state,
      zipcode,
      status,
      tier,
      totalBeds,
      multiplePersons,
      seperateBaths,
      stairs,
      wheelChair,
      availabilityDate,
    },
  } = props
  useEffect(() => {
    formik.setFieldValue('region', region)
    ////Run Clean Up Function to reset program values
    return () => {
      formik.setFieldValue('program', '')
    }
  }, [region, regions])

  ///////////////////////////////Image Upload with Dropzone

  const { isDragActive, isDragAccept, isDragReject, getRootProps, getInputProps } = useDropzone({
    accept: 'image/png, image/jpg, image/jpeg',
    maxFiles: 8,

    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 1) {
        showErrorToast('Can Only Upload 8 Images')
      }
      if (homeImages.length + acceptedFiles.length > 8) {
        showErrorToast('Can Only Upload Eight Images')
      } else {
        const mergedFiles = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
        setHomeImages(homeImages.concat(mergedFiles))
      }
      ///Check if same file, if so dont add to the files
      // const sameFile =files.map((storedFile) => {
      //   acceptedFiles.map((acceptedFile) => {
      //     console.log('%c🚀 ~ file: HomeInfoForm.jsx ~ line 159 ~ acceptedFiles.map ~ accepteFile','color:pink', acceptedFile)
      //     console.log('%c🚀 ~ file: HomeInfoForm.jsx ~ line 158 ~ files.map ~ storedFile','color:pink', storedFile)
      //     if (storedFile.path === acceptedFile.path) {
      //       return true
      //       // console.log('%c THESE FILES ARE SAME', 'color:red')
      //     }
      //   })
      // })
      // if(files.length <1){
      //   showSuccessToast(' IMAGE Uploaded')
      //   const mergedFiles = acceptedFiles.map((file) =>
      //   Object.assign(file, {
      //     preview: URL.createObjectURL(file),
      //   })
      // )
      // setHomeImages(files.concat(mergedFiles))
      // }else{
      //   if(sameFile){
      //     showErrorToast('SAME IMAGE SELECTED')
      //   }else{
      //     const mergedFiles = acceptedFiles.map((file) =>
      //     Object.assign(file, {
      //       preview: URL.createObjectURL(file),
      //     })
      //   )
      //   setHomeImages(files.concat(mergedFiles))
      //   }
      // }
    },
  })

  ///////////////////Main Image//////////////////////////////////
  const onMainImageDelete = (file) => {
    setMainHomeImage([])
    // formik.setFieldError('mainImage', 'Required')
    formik.setFieldTouched('mainImage', false)
    formik.setFieldValue('mainImage', '')
  }
  useEffect(() => {
    mainHomeImage &&
      mainHomeImage.forEach((file) => {
        mainImageResizer(file)
      })
  }, [mainHomeImage])

  const mainImageResizer = (file) => {
    if (file) {
      try {
        Resizer.imageFileResizer(
          file,
          500,
          500,
          'JPEG',
          100,
          0,
          (uri) => {
            formik.setFieldValue('mainImage', uri.slice(23))
          },
          'base64',
          200,
          200
        )
      } catch (err) {
        console.log(err)
      }
    }
  }

  useEffect(() => {
    if (formik.errors.mainImage === 'Required' && formik.touched.mainImage === true) {
      showErrorToast('Please Select A Main Image')
      formik.setFieldTouched('mainImage', false)
    }
  }, [formik.touched.mainImage])

  // const setMainImage = (file) => {
  //   mainImageResizer(file)
  //   setMainHomeImage(file)
  //   ///Set Main Image in
  // }

  ///////////////////Additional Images Image//////////////////////////////////

  useEffect(() => {
    formik.setFieldValue('additionalImages', homeImages)
    if (homeImages.length < 1) {
      formik.setFieldValue('additionalImages', [])
    }
  }, [homeImages])

  const onDelete = (file) => {
    setHomeImages((curr) =>
      curr.filter((fw) => {
        return fw.preview !== file.preview
      })
    )
  }

  useEffect(() => {
    if (
      homeImages.length === 0 &&
      formik.touched.additionalImages?.length < 1 &&
      formik.touched.additionalImages[0] !== true
    ) {
      showErrorToast('Please Add Images')
    }
  }, [formik.touched.additionalImages, homeImages])

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: formik.errors.mainImage === 'Required' && formik.touched.mainImage === 'true' ? 'red' : '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    cursor: 'pointer',
    color: '#666666',
    fontSize: '2rem',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  }

  const activeStyle = {
    borderColor: '#2196f3',
  }

  const acceptStyle = {
    borderColor: '#00e676',
  }

  const rejectStyle = {
    borderColor: '#ff1744',
  }
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  }

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    // border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 400,
    height: 250,
    padding: 4,
    boxSizing: 'border-box',
  }

  const thumbInner = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    overflow: 'hidden',
  }
  const buttonContainer = {
    // backgroundColor: 'rgb(66, 66, 66)',
    justifyContent: 'space-between',
    display: 'flex',
    transform: 'translateY(30px)',
  }

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
  }
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const mainImageThumb =
    mainHomeImage &&
    mainHomeImage?.map((file) => (
      <div style={thumb} key={file?.name || file?.picName}>
        <div style={thumbInner}>
          <div style={buttonContainer}>
            <Button size="small" color="secondary" variant="contained" onClick={() => onMainImageDelete(file)}>
              Delete
            </Button>
            <Button size="small" color="primary" variant="contained" style={{ cursor: 'default' }}>
              Main Image
            </Button>
          </div>

          <img src={file.url || file.preview} style={img} alt="" />
        </div>
      </div>
    ))

  const additionalImagesThumbs =
    homeImages &&
    homeImages.map((file) => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <div style={buttonContainer}>
            <Button size="small" color="secondary" variant="contained" onClick={() => onDelete(file)}>
              Delete
            </Button>
            {/* <Button size="small" color="primary" variant="contained" onClick={() => setMainImage(file)}>

              Set Main Photo
            </Button> */}
          </div>
          <img src={file.url || file.preview} style={img} alt="" />
        </div>
      </div>
    ))

  return (
    <React.Fragment>
      <Box my={2}>
        <Typography variant="h5" gutterBottom>
          Home Detail
        </Typography>
      </Box>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={3}>
          {/* <SelectField name={region.name} label={region.label} data={convertedRegions} fullWidth /> */}
          {formType === 'edit' ? (
            <RegionSelectApiDropdownAddHome name={region} disabled />
          ) : (
            <RegionSelectApiDropdownAddHome name={region} />
          )}
        </Grid>
        {formType === 'edit' ? (
          <Grid item xs={12} sm={2}>
            <SelectField name={program.name} label={program.label} data={convertedPrograms} fullWidth disabled />
          </Grid>
        ) : (
          <Grid item xs={12} sm={2}>
            <SelectField name={program.name} label={program.label} data={convertedPrograms} fullWidth />
          </Grid>
        )}
        <Grid item xs={12} sm={3}>
          <InputField name={mentorNames.name} label={mentorNames.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SelectField name={mentorType.name} label={mentorType.label} data={mentorTypeOptions} fullWidth />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SelectField name={status.name} label={status.label} data={homeStatusOptions} fullWidth />
        </Grid>
        <Grid item xs={12} md={2}>
          <DatePickerField
            name={availabilityDate.name}
            label={availabilityDate.label}
            format="MM/dd/yy"
            views={['year', 'month', 'date']}
            minDate={new Date()}
            maxDate={new Date('2050/12/31')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          {formType === 'edit' ? (
            <InputField name={address.name} label={address.label} fullWidth disabled />
          ) : (
            <InputField name={address.name} label={address.label} fullWidth />
          )}
        </Grid>
        <Grid item xs={12} sm={2}>
          {formType === 'edit' ? (
            <InputField name={address2.name} label={address2.label} fullWidth disabled />
          ) : (
            <InputField name={address2.name} label={address2.label} fullWidth />
          )}
        </Grid>
        <Grid item xs={12} sm={2}>
          {formType === 'edit' ? (
            <SelectField name={city.name} label={city.label} data={convertedCities} fullWidth disabled />
          ) : (
            <SelectField name={city.name} label={city.label} data={convertedCities} fullWidth />
          )}
        </Grid>
        <Grid item xs={12} sm={2}>
          {formType === 'edit' ? (
            <SelectField name={state.name} label={state.label} data={states} fullWidth disabled />
          ) : (
            <SelectField name={state.name} label={state.label} data={states} fullWidth />
          )}
        </Grid>
        <Grid item xs={12} sm={2}>
          {formType === 'edit' ? (
            <InputField name={zipcode.name} label={zipcode.label} fullWidth disabled />
          ) : (
            <InputField name={zipcode.name} label={zipcode.label} fullWidth />
          )}
        </Grid>
        <Grid item xs={12} sm={2}>
          <SelectField name={totalBeds.name} label={totalBeds.label} data={numberOptions} fullWidth />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SelectField name={bedsAvailable.name} label={bedsAvailable.label} data={numberOptions} fullWidth />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SelectField name={fullBathrooms.name} label={fullBathrooms.label} data={numberOptions} fullWidth />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SelectField name={tier.name} label={tier.label} data={numberOptions} fullWidth />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField name={multiplePersons.name} label={multiplePersons.label} data={yesNoOptions} fullWidth />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SelectField name={cats.name} label={cats.label} data={animalNumberOptions} fullWidth />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SelectField name={dogs.name} label={dogs.label} data={animalNumberOptions} fullWidth />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SelectField name={seperateBaths.name} label={seperateBaths.label} data={yesNoOptions} fullWidth />
        </Grid>
        <Grid item xs={12} sm={1}>
          <SelectField name={stairs.name} label={stairs.label} data={yesNoOptions} fullWidth />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SelectField name={wheelChair.name} label={wheelChair.label} data={yesNoOptions} fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField name={videoUrl.name} label={videoUrl.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField
            name={homeDescription.name}
            label={homeDescription.label}
            fullWidth
            variant="outlined"
            multiline
            rows={6}
          />
        </Grid>
        <Box m={2}>
          <section className="">
            <PhotoWidgetDropzone setFiles={setMainHomeImage} />
            {<aside style={thumbsContainer}>{mainImageThumb}</aside>}
            {/* {(mainHomeImage?.preview?.length > 1 && <aside style={thumbsContainer}>{mainImageThumb}</aside>) ||
              (mainHomeImage?.url?.length > 1 && <aside style={thumbsContainer}>{mainImageThumb}</aside>)
              } */}
          </section>
        </Box>
        <Box m={4}>
          <section className="">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop Additional Home images here, or click to select image</p>
              <em>(Only 8 Images will be accepted)</em>
            </div>
            <aside style={thumbsContainer}>{additionalImagesThumbs}</aside>
          </section>
        </Box>
      </Grid>
    </React.Fragment>
  )
}
