export const regions = [
  {
    value: 'RCEB - Regional Center of East Bay ',
    label: 'RCEB - Regional Center of East Bay ',
  },
  {
    value: 'FNRC - Far Northern Regional Center',
    label: 'FNRC - Far Northern Regional Center',
  },
  {
    value: 'FLRC - Frank D. Lanterman Regional Center',
    label: 'FLRC - Frank D. Lanterman Regional Center',
  },
  {
    value: 'GGRC - Golden Gate Regional Center',
    label: 'GGRC - Golden Gate Regional Center',
  },
  {
    value: 'HRC - Harbor Regional Center',
    label: 'HRC - Harbor Regional Center',
  },
  {
    value: 'IRC - Inland Regional Center',
    label: 'IRC - Inland Regional Center',
  },
  {
    value: 'KRC - Kern Regional Center',
    label: 'KRC - Kern Regional Center',
  },
  {
    value: 'NBRC - North Bay Regional Center',
    label: 'NBRC - North Bay Regional Center',
  },
  {
    value: 'NLACRC - North Los Angeles County Regional Center',
    label: 'NLACRC - North Los Angeles County Regional Center',
  },
]

export const programs = [
  {
    value: 'East Bay FHA',
    label: 'East Bay FHA',
  },
  {
    value: 'Golden Gate FHA',
    label: 'Golden Gate FHA',
  },
  {
    value: 'Orange County',
    label: 'Orange County',
  },
]
export const numberOptions = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: '6',
    label: '6+',
  },
]
export const animalNumberOptions = [
  {
    value: '0',
    label: '0',
  },
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5+',
  },
]
export const yesNoOptions = [
  {
    value: 'Y',
    label: 'Yes',
  },
  {
    value: 'N',
    label: 'No',
  },
]
export const homeStatusOptions = [
  {
    value: 'Avail',
    label: 'Available',
  },
  {
    value: 'IP',
    label: 'Coming Soon',
  },
  {
    value: 'UnAvail',
    label: 'Unavailable',
  },
]
export const mentorTypeOptions = [
  {
    value: 'Primary',
    label: 'Primary',
  },
  {
    value: 'Secondary',
    label: 'Secondary',
  },
  {
    value: 'Substitute',
    label: 'Substitute',
  },
]
export const states = [
  {
    value: 'CA',
    label: 'CA',
  },
]
export const citiesArray = [
  'Alameda',
  'Alhambra',
  'Aliso Viejo',
  'Anaheim',
  'Antioch',
  'Apple Valley',
  'Arcadia',
  'Azusa',
  'Bakersfield',
  'Baldwin Park',
  'Beaumont',
  'Bellflower',
  'Bell Gardens',
  'Berkeley',
  'Brea',
  'Brentwood',
  'Buena Park',
  'Burbank',
  'Calexico',
  'Camarillo',
  'Campbell',
  'Carlsbad',
  'Carson',
  'Cathedral City',
  'Ceres',
  'Cerritos',
  'Chico',
  'Chino',
  'Chino Hills',
  'Chula Vista',
  'Citrus Heights',
  'Clovis',
  'Coachella',
  'Colton',
  'Compton',
  'Concord',
  'Corona',
  'Costa Mesa',
  'Covina',
  'Culver City',
  'Cupertino',
  'Cypress',
  'Daly City',
  'Danville',
  'Davis',
  'Delano',
  'Diamond Bar',
  'Downey',
  'Dublin',
  'Eastvale',
  'El Cajon',
  'El Centro',
  'Elk Grove',
  'El Monte',
  'Encinitas',
  'Escondido',
  'Fairfield',
  'Folsom',
  'Fontana',
  'Fountain Valley',
  'Fremont',
  'Fresno',
  'Fullerton',
  'Gardena',
  'Garden Grove',
  'Gilroy',
  'Glendale',
  'Glendora',
  'Hanford',
  'Hawthorne',
  'Hayward',
  'Hemet',
  'Hesperia',
  'Highland',
  'Huntington Beach',
  'Huntington Park',
  'Indio',
  'Inglewood',
  'Irvine',
  'Jurupa Valley',
  'Laguna Niguel',
  'La Habra',
  'Lake Elsinore',
  'Lake Forest',
  'Lakewood',
  'La Mesa',
  'La Mirada',
  'Lancaster',
  'La Puente',
  'La Quinta',
  'Lincoln',
  'Livermore',
  'Lodi',
  'Lompoc',
  'Long Beach',
  'Los Angeles',
  'Los Banos',
  'Lynwood',
  'Madera',
  'Manteca',
  'Martinez',
  'Menifee',
  'Merced',
  'Milpitas',
  'Mission Viejo',
  'Modesto',
  'Monrovia',
  'Montclair',
  'Montebello',
  'Monterey Park',
  'Moreno Valley',
  'Morgan Hill',
  'Mountain View',
  'Murrieta',
  'Napa',
  'National City',
  'Newark',
  'Newport Beach',
  'Norwalk',
  'Novato',
  'Oakland',
  'Oakley',
  'Oceanside',
  'Ontario',
  'Orange',
  'Oxnard',
  'Pacifica',
  'Palmdale',
  'Palm Desert',
  'Palm Springs',
  'Palo Alto',
  'Paramount',
  'Pasadena',
  'Perris',
  'Petaluma',
  'Pico Rivera',
  'Pittsburg',
  'Placentia',
  'Pleasanton',
  'Pomona',
  'Porterville',
  'Poway',
  'Rancho Cordova',
  'Rancho Cucamonga',
  'Rancho Palos Verdes',
  'Rancho Santa Margarita',
  'Redding',
  'Redlands',
  'Redondo Beach',
  'Redwood City',
  'Rialto',
  'Richmond',
  'Riverside',
  'Rocklin',
  'Rohnert Park',
  'Rosemead',
  'Roseville',
  'Sacramento',
  'Salinas',
  'San Bernardino',
  'San Bruno',
  'San Buenaventura (Ventura)',
  'San Clemente',
  'San Diego',
  'San Francisco',
  'San Gabriel',
  'San Jacinto',
  'San Jose',
  'San Leandro',
  'San Luis Obispo',
  'San Marcos',
  'San Mateo',
  'San Rafael',
  'San Ramon',
  'Santa Ana',
  'Santa Barbara',
  'Santa Clara',
  'Santa Clarita',
  'Santa Cruz',
  'Santa Maria',
  'Santa Monica',
  'Santa Rosa',
  'Santee',
  'Simi Valley',
  'South Gate',
  'South San Francisco',
  'Stanton',
  'Stockton',
  'Sunnyvale',
  'Temecula',
  'Thousand Oaks',
  'Torrance',
  'Trabuco Canyon',
  'Tracy',
  'Tulare',
  'Turlock',
  'Tustin',
  'Union City',
  'Upland',
  'Vacaville',
  'Vallejo',
  'Victorville',
  'Visalia',
  'Vista',
  'Walnut Creek',
  'Watsonville',
  'West Covina',
  'Westminster',
  'West Sacramento',
  'Whittier',
  'Woodland',
  'Yorba Linda',
  'Yuba City',
  'Yucaipa',
]
