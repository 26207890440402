import React from 'react'

import { Typography, Grid, Table, TableBody, TableCell, TableHead, TableRow, Paper, Box } from '@material-ui/core'
import moment from 'moment'
import useStyles from './styles'

function HomeDetail(props) {
  const { formValues } = props
  const classes = useStyles()
  const {
    region,
    program,
    mentorNames,
    mentorType,
    bedsAvailable,
    cats,
    dogs,
    fullBathrooms,
    tier,
    totalBeds,
    multiplePersons,
    seperateBaths,
    wheelChair,
    stairs,
    homeDescription,
    address,
    address2,
    city,
    state,
    zipcode,
    status,
    mhdesc,
    dayProgram,
    nearestHospital,
    nearestPark,
    nearestStore,
    bike0,
    bus0,
    train0,
    availabilityDate,
  } = formValues
  const formatAvailabilityDate = availabilityDate !== null && availabilityDate !== '' && moment(availabilityDate.slice(0, 10)).format('ll')
  return (
    <Grid item container direction="column" xs={12} sm={6}>
      {/* <Typography variant="h6" gutterBottom className={classes.title}>
        Home details
      </Typography> */}
      <Grid container>
        <Paper
          style={{ width: '100%', padding: 16, marginBottom: 32, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
          className="mb-5">
          <Box my={2}>
            <Typography className={classes.title} variant="h5">
              Home details
            </Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCelltitle}>Field</TableCell>
                <TableCell className={classes.tableCelltitle}>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Region</TableCell>
                <TableCell>{region}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Program</TableCell>
                <TableCell>{program}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mentor Names</TableCell>
                <TableCell>{mentorNames}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mentor Type</TableCell>
                <TableCell>{mentorType}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Home Status</TableCell>
                <TableCell>{status}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Home Availability Date</TableCell>
                <TableCell>{formatAvailabilityDate}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell>{address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Address 2</TableCell>
                <TableCell>{address2}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>City</TableCell>
                <TableCell>{city}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>State</TableCell>
                <TableCell>{state}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Zipcode</TableCell>
                <TableCell>{zipcode}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Number of Bedrooms</TableCell>
                <TableCell>{totalBeds}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Available Bedrooms</TableCell>
                <TableCell>{bedsAvailable}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Number of Full Baths</TableCell>
                <TableCell>{fullBathrooms}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tier Level</TableCell>
                <TableCell>{tier}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Multiple Person(s) Being Served</TableCell>
                <TableCell>{multiplePersons}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Cats</TableCell>
                <TableCell>{cats}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Dogs</TableCell>
                <TableCell>{dogs}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Seperate Bath</TableCell>
                <TableCell>{seperateBaths}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Stairs</TableCell>
                <TableCell>{stairs}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Wheelchair Accessible</TableCell>
                <TableCell>{wheelChair}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Home Description</TableCell>
                <TableCell>{homeDescription}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mentor Description</TableCell>
                <TableCell>{mhdesc}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Day Program</TableCell>
                <TableCell>{dayProgram === '' ? '' : `${dayProgram} miles`} </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Nearest Hospital</TableCell>
                <TableCell>{nearestHospital === '' ? '' : `${nearestHospital} miles`} </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Nearest Park</TableCell>
                <TableCell>{nearestPark === '' ? '' : `${nearestPark} miles`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Nearest Store</TableCell>
                <TableCell> {nearestStore === '' ? '' : `${nearestStore} miles`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Bike Description</TableCell>
                <TableCell>{bike0}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Train Description</TableCell>
                <TableCell>{train0}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Bus Description</TableCell>
                <TableCell>{bus0}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default HomeDetail
