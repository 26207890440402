import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js'
import { showErrorToast } from './Utils'
const userPoolId = process.env.REACT_APP_USERPOOL_ID
const clientId = process.env.REACT_APP_CLIENT_ID
// const userPoolId = "us-east-2_DQoK2Aage"
// const clientId = "27mm5c93cl325fs3k0udgasgsu"

// console.log(`userpool id=${userPoolId}`)
// console.log(`client id=${clientId}`)

const poolData = {
  UserPoolId: `${userPoolId}`,
  ClientId: `${clientId}`,
}

const userPool: CognitoUserPool = new CognitoUserPool(poolData)

let currentUser: any = userPool.getCurrentUser()

export function getCurrentUser() {
  return currentUser
}

function getCognitoUser(username: string) {
  const userData = {
    Username: username,
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)
  return cognitoUser
}

export async function getSession() {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser()
  }

  return new Promise(function (resolve, reject) {
    currentUser.getSession(function (err: any, session: any) {
      if (err) {
        reject(err)
      } else {
        resolve(session)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function verifyCode(username: string, code: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username)

    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        reject(err)
      } else {
        resolve(result)
      }
    })
  }).catch((err) => {
    throw err
  })
}

/////////////////////// CUSTOM MFA CONFIG NEEDS TO BE FINISHED/////////////////////////////
// export let mfaRequired = false

// export const cognitoCallbacks =(getMfaCode:any) => ({
//   mfaRequired () {
//     // Implement you functionality to show UI for MFA form

//     mfaRequired = true
//     console.log("🚀 ~ file: cognito.ts ~ line 118 ~ GET MFA CODE START")
//     getMfaCode()
//     console.log("🚀 ~ file: cognito.ts ~ line 118 ~ GET MFA CODE FINISH")
//   },
//   onSuccess (response: any) {
//     console.log("🚀 ~ file: cognito.ts ~ line 118 ~ GET Sucess COGNITO  CALLBACK")
//     console.log("🚀 ~ file: cognito.ts ~ line 119 ~ onSuccess ~ response", response)
//     // Dance for joy the code gods be glorious.
//   },
//   onFailure (err: any) {
//     console.log("🚀 ~ file: cognito.ts ~ line 123 ~ onFailure ~ err", err)

//     // Cry.
//   }
// })

// export async function signInWithEmail(username: string, password: string, getMfaCode:Function) {

//   return new Promise(function (resolve, reject) {
//     const authenticationData = {
//       Username: username,
//       Password: password,
//     }
//     const authenticationDetails = new AuthenticationDetails(authenticationData)
//     console.log("🚀 ~ file: cognito.ts ~ line 174 ~ GET DETAILS", authenticationDetails)

//     currentUser = getCognitoUser(username)

//     currentUser.authenticateUser(authenticationDetails, cognitoCallbacks(getMfaCode))

//     // currentUser.authenticateUser(authenticationDetails, {
//     //   onSuccess: function (res: any) {
//     //     resolve(res)
//     //   },
//     //   onFailure: function (err: any) {
//     //     reject(err)
//     //   },
//     //   mfaRequired: function() {
//     //     // var verificationCode = prompt('Please input verification code' ,'');
//     //     // currentUser.sendMFACode(verificationCode, this)
//     //    getMfaCode()
//     //    let verificationCode = setMfaCode();
//     //     console.log("🚀 ~ file: cognito.ts ~ line 131 ~ verificationCode", verificationCode)
//     //     setTimeout(() => {
//     //       console.log('This will run after 1 second!')
//     //       currentUser.sendMFACode(verificationCode,this)
//     //     }, 10000)
//     //       //  currentUser.sendMFACode(verificationCode,this)

//     //   // await getMfaCode();
//     //   // let verificationCode = await setMfaCode().then((verificationCode: any) => {
//     //   //   currentUser.sendMFACode(verificationCode)})
//     //   },
//     // })
//   }).catch((err) => {
//     throw err
//   })
// }
// export async function sendMfaCode (MFACode: string)  {
//   let test= "test";
//   await currentUser.sendMFACode(MFACode, cognitoCallbacks(test))
//   console.log("🚀 ~ file: cognito.ts ~ line 186 ~ sendMfaCode ~ currentUser", currentUser)
// }

/////////////////////// CUSTOM MFA CONFIG NEEDS TO BE FINISHED/////////////////////////////

/////////////////////// CUSTOM MFA CONFIG that works with a prompt/////////////////////////////
export async function signInWithEmail(username: string, password: string) {
  return new Promise(function (resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: password,
    }
    const authenticationDetails = new AuthenticationDetails(authenticationData)

    currentUser = getCognitoUser(username)

    currentUser.authenticateUser(authenticationDetails, {
      onSuccess: function (res: any) {
        resolve(res)
      },
      onFailure: function (err: any) {
        reject(err)
      },
      mfaRequired: function () {
        var verificationCode
        while (verificationCode == null) verificationCode = prompt('Please input verification code', '')
        // var verificationCode = prompt('Please input verification code', '')
        currentUser.sendMFACode(verificationCode, this)
      },
    })
  }).catch((err) => {
    throw err
  })
}

export function signOut() {
  if (currentUser) {
    currentUser.signOut()
  }
}

export async function getAttributes() {
  return new Promise(function (resolve, reject) {
    currentUser.getUserAttributes(function (err: any, attributes: any) {
      if (err) {
        reject(err)
      } else {
        resolve(attributes)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function setAttribute(attribute: any) {
  return new Promise(function (resolve, reject) {
    const attributeList = []
    const res = new CognitoUserAttribute(attribute)
    attributeList.push(res)

    currentUser.updateAttributes(attributeList, (err: any, res: any) => {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function sendCode(username: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username)

    if (!cognitoUser) {
      reject(`could not find ${username}`)
      return
    }

    cognitoUser.forgotPassword({
      onSuccess: function (res) {
        resolve(res)
      },
      onFailure: function (err) {
        reject(err)
      },
    })
  }).catch((err) => {
    throw err
  })
}

export async function forgotPassword(username: string, code: string, password: string, codeUsername: string) {
  return new Promise(function (resolve, reject) {
    if (codeUsername !== username) {
      showErrorToast('Wrong Username')
      reject(`You Entred Wrong Username`)
      return
    }

    const cognitoUser = getCognitoUser(username)

    if (!cognitoUser) {
      reject(`could not find ${username}`)
      return
    }

    cognitoUser.confirmPassword(code, password, {
      onSuccess: function () {
        resolve('password updated')
      },
      onFailure: function (err) {
        reject(err)
      },
    })
  })
}

export async function changePassword(oldPassword: string, newPassword: string) {
  return new Promise(function (resolve, reject) {
    currentUser.changePassword(oldPassword, newPassword, function (err: any, res: any) {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    })
  })
}
// export async function signUpUserWithEmail(username: string, lastname: string, email: string, password: string) {
//   return new Promise(function (resolve, reject) {
//     // var attributeList = []

//     // var dataEmail = {
//     //   Name: 'email',
//     //   Value: email,
//     // }

//     // var dataLastName = {
//     //   Name: 'custom:lastname',
//     //   Value: lastname,
//     // }

//     // var attributeEmail = new CognitoUserAttribute(dataEmail)
//     // var attributeLastName = new CognitoUserAttribute(dataLastName)

//     // attributeList.push(attributeEmail)
//     // attributeList.push(attributeLastName)

//     const attributeList = [
//       new CognitoUserAttribute({
//         Name: 'email',
//         Value: email,
//       }),
//       new CognitoUserAttribute({
//         Name: 'custom:lastname',
//         Value: lastname,
//       }),
//     ]
//     console.log(attributeList)
//     userPool.signUp(username, password, attributeList, [], function (err, res) {
//       if (err) {
//         reject(err)
//       } else {
//         resolve(res)
//       }
//     })
//   }).catch((err) => {
//     throw err
//   })
// }
