import * as Yup from 'yup'
import moment from 'moment'
import { addHomeFormModel } from './addHomeFormModel'

const {
  formField: {
    mainImage,
    additionalImages,
    region,
    program,
    mentorNames,
    mentorType,
    totalBeds,
    cats,
    dogs,
    fullBathrooms,
    tier,
    bedsAvailable,
    multiplePersons,
    seperateBaths,
    stairs,
    wheelChair,
    homeDescription,
    videoUrl,
    address,
    address2,
    city,
    state,
    zipcode,
    status,
    mhdesc,
    mentorHighLightImage,
    mhvid,
    dayProgram,
    mapI,
    nearestHospital,
    nearestPark,
    nearestStore,
    bike0,
    bike1,
    bus0,
    bus1,
    train0,
    train1,
    availabilityDate,
  },
} = addHomeFormModel

export const validationSchema = [
  Yup.object().shape({
    [mainImage.name]: Yup.string().required(`${mainImage.requiredErrorMsg}`),
    // [additionalImages.name]: Yup.array().required(`${additionalImages.requiredErrorMsg}`),
    [additionalImages.name]: Yup.array()
      .min(1, "You can't leave this blank.")
      .required("You can't leave this blank.")
      .nullable(),
    [region.name]: Yup.string().required(`${region.requiredErrorMsg}`),
    [program.name]: Yup.string().required(`${program.requiredErrorMsg}`),
    [mentorNames.name]: Yup.string().max(100).required(`${mentorNames.requiredErrorMsg}`),
    [mentorType.name]: Yup.string().required(`${mentorType.requiredErrorMsg}`),
    [bedsAvailable.name]: Yup.number().required(`${bedsAvailable.requiredErrorMsg}`),
    [cats.name]: Yup.number().required(`${cats.requiredErrorMsg}`),
    [dogs.name]: Yup.number().required(`${dogs.requiredErrorMsg}`),
    [fullBathrooms.name]: Yup.number().required(`${fullBathrooms.requiredErrorMsg}`),
    [tier.name]: Yup.number().required(`${tier.requiredErrorMsg}`),
    [totalBeds.name]: Yup.number().required(`${totalBeds.requiredErrorMsg}`),
    [multiplePersons.name]: Yup.string().required(`${multiplePersons.requiredErrorMsg}`),
    [seperateBaths.name]: Yup.string().required(`${seperateBaths.requiredErrorMsg}`),
    [stairs.name]: Yup.string().required(`${stairs.requiredErrorMsg}`),
    [wheelChair.name]: Yup.string().required(`${wheelChair.requiredErrorMsg}`),
    [homeDescription.name]: Yup.string().max(255).required(`${homeDescription.requiredErrorMsg}`),
    [videoUrl.name]: Yup.string(),
    [address.name]: Yup.string().max(100).required(`${address.requiredErrorMsg}`),
    [address2.name]: Yup.string().max(40),
    [city.name]: Yup.string().required(`${city.requiredErrorMsg}`),
    [state.name]: Yup.string().required(`${state.requiredErrorMsg}`),
    [status.name]: Yup.string().required(`${status.requiredErrorMsg}`),
    [zipcode.name]: Yup.string()
      .required(`${zipcode.requiredErrorMsg}`)
      .test('len', `${zipcode.invalidErrorMsg}`, (val) => val && val.length === 5),
    [availabilityDate.name]: Yup.string()
      .nullable()
      // .required(`${availabilityDate.requiredErrorMsg}`)
      // .test('expDate',  (val) => {
      //   if (val) {
      //     const startDate = new Date()
      //     const endDate = new Date(2050, 12, 31)
      //     if (moment(val, moment.ISO_8601).isValid()) {
      //       return moment(val).isBetween(startDate, endDate)
      //     }
      //     return false
      //   }
      //   return false
      // }),
  }),
  Yup.object().shape({
    [mhdesc.name]: Yup.string().max(500).required(`${mhdesc.requiredErrorMsg}`),
    [mentorHighLightImage.name]: Yup.string().required(`${mentorHighLightImage.requiredErrorMsg}`),
    [mhvid.name]: Yup.string(),
  }),
  Yup.object().shape({
    [dayProgram.name]: Yup.string().max(100),
    [mapI.name]: Yup.string().required(`${mapI.requiredErrorMsg}`),
    [nearestHospital.name]: Yup.string().max(100),
    [nearestPark.name]: Yup.string().max(100),
    [nearestStore.name]: Yup.string().max(100),
    [bike0.name]: Yup.string().max(100),
    [bike1.name]: Yup.string().max(100),
    [bus0.name]: Yup.string().max(100),
    [bus1.name]: Yup.string().max(100),
    [train0.name]: Yup.string().max(100),
    [train1.name]: Yup.string().max(100),
  }),
]
