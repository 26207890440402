import React, { useState, useEffect, useContext } from 'react'
import * as cognito from '../libs/cognito'
import axios from 'axios'
//  import { useLocalStorageState } from '../hooks/useLocalStorageState'
import { BASE_URL } from '../libs/tools'

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
  IsAdmin,
}

export interface IAuth {
  sessionInfo?: {
    username?: string
    lastname?: string
    email?: string
    sub?: string
    accessToken?: string
    refreshToken?: string
    cognitoGroup?: Array<any>
  }
  getRefreshSession?: any
  sendCodeUsername?: any
  setSendCodeUsername?: any
  attrInfo?: any
  authStatus?: AuthStatus
  signInWithEmail?: any
  signUpWithEmail?: any
  signOut?: any
  verifyCode?: any
  getSession?: any
  sendCode?: any
  sendMfaCode?: any
  forgotPassword?: any
  changePassword?: any
  getAttributes?: any
  setAttribute?: any
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
}

export const AuthContext = React.createContext(defaultState)

export const AuthIsSignedIn: React.FunctionComponent = ({ children }) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const AuthIsAdmin: React.FunctionComponent = ({ children }) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.IsAdmin ? children : null}</>
}

export const AuthIsNotSignedIn: React.FunctionComponent = ({ children }) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}

const AuthProvider: React.FunctionComponent = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading)
  const [sessionInfo, setSessionInfo] = useState({})
  const [attrInfo, setAttrInfo] = useState([])
  const [sendCodeUsername, setSendCodeUsername] = useState('')

  useEffect(() => {
    async function getSessionInfo() {
      try {
        const session: any = await getSession()
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
        })
        window.localStorage.setItem('accessToken', `${session.accessToken.jwtToken}`)
        window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)
        const attr: any = await getAttributes()
        setAttrInfo(attr)
        if (session.getIdToken().payload['cognito:groups'][0] === 'ADMINS') {
          setAuthStatus(AuthStatus.IsAdmin)
        } else if (session.getIdToken().payload['cognito:groups'][0] === 'CASEMANAGERS') {
          setAuthStatus(AuthStatus.SignedIn)
        }
      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut)
      }
    }
    getSessionInfo()
  }, [setAuthStatus, authStatus])

  if (authStatus === AuthStatus.Loading) {
    return null
  }

  async function signInWithEmail(username: string, password: string) {
    try {
      await cognito.signInWithEmail(username, password)
      setAuthStatus(AuthStatus.Loading)
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut)
      throw err
    }
  }

  /////////////////////// CUSTOM MFA CONFIG NEEDS TO BE FINISHED/////////////////////////////
  // async function signInWithEmail(username: string, password: string, getMfaCode: Function) {
  //   try {
  //     console.log('$$$$$$$$$$$$$$$$$$$$   AUTH SIGN IN WITH EMAIL START')
  //     await cognito.signInWithEmail(username, password, getMfaCode)
  //     console.log('$$$$$$$$$$$$$$$$$$$$   AUTH SIGN IN WITH EMAIL FINISH')
  //     setAuthStatus(AuthStatus.SignedIn)
  //   } catch (err) {
  //     setAuthStatus(AuthStatus.SignedOut)
  //     throw err
  //   }
  // }

  // async function sendMfaCode(MFACode: string) {
  //   try {
  //     console.log('$$$$$$$$$$$$$$$$$$$$   AUTHCONTEXT SEND CODE START')

  //     await cognito.sendMfaCode(MFACode)
  //     console.log('$$$$$$$$$$$$$$$$$$$$   AUTHCONTEXT SEND CODE FINISH')
  //     console.log('$$$$$$$$$$$$$$$$$$$$   AUTHCONTEXT SET USER START')
  //     setAuthStatus(AuthStatus.SignedIn)
  //     history.push('landing')
  //     console.log('$$$$$$$$$$$$$$$$$$$$   COGNITO SET USER FINISH')
  //   } catch (err) {
  //     setAuthStatus(AuthStatus.SignedOut)
  //     throw err
  //   }
  // }

  /////////////////////// CUSTOM MFA CONFIG NEEDS TO BE FINISHED/////////////////////////////

  async function signUpWithEmail(
    email: string,
    lastname: string,
    firstname: string,
    organization: string,
    region: string,
    phone: string,
    phoneExt: string,
    password: string
  ) {
    // try {
    let phoneConvert = phone
      .replace(/\D+/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '+$1$2$3')
      .concat('', phoneExt)

    const dataToSubmit = {
      casemanager: {
        status: 'PD',
        fName: firstname,
        lName: lastname,
        phone: phoneConvert,
        email: email.toLowerCase(),
        organization: organization,
        passwd: password,
        region: `ORG#CSS#ST#CA#REG#${region}`,
      },
    }
    const headers = {}
    await axios
      .post(`${BASE_URL}/registerCaseManager`, dataToSubmit, {
        headers,
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        let err = error.response.data.error
        throw err
      })
  }

  function signOut() {
    cognito.signOut()
    window.localStorage.removeItem('caseManagerInfo')
    window.localStorage.removeItem('caseManagerEmail')
    window.localStorage.removeItem('pendingCaseManagers')
    window.localStorage.removeItem('approvedCaseManagers')
    window.localStorage.removeItem('deniedCaseManagers')
    window.localStorage.removeItem('caseManagerToUpdate')
    window.localStorage.removeItem('programContact')
    window.localStorage.removeItem('mentorEvents')
    window.localStorage.removeItem('mentorEvent')
    window.localStorage.removeItem('currentRegion')
    window.localStorage.setItem('preferenceSearch', 'false')
    window.localStorage.setItem('availablePaginated', 'false')
    window.localStorage.setItem('unavailablePaginated', 'false')
    window.localStorage.setItem('comingSoonPaginated', 'false')
    window.localStorage.removeItem('homePreferences')
    setAuthStatus(AuthStatus.SignedOut)
  }

  async function verifyCode(username: string, code: string) {
    try {
      await cognito.verifyCode(username, code)
    } catch (err) {
      throw err
    }
  }

  async function getSession() {
    try {
      const session = await cognito.getSession()
      return session
    } catch (err) {
      throw err
    }
  }
  async function getRefreshSession() {
    try {
      const session: any = await cognito.getSession()
      // console.log('REFRESH SESSION WITH NEW TOKEN')

      setSessionInfo({
        accessToken: session.accessToken.jwtToken,
        refreshToken: session.refreshToken.token,
      })
      // console.log('NEW TOKEN Session set')
      window.localStorage.setItem('accessToken', `${session.accessToken.jwtToken}`)
      window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)
      // console.log('NEW TOKEN Local Storage set')
    } catch (err) {
      throw err
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes()
      return attr
    } catch (err) {
      throw err
    }
  }

  async function setAttribute(attr: any) {
    try {
      const res = await cognito.setAttribute(attr)
      return res
    } catch (err) {
      throw err
    }
  }

  async function sendCode(username: string) {
    try {
      await cognito.sendCode(username)
    } catch (err) {
      throw err
    }
  }

  async function forgotPassword(username: string, code: string, password: string, codeUsername: string) {
    try {
      await cognito.forgotPassword(username, code, password, codeUsername)
    } catch (err) {
      throw err
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      await cognito.changePassword(oldPassword, newPassword)
    } catch (err) {
      throw err
    }
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    signUpWithEmail,
    signInWithEmail,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    // sendMfaCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
    sendCodeUsername,
    setSendCodeUsername,
    getRefreshSession,
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider
