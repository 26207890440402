import React from 'react'
import styled from 'styled-components'
import logo from '../../resources/images/FHA_logo_white.png'
import logoBlue from '../../resources/images/FHA_logo_blue.png'

const HeroStyles = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 0;
  & img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 50vh;
    opacity: 1;
    background-size: cover;
  }
  .logo {
    position: absolute;
    top: 75%;
    left: 2%;
    width: 180px;
    height: auto;
    color: var(--white, white);
    font-size: 10rem;
    z-index: 10;
    ${({ flip }) =>
      flip &&
      `left:auto;
      right: 5% !important;

  `}
    & img {
      object-fit: cover;
      display: block;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  }
`

const Hero = ({ src, alt, children, flip }) => {
  return (
    <HeroStyles flip={flip}>
    {/* style={{backgroundImage: `url(${src})`, height:'50vh', width:'100%', backgroundPosition:'top'}} */}
      <img src={src} alt={alt} />
      {children}
      <div className="logo">
        {flip ? (
          <img src={logoBlue} alt="California Mentor Logo Blue" />
        ) : (
          <img src={logo} alt="California Mentor Logo" />
        )}
      </div>
    </HeroStyles>
  )
}

export default Hero
