import React, { Fragment, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Header from '../../components/layout/Header'
import { DataGrid } from '@material-ui/data-grid'
import { Paper, Box, Typography } from '@material-ui/core'

import { MentorContext } from '../../contexts/mentorContext'

const columns = [
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 250,
  },
  {
    field: 'firstName',
    headerName: 'First name',
    width: 250,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
  },
  {
    field: 'organization',
    headerName: 'Organization',
    width: 250,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    type: 'number',
    width: 250,
  },
]

const MentorEventRegistrations = () => {
  const { mentorEvent } = useContext(MentorContext)
  const registeredCaseManagers = mentorEvent.registeredCaseManagers?.map((casemanager) => {
    return {
      id: uuidv4(),
      comments: casemanager.comments,
      email: casemanager.email,
      firstName: casemanager.firstName,
      lastName: casemanager.lastName,
      organization: casemanager.organization,
      phone: casemanager.phone,
    }
  })
  return (
    <Fragment>
      <Header back />
      <Paper style={{ width: '70%', margin: '50px auto', padding: 16, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
        <Box m={4}>
          <Typography
            style={{ color: 'rgb(66, 66, 66)', fontWeight: 500, fontFamily: 'Roboto' }}
            variant="h4">{`${mentorEvent.name}`}</Typography>
        </Box>
        <div style={{ height: 650, width: '100%' }}>
          <DataGrid rows={registeredCaseManagers} columns={columns} pageSize={10} />
        </div>
      </Paper>
    </Fragment>
  )
}

export default MentorEventRegistrations
