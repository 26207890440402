import React, { Fragment, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components'
import Header from '../../components/layout/Header'
import MentorCards from './MentorCards'
import { Grid, Box, Button } from '@material-ui/core/'
import { MentorContext } from '../../contexts/mentorContext'
import { AuthContext } from '../../contexts/authContext'
import Loader from '../../libs/loader'
import EmptyFile from '../../resources/images/EmptyFile2.png'

const Mentors = () => {
  const { authStatus } = useContext(AuthContext)
  const { mentorEvents, setMentorEvent, isLoading } = useContext(MentorContext)

  const renderMentorcards = () => {
    return (
      <ul className="card-wrapper">
        {!mentorEvents || mentorEvents.length === 0 ? (
          <div className="info-container">
            <h1>Events Coming Soon!</h1>
            <img className="not-found" src={EmptyFile} alt="" />
          </div>
        ) : (
          <>
            {mentorEvents &&
              mentorEvents.map((mentor, index) => {
                return <MentorCards authStatus={authStatus} mentor={mentor} key={index} />
              })}
          </>
        )}
      </ul>
    )
  }
  useEffect(() => {
    document.title = 'Meet Our Mentors | California Mentor'
  }, [])
  return (
    <Fragment>
      <Header back contact />
      <MentorStyles>
        <h1 className="heading">Upcoming Events</h1>
        <Grid container direction="row" justifyContent="center" alignItems="flex-start">
          <Grid lg={6} container direction="row" justifyContent="center" alignItems="center" item>
            {authStatus && authStatus === 3 ? (
              <Box style={{ width: '100%' }}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                  <Box>
                    <Link to="/admin-mentor-events/add">
                      <Button onClick={() => setMentorEvent([])} size="large" variant="contained" color="primary">
                        Add Event
                      </Button>
                    </Link>
                  </Box>
                </Grid>
              </Box>
            ) : null}
          </Grid>
        </Grid>

        <>{isLoading ? <Loader /> : <ul className="card-wrapper">{renderMentorcards()}</ul>}</>
      </MentorStyles>
    </Fragment>
  )
}
const MentorStyles = styled.div`
  /* margin: 5rem; */
  .heading {
    margin: 6rem auto;
    text-align: center;
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeDisplay, fontSizeDisplay);
    color: var(--black, black);
    text-decoration: underline;
  }
  .card-wrapper {
    position: relative;
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30ch, 1fr));
    grid-gap: 2.5rem;
    max-width: 100vw;
    width: 130ch;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 2rem auto;
  }
  .info-container {
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .not-found {
    border-radius: 0.5rem 0.5rem 0 0;
    width: 100%;
    object-fit: contain;
    max-height: max(10rem, 50vh);
    aspect-ratio: 4/3;
  }
`
export default Mentors
