import React, { useEffect, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import Resizer from 'react-image-file-resizer'
import { Grid, Typography, Box, Button } from '@material-ui/core'
import { InputField } from '../../../../components/FormFields'
import { showErrorToast } from '../../../../libs/Utils'

const PublicTransportation = (props) => {
  const {
    formField: { dayProgram, nearestHospital, nearestPark, nearestStore, bike0, bike1, bus0, bus1, train0, train1 },
  } = props
  const { formik, mapImage, setMapImage } = props

  const { isDragActive, isDragAccept, isDragReject, getRootProps, getInputProps } = useDropzone({
    accept: 'image/png, image/jpg, image/jpeg',
    maxFiles: 1,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 1) {
        showErrorToast('Can Only Upload One Image')
      }
      setMapImage(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    },
  })

  const fileChangedHandler = (file) => {
    if (file) {
      try {
        Resizer.imageFileResizer(
          file,
          500,
          500,
          'JPEG',
          100,
          0,
          (uri) => {
            formik.setFieldValue('mapI', uri)
          },
          'base64',
          200,
          200
        )
      } catch (err) {
        console.log(err)
      }
    }
  }
  useEffect(() => {
    if (formik.errors.mapI === 'Required' && formik.touched.mapI === true) {
      showErrorToast('Please Select A Map Image')
      formik.setFieldTouched('mapI', false)
    }
  }, [formik.touched.mapI])

  useEffect(() => {
    //NEED A CHECK SO WHEN INITIALLY LOADED THIS DOES NOT RUN
    mapImage &&
      mapImage.forEach((file) => {
        fileChangedHandler(file)
      })
  }, [mapImage])

  const onDelete = (file) => {
    setMapImage([])
    formik.setFieldValue('mapI', '')
  }

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    cursor: 'pointer',
    color: '#666666',
    fontSize: '2rem',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  }

  const activeStyle = {
    borderColor: '#2196f3',
  }

  const acceptStyle = {
    borderColor: '#00e676',
  }

  const rejectStyle = {
    borderColor: '#ff1744',
  }
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  }

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    marginBottom: 8,
    marginRight: 8,
    width: 450,
    height: 250,
    padding: 4,
    boxSizing: 'border-box',
  }

  const thumbInner = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    overflow: 'hidden',
  }

  const buttonContainer = {
    justifyContent: 'space-between',
    display: 'flex',
    transform: 'translateY(30px)',
  }

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const thumbs =
    mapImage &&
    mapImage.map((file) => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <div style={buttonContainer}>
            <Button size="small" color="secondary" variant="contained" onClick={() => onDelete(file)}>
              Delete
            </Button>
          </div>
          <img src={file.preview} style={img} alt="" />
        </div>
      </div>
    ))
  return (
    <React.Fragment>
      <Box my={2}>
        <Typography variant="h5" gutterBottom>
          Public Transportation & Map Details
        </Typography>
      </Box>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={4}>
          <InputField name={dayProgram.name} label={dayProgram.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField name={nearestHospital.name} label={nearestHospital.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField name={nearestPark.name} label={nearestPark.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField name={nearestStore.name} label={nearestStore.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField name={bike0.name} label={bike0.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField name={bike1.name} label={bike1.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField name={bus0.name} label={bus0.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField name={bus1.name} label={bus1.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField name={train0.name} label={train0.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputField name={train1.name} label={train1.label} fullWidth />
        </Grid>
        <Box m={4}>
          <section className="">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop One image here, or click to select image</p>
              <em>(Only 1 Image will be accepted)</em>
            </div>
            <aside style={thumbsContainer}>{thumbs}</aside>
          </section>
        </Box>
      </Grid>
    </React.Fragment>
  )
}

export default PublicTransportation
