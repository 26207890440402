import styled from 'styled-components'

const StoryStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction:row;
  justify-content:center;
  padding:4.5rem;
  box-shadow: 0px 6px 8px rgba(25, 50, 47, 0.08),0px 3px 4px rgba(18, 71, 52, 0.02), 0px 1px 16px rgba(18, 71, 52, 0.03);
  @media (max-width: 800px) {
        flex-direction:column;
        };
      }

 .info{
  margin-right: 3rem;
  flex-basis: 517px;
   @media (max-width: 800px) {
        margin-right: 0;
        flex-basis: 100%;
        margin-bottom:5rem;
        };
      }
  .title{
    color: var(--black, black);
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeTitle, fontSizeTitle);
    margin-bottom:2.7rem;
    font-weight:600;
  }
  .description{
    color: var(--black, black);
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeDescription, fontSizeDescription);
    font-weight: 400;
    line-height: 1.4;
  }
 .video{
  margin-left: 3rem;
  flex-basis: 517px;
  @media (max-width: 800px) {
        margin-left: 0;
        flex-basis: 100%;
        };
 }
`

const Story = () => {
  return (
    <StoryStyles>
      <div className="info">
        <h2 className="title">The Family Home Agency (FHA) at a Glance</h2>
        <p className="description">
          See what it means to make a difference as a Mentor in the California MENTOR Family Home Agency program.
          Mentors open their hearts and homes to individuals with developmental disabilities and are teachers,
          advocates, and friends to the people they help.
        </p>
      </div>
      <div className="video">
        <iframe
          width="100%"
          height="235"
          src="https://www.youtube.com/embed/8ee5z7mR1sc"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen;"></iframe>
      </div>
    </StoryStyles>
  )
}

export default Story
