import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = () => (
    <div style={{margin:'1rem auto', width:'fit-content'}}> <CircularProgress /></div>
)

export default Loader;


// import React from 'react'
// import CircularProgress from '@material-ui/core/CircularProgress'
// import styled from 'styled-components'

// // {`root_loader ${full ? 'full':''}`}>

// const Loader = ({ full }) => (
//   // <div style={{margin:'1rem auto', width:'fit-content'}}> <CircularProgress /></div>
//   <LandingStyles full={full}>
//     <CircularProgress />
//   </LandingStyles>
// )

// const LandingStyles = styled.div`
//   text-align: center;
//   margin: 80px;
//   /* width: 'fit-content'; */
//   ${({ full }) =>
//     full &&
//     `
//     heigh:300px;
//     margin: 50vh auto;
//   `}
// `
// export default Loader
