import React, { useCallback,useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { showErrorToast } from '../../libs/Utils'

export default function PhotoWidgetDropzone({ setFiles }) {
//   const dropzoneStyles = {
//     border: 'dashed 3px #eee',
//     borderRadius: '5%',
//     paddingTop: '30px',
//     textAlign: 'center',
//   }

//   const dropzoneActive = {
//     border: 'dashed 3px green',
//   }
  
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    // borderColor: formik.errors.mainImage === 'Required' && formik.touched.mainImage === 'true' ? 'red' : '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    cursor: 'pointer',
    color: '#666666',
    fontSize: '2rem',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  }

  const activeStyle = {
    borderColor: '#2196f3',
  }

  const acceptStyle = {
    borderColor: '#00e676',
  }

  const rejectStyle = {
    borderColor: '#ff1744',
  }


  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 1) {
        showErrorToast('can Only Upload One Image')
      }
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    },
    [setFiles]
  )
  const { getRootProps, getInputProps, isDragActive,isDragAccept,isDragReject } = useDropzone({
    onDrop,
    accept: 'image/png, image/jpg, image/jpeg',
    maxFiles: 1,
  })
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <p>Drag 'n' drop Main image here, or click to select image</p>
      <em>(Only 1 Image will be accepted)</em>
    </div>
  )
}
