import {addHomeFormModel} from './addHomeFormModel';
const {
  formField: {
    mainImage,
    additionalImages,
    region,
    program,
    mentorNames,
    mentorType,
    bedsAvailable,
    cats,
    dogs,
    fullBathrooms,
    tier,
    totalBeds,
    multiplePersons,
    seperateBaths,
    wheelChair,
    stairs,
    homeDescription,
    videoUrl,
    address,
    address2,
    city,
    state,
    zipcode,
    status,
    mhdesc,
    mentorHighLightImage,
    mhvid,
    dayProgram,
    mapI,
    nearestHospital,
    nearestPark,
    nearestStore,
    bike0,
    bike1,
    bus0,
    bus1,
    train0,
    train1,
    availabilityDate,

  }
} = addHomeFormModel;

export const formInitialValues = {
  [mainImage.name]: '',
  [additionalImages.name]: [],
  [region.name]: '',
  [program.name]: '',
  [mentorNames.name]: '',
  [mentorType.name]: '',
  [bedsAvailable.name]: '',
  [cats.name]: '',
  [dogs.name]: '',
  [fullBathrooms.name]: '',
  [tier.name]: '',
  [totalBeds.name]: '',
  [multiplePersons.name]: '',
  [seperateBaths.name]: '',
  [wheelChair.name]: '',
  [stairs.name]: '',
  [homeDescription.name]: '',
  [videoUrl.name]: '',
  [address.name]: '',
  [address2.name]: '',
  [city.name]: '',
  [state.name]: '',
  [zipcode.name]: '',
  [status.name]: '',
  [mhdesc.name]: '',
  [mentorHighLightImage.name]: '',
  [mhvid.name]: '',
  [dayProgram.name]: '',
  [mapI.name]: '',
  [nearestHospital.name]: '',
  [nearestPark.name]: '',
  [nearestStore.name]: '',
  [bike0.name]: '',
  [bike1.name]: '',
  [bus0.name]: '',
  [bus1.name]: '',
  [train0.name]: '',
  [train1.name]: '',
  [availabilityDate.name]: '',
};
