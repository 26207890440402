import React, { useState, useContext, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { useDropzone } from 'react-dropzone'
import AdminLayout from '../../Hoc/AdminLayout'
import { textErrorHelper, selectErrorHelper, selectIsError } from '../../libs/tools'
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Paper,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { BASE_URL } from '../../libs/tools'
import { showErrorToast, showSuccessToast } from '../../libs/Utils'

import { MentorContext } from '../../contexts/mentorContext'
import { RegionContext } from '../../contexts/regionContext'
import { ProgramContext } from '../../contexts/programContext'
import { AuthContext } from '../../contexts/authContext'

import Resizer from 'react-image-file-resizer'

const defaultValues = {
  name: '',
  startDate: '',
  endDate: '',
  location: '',
  description: '',
  displayStatus: '',
  mentorImage: '',
}

const AddEditMentorEvents = (props) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [currentImage, setCurrentImage] = useState(false)
  const [formType, setFormType] = useState('add')
  const [values, setValues] = useState(defaultValues)

  const { mentorEvent } = useContext(MentorContext)
  const { region } = useContext(RegionContext)
  const { program } = useContext(ProgramContext)
  const { getRefreshSession, sessionInfo } = useContext(AuthContext)

  // const localStorageToken = window.localStorage.getItem('accessToken')
  const history = useHistory()

  //Restrict Date Time Day///
  const today = new Date()
  today.setDate(today.getDate())
  const convertedFormRestrictDate = moment(today).format('YYYY-MM-DDTHH:mm')

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values,
    validationSchema: Yup.object({
      name: Yup.string().min(2).max(100).required('Required'),
      startDate: Yup.string().min(2).required('Required'),
      endDate: Yup.string().min(2).required('Required'),
      location: Yup.string().min(2).max(100).required('Required'),
      description: Yup.string().min(2).max(255),
      displayStatus: Yup.string().required('Required'),
      mentorImage: Yup.string(),
    }),
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm)
    },
  })

  const submitForm = (values, resetForm) => {
    setLoading(true)
    if (formType === 'add') {
      ////SET MENTOR EVENT TO BLANK
      // setMentorEvent()
      const AddMentorEventData = async () => {
        const isMentorEventImage = values.mentorImage.length > 1 ? values.mentorImage.slice(23) : '$$$$$'
        try {
          const dataToPost = {
            mentorImage: isMentorEventImage,
            // mentorImage: 'Base 64 Image',
            pk: `ORG#CSS#ST#CA#REG#${region}#PROG#${program}`,
            mentorEvent: {
              name: values.name,
              description: values.description,
              location: values.location,
              displayStatus: values.displayStatus,
              startDate: values.startDate,
              endDate: values.endDate,
            },
          }
          // const mentorEventLocalState = {
          //   name: values.name,
          //   description: values.description,
          //   location: values.location,
          //   displayStatus: values.displayStatus,
          //   startDate: values.startDate,
          //   endDate: values.endDate,
          // }
          const response = await axios.post(`${BASE_URL}/admin/createMentorEvent`, dataToPost, {
            // headers: { Authorization: `Bearer ${localStorageToken}` },
            headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
          })
          // console.log('$$$$$$$$$$$$$$$$$$$$ EVENT  DATA SEND TO API SUCCESS')

          // setMentorEvents(prev => ([...prev, mentorEventLocalState]));
          // addMentorEvent(mentorEventLocalState)
          if (response.status === 200) {
            ///Reset Image to be blank
            // setFiles([])
            ///Reset The Form
            // resetForm()
            showSuccessToast('Successfully Added')
            setLoading(false)
            history.push('/')
          }
        } catch (err) {
          if (err && err.response.status === 403) {
            await getRefreshSession()
            showErrorToast('Refreshing your token, Please Submit Again')
            setLoading(false)
          } else {
            showErrorToast('Update Failed')
            setLoading(false)
          }
          setLoading(false)
        }
      }

      AddMentorEventData()
    } else {
      const EditMentorEventData = async () => {
        try {
          if (currentImage !== values.mentorImage) {
            const isMentorEventImage = values.mentorImage.length > 1 ? values.mentorImage.slice(23) : '$$$$$'
            const isDollars =
              values.mentorImage === '$$$$$'
                ? '$$$$$'
                : `program/ORG#CSS#ST#CA#REG#${region}#PROG#${program}${values.name}.jpeg`
            const dataToPost = {
              pk: `ORG#CSS#ST#CA#REG#${region}#PROG#${program}`,
              currentMentorEvent: {
                name: mentorEvent.name,
                description: mentorEvent.description,
                location: mentorEvent.location,
                image: isDollars,
                displayStatus: mentorEvent.displayStatus,
                startDate: mentorEvent.startDate,
                endDate: mentorEvent.endDate,
                registeredCaseManagers: mentorEvent.registeredCaseManagers,
              },
              updatedMentorEvent: {
                name: values.name,
                description: values.description,
                location: values.location,
                image: isDollars,
                displayStatus: values.displayStatus,
                startDate: values.startDate,
                endDate: values.endDate,
                registeredCaseManagers: mentorEvent.registeredCaseManagers,
              },
              newImage: isMentorEventImage,
            }
            const response = await axios.post(`${BASE_URL}/admin/updateMentorEvent`, dataToPost, {
              headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
            })
            if (response.status === 200) {
              setLoading(false)
              showSuccessToast('Successfully Updated')
              history.push('/')
            }
          } else {
            const dataToPost = {
              pk: `ORG#CSS#ST#CA#REG#${region}#PROG#${program}`,
              currentMentorEvent: {
                name: mentorEvent.name,
                description: mentorEvent.description,
                location: mentorEvent.location,
                image: mentorEvent.imageName,
                displayStatus: mentorEvent.displayStatus,
                startDate: mentorEvent.startDate,
                endDate: mentorEvent.endDate,
                registeredCaseManagers: mentorEvent.registeredCaseManagers,
              },
              updatedMentorEvent: {
                name: values.name,
                description: values.description,
                location: values.location,
                image: mentorEvent.imageName,
                displayStatus: values.displayStatus,
                startDate: values.startDate,
                endDate: values.endDate,
                registeredCaseManagers: mentorEvent.registeredCaseManagers,
              },
              //If same image
              // newImage: values.mentorImage,
            }

            const response = await axios.post(`${BASE_URL}/admin/updateMentorEvent`, dataToPost, {
              headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
            })
            if (response.status === 200) {
              showSuccessToast('Successfully Updated')
              setLoading(false)
              history.push('/')
            }
            // const mentorEventLocalState = {
            //   name: values.name,
            //   description: values.description,
            //   location: values.location,
            //   image: 'program/ORG#CSS#ST#CA#REG#Golden Gate Regional Center#PROG#Golden Gate FHA#Fall Bash 2000.jpeg',
            //   displayStatus: values.displayStatus,
            //   date: values.date,
            // }
            // editMentorEvent(mentorEventLocalState, mentorEventLocalState.name)
          }
        } catch (err) {
          if (err && err.response.status === 403) {
            await getRefreshSession()
            showErrorToast('Refreshing your token, Please Submit Again')
            setLoading(false)
          } else {
            showErrorToast('Update Failed')
            setLoading(false)
          }
          setLoading(false)
        }
      }
      EditMentorEventData()
    }
  }

  useEffect(() => {
    if (!mentorEvent || mentorEvent.length === 0) {
      document.title = 'Add Mentor Event | California Mentor'
      setValues(defaultValues)
      setFormType('add')
      setFiles([])
    } else {
      document.title = 'Edit Mentor Event | California Mentor'
      setFormType('edit')
      if (mentorEvent) {
        setCurrentImage(mentorEvent.image)
        const convertedStartDate = moment(mentorEvent.startDate).format('YYYY-MM-DDTHH:mm')
        const convertedEndDate = moment(mentorEvent.endDate).format('YYYY-MM-DDTHH:mm')
        const mentorEventFormData = {
          startDate: convertedStartDate,
          endDate: convertedEndDate,
          name: mentorEvent.name,
          description: mentorEvent.description,
          location: mentorEvent.location,
          displayStatus: mentorEvent.displayStatus,
          mentorImage: mentorEvent.image,
        }
        setValues(mentorEventFormData)
        if (mentorEvent.image === '$$$$$') {
          setFiles([])
        } else {
          const image = {
            name: mentorEvent.imageName,
            preview: mentorEvent.image,
          }
          setFiles([image])
        }
      }
    }
  }, [mentorEvent, formType])

  ///////////////////////////////Image Upload with Dropzone

  const [files, setFiles] = useState([])
  const { isDragActive, isDragAccept, isDragReject, getRootProps, getInputProps } = useDropzone({
    accept: 'image/png, image/jpg, image/jpeg',
    maxFiles: 1,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 1) {
        showErrorToast('can Only Upload One Image')
      }
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    },
  })

  const fileChangedHandler = (file) => {
    if (file) {
      try {
        Resizer.imageFileResizer(
          file,
          500,
          500,
          'JPEG',
          100,
          0,
          (uri) => {
            formik.setFieldValue('mentorImage', uri)
          },
          'base64',
          200,
          200
        )
      } catch (err) {
        console.log(err)
      }
    }
  }

  useEffect(() => {
    //NEED A CHECK SO WHEN INITIALLY LOADED THIS DOES NOT RUN
    files.forEach((file) => {
      fileChangedHandler(file)
    })
    // Make sure to revoke the data uris to avoid memory leaks
    return () => {
      files.forEach((file) => {
        URL.revokeObjectURL(file.preview)
      })
    }
  }, [files])

  const onDelete = (file) => {
    setFiles([])
    formik.setFieldValue('mentorImage', '')
  }

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    cursor: 'pointer',
    color: '#666666',
    fontSize: '2rem',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  }

  const activeStyle = {
    borderColor: '#2196f3',
  }

  const acceptStyle = {
    borderColor: '#00e676',
  }

  const rejectStyle = {
    borderColor: '#ff1744',
  }
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  }

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    // border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 450,
    height: 250,
    padding: 4,
    boxSizing: 'border-box',
  }

  const thumbInner = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    overflow: 'hidden',
  }
  const buttonContainer = {
    // backgroundColor: 'rgb(66, 66, 66)',
    justifyContent: 'space-between',
    display: 'flex',
    transform: 'translateY(30px)',
  }

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
  }
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const thumbs = files.map((file, i) => (
    <div style={thumb} key={i}>
      <div style={thumbInner}>
        <div style={buttonContainer}>
          <Button size="small" color="secondary" variant="contained" onClick={() => onDelete(file)}>
            Delete
          </Button>
        </div>

        <img src={file.preview} style={img} alt="" />
      </div>
    </div>
  ))
  return (
    <AdminLayout>
      <Paper style={{ width: '60%', padding: 16, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
        <Box m={4}>
          <Typography style={{ color: 'rgb(66, 66, 66)', fontWeight: 500, fontFamily: 'Roboto' }} variant="h3">
            {formType === 'add' ? 'Add Mentor Event' : 'Edit Mentor Event'}
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box m={4}>
            <section className="">
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop One image here, or click to select image</p>
                <em>(Only One image will be accepted)</em>
              </div>

              <aside style={thumbsContainer}>{thumbs}</aside>
            </section>
          </Box>
          <Box m={2}>
            <FormControl style={{ width: '60%', padding: 10 }}>
              {formType === 'edit' ? (
                <TextField
                  fullWidth
                  label="Event Name"
                  id="name"
                  disabled
                  firstname="name"
                  variant="outlined"
                  placeholder="Add Event Name"
                  {...formik.getFieldProps('name')}
                  {...textErrorHelper(formik, 'name')}
                />
              ) : (
                <TextField
                  fullWidth
                  label="Event Name"
                  id="name"
                  firstname="name"
                  variant="outlined"
                  placeholder="Add Event Name"
                  {...formik.getFieldProps('name')}
                  {...textErrorHelper(formik, 'name')}
                />
              )}
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl style={{ width: '60%', padding: 10 }}>
              <TextField
                id="location"
                name="location"
                label="Location"
                variant="outlined"
                placeholder="Add Location"
                {...formik.getFieldProps('location')}
                {...textErrorHelper(formik, 'location')}
              />
            </FormControl>
          </Box>
          <Box m={2}>
            <FormControl style={{ width: '60%', padding: 10 }}>
              <TextField
                id="description"
                name="description"
                label="Description"
                multiline
                rows={4}
                variant="outlined"
                placeholder="Add Description"
                {...formik.getFieldProps('description')}
                {...textErrorHelper(formik, 'description')}
              />
            </FormControl>
          </Box>
          <Box m={2}>
            <FormControl style={{ width: '60%', padding: 10 }}>
              <TextField
                id="startDate"
                name="startDate"
                type="datetime-local"
                inputProps={{ min: convertedFormRestrictDate }}
                variant="outlined"
                placeholder="Add a Date"
                {...formik.getFieldProps('startDate')}
                {...textErrorHelper(formik, 'startDate')}
              />
            </FormControl>
          </Box>
          <Box m={2}>
            <FormControl style={{ width: '60%', padding: 10 }}>
              <TextField
                id="endDate"
                name="endDate"
                type="datetime-local"
                inputProps={{ min: convertedFormRestrictDate }}
                variant="outlined"
                placeholder="Add a Date"
                {...formik.getFieldProps('endDate')}
                {...textErrorHelper(formik, 'endDate')}
              />
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl error={selectIsError(formik, 'displayStatus')} style={{ width: '60%', padding: 10 }}>
              <Select
                id="displayStatus"
                name="displayStatus"
                variant="outlined"
                displayEmpty
                {...formik.getFieldProps('displayStatus')}>
                <MenuItem value="" disabled>
                  Select Status
                </MenuItem>
                <MenuItem value="Y">Yes</MenuItem>
                <MenuItem value="N">No</MenuItem>
              </Select>
              {selectErrorHelper(formik, 'displayStatus')}
            </FormControl>
          </Box>

          <Box m={1}>
            <Button
              className={classes.buttonEdit}
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}>
              Save
              {loading && (
                <>
                  <CircularProgress style={{ color: 'white', marginLeft: '10px' }} size={24} />
                </>
              )}
            </Button>
            <Button
              onClick={() => history.goBack()}
              className={classes.buttonDelete}
              size="large"
              variant="contained"
              disabled={loading}>
              Cancel
            </Button>
          </Box>
        </form>
      </Paper>
    </AdminLayout>
  )
}

const useStyles = makeStyles(() => ({
  buttonEdit: {
    color: 'white',
    margin: '2rem 1rem 2rem 1rem',
    padding: '.5rem 3rem',
    fontSize: '1.4rem',
    // backgroundColor: 'rgb(8, 102, 173)',
    // '&:hover': {
    //   backgroundColor: 'rgb(16, 81, 130)',
    // },
  },
  buttonDelete: {
    color: 'white',
    margin: '2rem 1rem 2rem 1rem',
    padding: '.5rem 2rem',
    fontSize: '1.4rem',
    backgroundColor: 'rgb(199, 36, 42)',
    '&:hover': {
      backgroundColor: 'rgb(236,28,36)',
    },
  },
}))

export default AddEditMentorEvents
