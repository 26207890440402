import React from 'react'
import styled from 'styled-components'
import AdminNav from '../components/admin/nav/AdminNav'
import Header from '../components/layout/Header'

const AdminStyles = styled.div`
  position: relative;
  margin-top: 0px;
  display: flex;
  .title {
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeDisplay, fontSizeDisplay);
    font-size: 3.5rem;
    color: var(--black, black);
  }
  .admin_left_nav {
    min-height: 100vh;
    background: var(--black, black);
    background: var(--black, black);
    border-right: 2px solid #01285e;
    min-width: fit-content;
  }
  .admin_right {
    flex-grow: 1;
    padding: 20px;
  }
`

const AdminLayout = (props) => {
  return (
    <>
      <Header />
      <AdminStyles>
        <div className="admin_left_nav">
          <AdminNav />
        </div>
        <div className="admin_right">
          <h2 className="title">{props.title}</h2>
          {props.children}
        </div>
      </AdminStyles>
    </>
  )
}

export default AdminLayout
