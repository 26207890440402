import { useState, useEffect } from 'react'

import * as yup from 'yup'

export const useValidEmail = (initialValue: string) => {
  const [email, setEmail] = useState(initialValue)
  const [emailIsValid, setEmailIsValid] = useState(true)

  useEffect(() => {
    const emailSchema = yup.object().shape({
      email: yup.string().email().required("'This input is required'"),
    })

    if (email.length === 0) {
      setEmailIsValid(true)
      return
    }

    const isValid = emailSchema.isValidSync({ email })

    setEmailIsValid(isValid)
  }, [email])

  return { email, setEmail, emailIsValid }
}

export const useValidPassword = (initialValue: string) => {
  const [password, setPassword] = useState(initialValue)
  const [passwordIsValid, setPasswordIsValid] = useState(true)

  useEffect(() => {
    const passwordSchema = yup.object().shape({
      password: yup
        .string()
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          'Password must contain at least 8 characters, one uppercase, one number and one special case character'
        )
        // .matches(
        //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        //   'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
        // )
        .max(64)
        .required(),
    })

    if (password.length === 0) {
      setPasswordIsValid(true)
      return
    }

    const isValid = passwordSchema.isValidSync({ password })

    setPasswordIsValid(isValid)
  }, [password])

  return { password, setPassword, passwordIsValid }
}

export const useValidFirstname = (initialValue: string) => {
  const [firstname, setFirstname] = useState(initialValue)
  const [firstnameIsValid, setFirstnameIsValid] = useState(true)

  useEffect(() => {
    const firstnameSchema = yup.object().shape({
      firstname: yup
        .string()
        .min(2)
        .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed for this field ')
        .required(),
    })

    if (firstname.length === 0) {
      setFirstnameIsValid(true)
      return
    }

    const isValid = firstnameSchema.isValidSync({ firstname })

    setFirstnameIsValid(isValid)
  }, [firstname])

  return { firstname, setFirstname, firstnameIsValid }
}

export const useValidLastname = (initialValue: string) => {
  const [lastname, setLastname] = useState(initialValue)
  const [lastnameIsValid, setLastnameIsValid] = useState(true)

  useEffect(() => {
    const lastnameSchema = yup.object().shape({
      lastname: yup
        .string()
        .min(2)
        .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed for this field ')
        .required(),
    })
    // /^[aA-zZ\s]+$/
    if (lastname.length === 0) {
      setLastnameIsValid(true)
      return
    }

    const isValid = lastnameSchema.isValidSync({ lastname })

    setLastnameIsValid(isValid)
  }, [lastname])

  return { lastname, setLastname, lastnameIsValid }
}
export const useValidOrganization = (initialValue: string) => {
  const [organization, setOrganization] = useState(initialValue)
  const [organizationIsValid, setOrganizationIsValid] = useState(true)

  useEffect(() => {
    const organizationSchema = yup.object().shape({
      organization: yup
        .string()
        .min(2)
        .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field ')
        .required(),
    })
    // /^[A-Za-z0-9 ]+$/
    if (organization.length === 0) {
      setOrganizationIsValid(true)
      return
    }

    const isValid = organizationSchema.isValidSync({ organization })

    setOrganizationIsValid(isValid)
  }, [organization])

  return { organization, setOrganization, organizationIsValid }
}

export const useValidRegion = (initialValue: any) => {
  const [region, setRegion] = useState(initialValue)
  const [regionIsValid, setRegionIsValid] = useState(true)

  useEffect(() => {
    const regionSchema = yup.object().shape({
      region: yup
        .string()
        // .min(2)
        // .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed for this field ')
        .required(),
    })
    // /^[A-Za-z0-9 ]+$/
    if (region.length === 0) {
      setRegionIsValid(true)
      return
    }

    const isValid = regionSchema.isValidSync({ region })

    setRegionIsValid(isValid)
  }, [region])

  return { region, setRegion, regionIsValid }
}

export const useValidPhone = (initialValue: any) => {
  const [phone, setPhone] = useState(initialValue)
  const [phoneIsValid, setPhoneIsValid] = useState(true)

  useEffect(() => {
    const phoneSchema = yup.object().shape({
      phone: yup.string().min(17, 'The minimum is 10').required(),
    })

    if (phone.length === 0) {
      setPhoneIsValid(true)
      return
    }

    const isValid = phoneSchema.isValidSync({ phone })

    setPhoneIsValid(isValid)
  }, [phone])

  return { phone, setPhone, phoneIsValid }
}

export const useValidPhoneExt = (initialValue: string) => {
  const [phoneExt, setPhoneExt] = useState(initialValue)
  const [phoneExtIsValid, setPhoneExtIsValid] = useState(true)

  useEffect(() => {
    const phoneExtSchema = yup.object().shape({
      phoneExt: yup.number().positive().integer().max(9999999, 'Must be more than 1 characters'),
    })

    if (phoneExt.length === 0) {
      setPhoneExtIsValid(true)
      return
    }

    const isValid = phoneExtSchema.isValidSync({ phoneExt })

    setPhoneExtIsValid(isValid)
  }, [phoneExt])

  return { phoneExt, setPhoneExt, phoneExtIsValid }
}

export const useValidUsername = (initialValue: string) => {
  const [username, setUsername] = useState(initialValue)
  const [usernameIsValid, setUsernameIsValid] = useState(true)

  useEffect(() => {
    const usernameSchema = yup.object().shape({
      username: yup.string().email().min(2).required(),
    })

    if (username.length === 0) {
      setUsernameIsValid(true)
      return
    }

    const isValid = usernameSchema.isValidSync({ username })

    setUsernameIsValid(isValid)
  }, [username])

  return { username, setUsername, usernameIsValid }
}

export const useValidCode = (initialValue: string) => {
  const [code, setCode] = useState(initialValue)
  const [codeIsValid, setCodeIsValid] = useState(true)

  useEffect(() => {
    const codeSchema = yup.object().shape({
      code: yup.string().min(6).required(),
    })

    if (code.length === 0) {
      setCodeIsValid(true)
      return
    }

    const isValid = codeSchema.isValidSync({ code })

    setCodeIsValid(isValid)
  }, [code])

  return { code, setCode, codeIsValid }
}
