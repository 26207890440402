import React, { Fragment, useState, useContext, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Select, MenuItem, CircularProgress, Grid, Button, Typography, Paper } from '@material-ui/core/'

// import MaterialUiPhoneNumber from 'material-ui-phone-number'

import {
  useValidEmail,
  useValidPassword,
  useValidFirstname,
  useValidLastname,
  useValidOrganization,
  useValidRegion,
  useValidPhone,
  useValidPhoneExt,
} from '../../hooks/useAuthHooks'
import { Email, Password, Firstname, Lastname, Organization, Phone, PhoneExt } from '../../components/authComponents'

import { AuthContext } from '../../contexts/authContext'
import { RegionContext } from '../../contexts/regionContext'

const useStyles = makeStyles({
  root: {
    height: '100vh',
    overflow: 'scroll',
    // margin: '5rem',
  },
  title: {
    color: 'rgb(66, 66, 66)',
    fontWeight: 500,
    fontFamily: 'Roboto',
  },
})

const Register: React.FunctionComponent<{}> = () => {
  const classes = useStyles()
  const { email, setEmail, emailIsValid } = useValidEmail('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const { lastname, setLastname, lastnameIsValid } = useValidLastname('')
  const { firstname, setFirstname, firstnameIsValid } = useValidFirstname('')
  const { organization, setOrganization, organizationIsValid } = useValidOrganization('')
  const { region, setRegion, regionIsValid } = useValidRegion('')
  const { phone, setPhone, phoneIsValid } = useValidPhone('')
  const { phoneExt, setPhoneExt, phoneExtIsValid } = useValidPhoneExt('')
  const [error, setError] = useState('')
  const [created, setCreated] = useState(false)
  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('')

  const isValid =
    !emailIsValid ||
    email.length === 0 ||
    // !usernameIsValid ||
    // username.length === 0 ||
    passwordConfirm !== password ||
    !firstnameIsValid ||
    firstname.length === 0 ||
    !lastnameIsValid ||
    lastname.length === 0 ||
    !organizationIsValid ||
    organization.length === 0 ||
    phone.length <= 16 ||
    !phone ||
    !passwordIsValid ||
    !region ||
    password.length < 8 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0

  const history = useHistory()

  const authContext = useContext(AuthContext)
  const { regions } = useContext(RegionContext)

  const signInClicked = async (e: any) => {
    e.preventDefault()
    try {
      setLoading(true)
      await authContext.signUpWithEmail(email, lastname, firstname, organization, region, phone, phoneExt, password)
      setLoading(false)
      setCreated(true)
    } catch (err: any) {
      setError(err)
      setLoading(false)
    }
  }
  useEffect(() => {
    document.title = 'Registration | California Mentor'
    password !== passwordConfirm ? setError('Passwords do not match. Please re-enter.') : setError('')
  }, [password, passwordConfirm])

  const ITEM_HEIGHT = 40
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    // anchorOrigin: {
    //   vertical: 'bottom',
    // },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
        // transform: 'translateY(100px)',
      },
    },
  }

  const regionsSort = regions.sort((a, b) => (a > b ? 1 : -1))

  const handleChange = (event: { target: { value: any } }) => {
    setRegion(event.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  const register = (
    <Fragment>
      <Box m={3}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Typography className={classes.title} variant="h3">
            Register
          </Typography>
        </Grid>
      </Box>
      <form style={{ width: '75%' }} onSubmit={signInClicked}>
        <Box width="100%" my={2}>
          <Email emailIsValid={emailIsValid} setEmail={setEmail} />
        </Box>
        <Box width="100%" my={2}>
          <Firstname firstnameIsValid={firstnameIsValid} setFirstname={setFirstname} />
        </Box>
        <Box width="100%" my={2}>
          <Lastname lastnameIsValid={lastnameIsValid} setLastname={setLastname} />
        </Box>
        <Box width="100%" my={2}>
          <Organization organizationIsValid={organizationIsValid} setOrganization={setOrganization} />
        </Box>
        <Box width="100%" my={2}>
          <Select
            id="region"
            name="region"
            variant="outlined"
            MenuProps={MenuProps}
            displayEmpty
            fullWidth
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={region}
            onChange={handleChange}>
            <MenuItem disabled value="">
              Select Region
            </MenuItem>
            {regionsSort.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={{
                  fontSize: '1.8rem',
                }}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box width="100%" my={2} display="flex">
          <Phone phoneIsValid={phoneIsValid} setPhone={(phone) => setPhone(phone)} phone={phone} />
          <PhoneExt phoneExtIsValid={phoneExtIsValid} setPhoneExt={setPhoneExt} />
          {/* <MaterialUiPhoneNumber
          countryCodeEditable={false}
          defaultCountry="us"
          variant="outlined"
          value={phone}
          onChange={(phone) => setPhone(phone)}
        /> */}
        </Box>
        <Box width="100%" my={2}>
          <Password toolTip={true} label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
        </Box>
        <Box width="100%" my={2}>
          <Password
            toolTip={false}
            label="Confirm Password"
            passwordIsValid={passwordConfirmIsValid}
            setPassword={setPasswordConfirm}
          />
        </Box>
        <Box mt={2}>
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        </Box>

        {/* Buttons */}
        <Box mt={2}>
          <Grid container direction="row" justifyContent="center">
            <Box m={1}>
              <Button onClick={() => history.goBack()} color="secondary" variant="contained">
                Cancel
              </Button>
            </Box>
            <Box m={1}>
              <Button type="submit" color="primary" disabled={isValid} variant="contained" onClick={signInClicked}>
                {isLoading && (
                  <>
                    <h4 style={{ marginRight: '10px', fontFamily: 'Roboto', fontSize: '15px' }}>Submit</h4>
                    <CircularProgress style={{ color: 'white' }} size={24} />
                  </>
                )}
                {!isLoading && 'Submit'}
              </Button>
            </Box>
          </Grid>
        </Box>
      </form>
    </Fragment>
  )

  const accountCreated = (
    <Fragment>
      <Box m={4}>
        <Typography variant="h5">{`Registration Successful `}</Typography>
      </Box>

      <Box m={4}>
        <Button onClick={() => history.push('/welcome')} color="primary" variant="contained">
          Back To Home
        </Button>
      </Box>
    </Fragment>
  )

  return (
    <Grid className={classes.root} container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} container direction="row" justifyContent="center" alignItems="center" item>
        <Paper style={{ width: '100%', padding: 16, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            {/* Title */}

            {!created ? register : accountCreated}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Register
