import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import NotFoundImg from '../resources/images/NotFound.png'
import { Button } from '@material-ui/core'

const NotFoundStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  max-width: 1600px;
  margin: 0 auto;
  justify-content: center;
  & img {
    border-radius: 0.5rem 0.5rem 0 0;
    width: 100%;
    object-fit: contain;
    max-height: max(30rem, 90vh);
    aspect-ratio: 16/9;
  }
`

const NotFound = () => {
  return (
    <NotFoundStyles>
      <img src={NotFoundImg} alt="" />
      <Link to="/welcome">
        <Button
          style={{
            backgroundColor: 'rgb(8, 102, 173)',
            color: '#FFFFFF',
            padding: '.5rem 5.5rem',
            fontSize: '1.6rem',
            marginBottom: '5rem',
          }}
          size="large"
          variant="contained">
          Back Home
        </Button>
      </Link>
    </NotFoundStyles>
  )
}

export default NotFound
