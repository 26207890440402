import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import FacebookIcon from '@material-ui/icons/Facebook'
import YouTubeIcon from '@material-ui/icons/YouTube'
import TwitterIcon from '@material-ui/icons/Twitter'

import logoBlue from '../../resources/images/FHA_logo_green.png'

const GlobalHeader = () => {
  const classes = useStyles()
  return (
    <GlobalHeaderStyles>
      <div className="logo">
        <Link to={`/welcome`}>
          <img src={logoBlue} alt="California Mentor Logo Green" />
        </Link>
      </div>
      <div className="header">
        Welcome to California MENTOR's Family Home Agency <span>Home Life</span>
      </div>

      <div className="social-icons">
        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/The_MENTOR_Ntwk">
          <div className="icon-container">
            <TwitterIcon className={classes.icon} style={{ color: 'rgb(0,184,243)' }} />
          </div>
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/likementor">
          <div className="icon-container">
            <FacebookIcon className={classes.icon} style={{ color: 'rgb(60,89,151)' }} />
          </div>
        </a>

        <a target="_blank" rel="noopener noreferrer" href="http://www.youtube.com/user/TheMENTORNetwork">
          <div className="icon-container">
            <YouTubeIcon className={classes.icon} style={{ color: 'rgb(165,15,19)' }} />
          </div>
        </a>
      </div>
    </GlobalHeaderStyles>
  )
}
const GlobalHeaderStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
  padding: 2rem 10rem;

  @media (max-width: 800px) {
    text-align: center;
  }
  .logo {
    width: 180px;
    height: auto;
    z-index: 10;

    & img {
      object-fit: cover;
      display: block;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  }
  .header {
    font-family: var(--fontPrimary, fontPrimary);
    font-size: 3rem;
    font-weight: 700;
    color: var(--green, green);
  }
  .header span {
    font-family: var(--fontItalic, fontItalic);
    font-size: 4.5rem;
    font-weight: 700;
    color: var(--primary, primary);
  }
  .social-icons {
    display: flex;
  }
`
const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '3rem',
    margin: ' 0 1rem',
    transition: '0.3s',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
}))
export default GlobalHeader
