import React, { useEffect, useContext } from 'react'
import ReactPlayer from 'react-player'
import Header from '../components/layout/Header'
import styled from 'styled-components'
import { HomesContext } from '../contexts/homesContext'
import { AuthContext } from '../contexts/authContext'

const VirtualTour = () => {
  const { homeDetails } = useContext(HomesContext)
  const { authStatus } = useContext(AuthContext)
  const isAdmin = authStatus && authStatus === 3
  const { homeInfo } = homeDetails.home
  const { vtInfo } = homeDetails.home
  const homeLocationData = homeDetails.SK.S.split('$')
  const cityState = `${homeLocationData[2]}, ${homeLocationData[3]}`
  const fullAddressWithApt = `${homeLocationData[0].slice(5)} ${homeLocationData[1]}, ${homeLocationData[5]}, ${
    homeLocationData[2]
  }, ${homeLocationData[3]} ${homeLocationData[4]}`
  const fullAddress = `${homeLocationData[0].slice(5)} ${homeLocationData[1]}, ${homeLocationData[2]}, ${
    homeLocationData[3]
  } ${homeLocationData[4]}`

  useEffect(() => {
    document.title = 'Virtual Tour | California Mentor'
  }, [])
  return (
    <>
      <Header back />
      <VirtualTourStyles>
        <div className="tour-info">
          <img
            src="https://proton-resources-production.imgix.net/4122b836aabb536852ba6a24d0be301e7a59038f8cfeceacf157581b180fb12d.png?orient=&auto=compress"
            alt=""
          />
          <div className="tour-details">
            <h1 className="tour-owner">{homeInfo.names[0]} Home Tour</h1>
            <p className="tour-address">
              {isAdmin && homeLocationData[5].length > 1 ? fullAddressWithApt : isAdmin ? fullAddress : cityState}
            </p>
          </div>
        </div>
        <div className="tour-video">
          {vtInfo.vTourUrl === '$$$$$' ? (
            renderVideoPlayer("https://via.placeholder.com/750x300", false)
          ) : (
            renderVideoPlayer(vtInfo.vTourUrl, false)
          )}
        </div>
      </VirtualTourStyles>
    </>
  )
}

const renderVideoPlayer = (srcUrl, isHtml5Video) => {
  return (
    isHtml5Video ? 
    <ReactPlayer className="responsive-iframe" url={srcUrl} width="100%" height="100%" /> :
    <iframe
      title="YouTube video player"
      className="responsive-iframe"
      src={srcUrl}
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
      allowfullscreen>        
    </iframe>
  )
}

const VirtualTourStyles = styled.div`
  margin: 5rem auto;
  width: 100%;
  max-width: 900px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  & img {
    width: 100%;
    max-width: 100px;
    object-fit: contain;
    max-height: max(3rem, 7vh);
    aspect-ratio: 4/3;
    margin: 2rem 2rem 2rem 0;
  }
  .tour-info {
    background-color: var(--lightGrey, black);
    border-radius: 0.5rem 0.5rem 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .tour-details {
    margin: 3rem 0;
  }
  .tour-owner {
    margin: 0.5rem 0;
    font-family: var(--fontPrimary, fontPrimary);
    font-size: 3rem;
    color: var(--black, black);
  }
  .tour-address {
    margin: 1rem 0;
    font-family: var(--fontPrimary, fontPrimary);
    font-size: 2.1rem;
    color: var(--grey, grey);
    font-weight: 400;
  }
  .tour-video {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
  }
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`
export default VirtualTour
