import { FormHelperText } from '@material-ui/core'

export const HeaderLink = (props) => {
  const template = (
    <div
      className={props.className}
      style={{
        background: props.bck,
        fontSize: props.size,
        color: props.color,
        padding: props.padding,
        display: 'inline-block',
        fontFamily: 'Roboto',
        ...props.add,
      }}>
      {props.text}
    </div>
  )
  if (props.link) {
    return (
      <a href={props.href} rel="noreferrer" target="_blank">
        {template}
      </a>
    )
  } else {
    return template
  }
}

export const textErrorHelper = (formik, values) => ({
  error: formik.errors[values] && formik.touched[values],
  helperText: formik.errors[values] && formik.touched[values] ? formik.errors[values] : null,
})

export const selectErrorHelper = (formik, values) => {
  if (formik.errors[values] && formik.touched[values]) {
    return (
      <FormHelperText style={{ color: 'red', transform: 'translate(15px)' }}>{formik.errors[values]}</FormHelperText>
    )
  }
  return false
}

export const selectIsError = (formik, values) => {
  return formik.errors[values] && formik.touched[values]
}

export const reverseArray = (actualArray) => {
  let reversedArray = []

  for (let i = actualArray.length - 1; i >= 0; i--) {
    reversedArray.push(actualArray[i])
  }
  return reversedArray
}

export const token = localStorage.getItem('accessToken')

// console.log("%c 🚀 ~ file: tools.js ~ line 58 ~ TOKEN---------------------","color:red", token)
export const getAuthHeader = () => {
  const token = localStorage.getItem('accessToken')
  return { headers: { Authorization: `Bearer ${token}` } }
}

// export const BASE_URL = "https://py5aq2lhmf.execute-api.us-east-2.amazonaws.com/prod"
export const BASE_URL = process.env.REACT_APP_BASE_URL

// window.location.hostname === 'localhost' ||
// window.location.hostname === '127.0.0.1' ||
// window.location.hostname === ''
//   ? process.env.REACT_APP_BASE_URL
//   : process.env.local.REACT_APP_BASE_URL
