import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { Formik, useFormik, Form } from 'formik'
import Resizer from 'react-image-file-resizer'
import { Button, Typography, Stepper, Step, StepLabel, CircularProgress } from '@material-ui/core'

import AdminLayout from '../../../Hoc/AdminLayout'
import useStyles from './styles'
import { showErrorToast, showSuccessToast } from '../../../libs/Utils'
import { BASE_URL } from '../../../libs/tools'
import HomeInfoForm from './Forms/HomeInfoForm'
import MentorInfoForm from './Forms/MentorInfoForm'
import PublicTransportation from './Forms/PublicTransportation'
import ReviewForm from './ReviewForm'
import SubmitSuccess from './SubmitSuccess/SubmitSuccess'

import { validationSchema } from './FormModel/validationSchema'
import { addHomeFormModel } from './FormModel/addHomeFormModel'
import { formInitialValues } from './FormModel/formInitialValues'

import { HomesContext } from '../../../contexts/homesContext'
import { RegionContext } from '../../../contexts/regionContext'
import { ProgramContext } from '../../../contexts/programContext'
import { AuthContext } from '../../../contexts/authContext'
import MentorPlaceHolderImage from '../../../resources/images/Forms.png'

const steps = ['Home Information', 'Mentor Information', 'Transportation', 'Completed']
const { formId, formField } = addHomeFormModel

// let appRender = 0

function _renderStepContent(
  step,
  formik,
  setMainHomeImage,
  mainHomeImage,
  homeImages,
  setHomeImages,
  mentorHighlightImage,
  setMentorHighlightImage,
  mapImageArr,
  setMapImageArr,
  formType
) {
  switch (step) {
    case 0:
      // return <ReviewForm formField={formField}  />;
      return (
        <HomeInfoForm
          formField={formField}
          formik={formik}
          formType={formType}
          setMainHomeImage={setMainHomeImage}
          mainHomeImage={mainHomeImage}
          homeImages={homeImages}
          setHomeImages={setHomeImages}
        />
      )
    // return updatePassword;
    case 1:
      return (
        <MentorInfoForm
          formField={formField}
          formik={formik}
          mentorHighlightImage={mentorHighlightImage}
          setMentorHighlightImage={setMentorHighlightImage}
        />
      )
    case 2:
      return (
        <PublicTransportation
          formField={formField}
          formik={formik}
          mapImage={mapImageArr}
          setMapImage={setMapImageArr}
        />
      )
    case 3:
      return <ReviewForm formField={formField} />
    default:
      return <div>Not Found</div>
  }
}
const AddHomes = () => {
  // console.log('🚀 ~ file: addEditHomes.jsx ~ line 72 ~ appRender', appRender++)
  const classes = useStyles()
  const { region } = useContext(RegionContext)
  const { program } = useContext(ProgramContext)
  const { sessionInfo, getRefreshSession } = useContext(AuthContext)
  const { homeToUpdate, setAvailablePaginated, setUnavailablePaginated, setComingSoonPaginated } =
    useContext(HomesContext)
  // const localStorageToken = window.localStorage.getItem('accessToken')
  const [formType, setFormType] = useState('add')
  const [values, setValues] = useState(formInitialValues)
  const [mainHomeImage, setMainHomeImage] = useState([])
  const [homeImages, setHomeImages] = useState([])
  const [mentorHighlightImage, setMentorHighlightImage] = useState([])
  const [mapImageArr, setMapImageArr] = useState([])
  const [loading, setLoading] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const currentValidationSchema = validationSchema[activeStep]
  const isLastStep = activeStep === steps.length - 1

  const ConvertAdditionalImages = (images, formik) => {
    const results = []
    images.forEach((image) => {
      try {
        Resizer.imageFileResizer(
          image,
          900,
          900,
          'JPEG',
          100,
          0,
          (uri) => {
            results.push(uri.slice(23))
          },
          'base64',
          200,
          200
        )
      } catch (err) {
        console.log(err)
      }
    })
    formik.setFieldValue('additionalImages', results)
  }

  const _handleSubmit = async (values, actions) => {
    if (isLastStep) {
      _submitForm(values, actions)
    } else {
      if (activeStep === 2) {
        ConvertAdditionalImages(homeImages, actions)
        setActiveStep(activeStep + 1)
        actions.setTouched({})
        actions.setSubmitting(false)
      } else {
        setActiveStep(activeStep + 1)
        actions.setTouched({})
        actions.setSubmitting(false)
      }
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1)
  }

  async function _submitForm(values, actions) {
    if (formType === 'add') {
      const AddHome = async () => {
        actions.setSubmitting(true)
        setLoading(true)
        try {
          const dataToPost = {
            mainImage: values.mainImage,
            additionalImages: values.additionalImages,
            mapImage: values.mapI.length < 1 ? '$$$$$' : values.mapI.slice(23),
            mentorHighLightImage:
              values.mentorHighLightImage.length < 1 ? '$$$$$' : values.mentorHighLightImage.slice(23),
            pk: `ORG#CSS#ST#CA#REG#${values.region}#PROG#${values.program}`,
            home: {
              status: values.status,
              homeInfo: {
                streetNumber: values.address.replace(/[^0-9]/g, ''),
                streetAddress: values.address.slice(values.address.replace(/[^0-9]/g, '').length + 1),
                city: values.city,
                state: values.state,
                zip: values.zipcode,
                // availabilityDate: values.availabilityDate.isEmpty() ? '' : values.availabilityDate,
                availabilityDate: values.availabilityDate === null ? '' : values.availabilityDate,
                suiteApt: values.address2,
                bedsAvailable: values.bedsAvailable,
                cats: values.cats,
                dogs: values.dogs,
                fullBathrooms: values.fullBathrooms,
                multiplePersons: values.multiplePersons,
                names: [values.mentorNames],
                mentorType: values.mentorType,
                seperateBaths: values.seperateBaths,
                stairs: values.stairs,
                tier: values.tier,
                totalBeds: values.totalBeds,
                wheelChair: values.wheelChair,
                description: values.homeDescription,
              },
              mapInfo: {
                nearestHospital: values.nearestHospital.length < 1 ? '$$$$$' : values.nearestHospital,
                nearestStore: values.nearestStore.length < 1 ? '$$$$$' : values.nearestStore,
                nearestPark: values.nearestPark.length < 1 ? '$$$$$' : values.nearestPark,
                dayProgram: values.dayProgram.length < 1 ? '$$$$$' : values.dayProgram,
              },
              mentorHighlightInfo: {
                description: values.mhdesc,
                videoUrl: values.mhvid.length < 1 ? '$$$$$' : values.mhvid,
              },
              ptInfo: {
                bike: [
                  values.bike0.length < 1 ? '$$$$$' : values.bike0,
                  values.bike1.length < 1 ? '$$$$$' : values.bike1,
                ],
                bus: [values.bus0.length < 1 ? '$$$$$' : values.bus0, values.bus1.length < 1 ? '$$$$$' : values.bus1],
                train: [
                  values.train0.length < 1 ? '$$$$$' : values.train0,
                  values.train1.length < 1 ? '$$$$$' : values.train1,
                ],
              },
              virtualTourInfo: {
                videoUrl: values.videoUrl.length < 1 ? '$$$$$' : values.videoUrl,
              },
            },
          }

          // console.log('🚀 ~ file: editCaseManagers.jsx ~ line 256 ~ ADD HOME DATA TO POST ', dataToPost)

          const response = await axios.post(`${BASE_URL}/admin/createHome`, dataToPost, {
            headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
          })
          // console.log(response)

          showSuccessToast('Successfully Added')
          actions.setSubmitting(false)
          setActiveStep(activeStep + 1)
          setMainHomeImage([])
          setHomeImages([])
          setMentorHighlightImage([])
          setMapImageArr([])
          setLoading(false)
          setAvailablePaginated(false)
          setUnavailablePaginated(false)
          setComingSoonPaginated(false)
        } catch (err) {
          if (err && err.response.status === 403) {
            await getRefreshSession()
            showErrorToast('Refreshing your token, Please Submit Again')
            actions.setSubmitting(false)
            setLoading(false)
          } else {
            actions.setSubmitting(false)
            showErrorToast('Add Home Failed')
            setLoading(false)
          }
        }
      }
      AddHome()
    } else {
      const EditHomeData = async () => {
        actions.setSubmitting(true)
        setLoading(true)
        try {
          const { homeInfo } = homeToUpdate.home
          const { mhInfo } = homeToUpdate.home
          const { mapInfo } = homeToUpdate.home
          const { ptInfo } = homeToUpdate.home
          const homeAddressData = homeToUpdate.SK.S.slice(5).split('$')
          // const numberAddress = homeAddressData[0].concat(' ', homeAddressData[1])

          const getAdditionalImagesToAddBase64 = (images) => {
            let result = []
            images.map((image) => {
              result.push({
                action: 'add',
                base64Image: image,
              })
            })
            return result
          }
          var result = homeInfo.photoUrls.filter(function (o1) {
            return !homeImages.some(function (o2) {
              return o1.picName === o2.name
            })
          })
          const imagesTodelete = result.map((imgdel) => {
            return {
              action: 'delete',
              name: imgdel.picName,
            }
          })
          const imagesToAdd = getAdditionalImagesToAddBase64(values.additionalImages)
          const updateAdditionalImages = imagesTodelete.concat(imagesToAdd)

          const dataToPost = {
            pk: `ORG#CSS#ST#CA#REG#${values.region}#PROG#${values.program}`,
            // "updateMainImage": {
            //     "name": "homes/00012$Lake Plain LN$Los Santos$CA$90210$A.jpeg",
            //     "base64Image":"<BASE_64_IMAGE_STRING>"
            // },

            updateAdditionalImages: updateAdditionalImages.length > 0 ? updateAdditionalImages : null,

            updateMainImage:
              homeInfo.picUrl !== mainHomeImage[0]?.preview
                ? {
                    name: homeInfo.picName,
                    base64Image: values.mainImage,
                  }
                : null,
            ///What if there was map image but then got deleted what information do i sent
            updateMapImage:
              mapInfo.mapImage !== mapImageArr[0]?.preview || !mapInfo.mapImageName === '$$$$$'
                ? {
                    // name: mapImageArr[0]?.name,
                    name: mapInfo.mapImageName,
                    base64Image: values.mapI.slice(23),
                  }
                : null,
            updateMentorHighLightImage:
              mhInfo.pictureUrl !== mentorHighlightImage[0]?.preview || !mhInfo.pictureUrlName === '$$$$$'
                ? // && !values.mentorHighLightImage === ''
                  {
                    // name: mentorHighlightImage[0].name,
                    name: mhInfo.pictureUrlName,
                    base64Image: values.mentorHighLightImage.slice(23),
                  }
                : null,
            currentHome: {
              home: {
                status: homeToUpdate.home.status,
                homeInfo: {
                  streetNumber: homeAddressData[0],
                  streetAddress: homeAddressData[1],
                  city: homeAddressData[2],
                  state: homeAddressData[3],
                  zip: homeAddressData[4],
                  suiteApt: homeAddressData[5].length > 1 ? homeAddressData[5] : '',
                  // availabilityDate: homeInfo.availabilityDate,
                  availabilityDate: homeInfo.availabilityDate === null ? '' : homeInfo.availabilityDate,
                  bedsAvailable: homeInfo.bedsAvailable,
                  cats: homeInfo.cats,
                  dogs: homeInfo.dogs,
                  fullBathrooms: homeInfo.fullBaths,
                  multiplePersons: homeInfo.multpers,
                  names: homeInfo.names[0],
                  // picUrls: homeInfo.photoUrls,
                  picUrls: homeInfo?.photoUrls?.map((item) => {
                    return item.picName
                  }),
                  picUrl: homeInfo.picUrl,
                  seperateBaths: homeInfo.separatebath,
                  mentorType: values.mentorType,
                  stairs: homeInfo.stairs,
                  tier: homeInfo.tier,
                  totalBeds: homeInfo.totalBeds,
                  wheelChair: homeInfo.wheelChair,
                  description: homeInfo.description,
                },
                mapInfo: {
                  mapImage: mapInfo.mapImage,
                  nearestHospital: mapInfo.nearesthospital,
                  nearestStore: mapInfo.nearestStore,
                  nearestPark: mapInfo.nearestPark,
                  dayProgram: mapInfo.dayProgram,
                },
                mentorHighlightInfo: {
                  mhImage: mhInfo.pictureUrl,
                  description: mhInfo.description,
                  videoUrl: mhInfo.videoUrl,
                },
                ptInfo: {
                  bike: [ptInfo.bike[0], ptInfo.bike[1]],
                  bus: [ptInfo.bus[0], ptInfo.bus[1]],
                  train: [ptInfo.train[0], ptInfo.train[1]],
                },
                virtualTourInfo: {
                  videoUrl: homeToUpdate.home.vtInfo.vTourUrl,
                },
              },
            },
            updatedHome: {
              home: {
                status: values.status,
                homeInfo: {
                  streetNumber: values.address.replace(/[^0-9]/g, ''),
                  streetAddress: values.address.slice(values.address.replace(/[^0-9]/g, '').length + 1),
                  city: values.city,
                  state: values.state,
                  zip: values.zipcode,
                  suiteApt: values.address2,
                  // availabilityDate: values.availabilityDate,
                  availabilityDate: values.availabilityDate === null ? '' : values.availabilityDate,
                  bedsAvailable: values.bedsAvailable,
                  cats: values.cats,
                  dogs: values.dogs,
                  fullBathrooms: values.fullBathrooms,
                  multiplePersons: values.multiplePersons,
                  names: [values.mentorNames],
                  // picUrls: [
                  //   'homes/00012$Lake Plain LN$Los Santos$CA$90210$A_9fba6980-4175-4d3c-94d0-21e9016c3095.jpeg',
                  // ],

                  ////Need to Loop Thru photo urls and return mnames
                  picUrls: homeInfo?.photoUrls?.map((item) => {
                    return item.picName
                  }),
                  // picUrls: homeInfo.photoUrls,

                  picUrl: homeInfo.picName,
                  // picUrl: 'homes/00012$Lake Plain LN$Los Santos$CA$90210$A.jpeg',
                  mentorType: values.mentorType,
                  seperateBaths: values.seperateBaths,
                  stairs: values.stairs,
                  tier: values.tier,
                  totalBeds: values.totalBeds,
                  wheelChair: values.wheelChair,
                  description: values.homeDescription,
                },
                mapInfo: {
                  mapImage: mapInfo.mapImageName === '$$$$$' ? '$$$$$' : mapInfo.mapImageName,
                  // mapImage: 'homes/00012$Lake Plain LN$Los Santos$CA$90210$A_map.jpeg',
                  nearestHospital: values.nearestHospital.length < 1 ? '$$$$$' : values.nearestHospital,
                  nearestStore: values.nearestStore.length < 1 ? '$$$$$' : values.nearestStore,
                  nearestPark: values.nearestPark.length < 1 ? '$$$$$' : values.nearestPark,
                  dayProgram: values.dayProgram.length < 1 ? '$$$$$' : values.dayProgram,
                },
                mentorHighlightInfo: {
                  mhImage: mhInfo.pictureUrlName === '$$$$$' ? '$$$$$' : mhInfo.pictureUrlName,
                  // mhImage: 'homes/00012$Lake Plain LN$Los Santos$CA$90210$A_mentorhighlight.jpeg',
                  description: values.mhdesc,
                  videoUrl: values.mhvid.length < 1 ? '$$$$$' : values.mhvid,
                },
                ptInfo: {
                  bike: [
                    values.bike0.length < 1 ? '$$$$$' : values.bike0,
                    values.bike1.length < 1 ? '$$$$$' : values.bike1,
                  ],
                  bus: [values.bus0.length < 1 ? '$$$$$' : values.bus0, values.bus1.length < 1 ? '$$$$$' : values.bus1],
                  train: [
                    values.train0.length < 1 ? '$$$$$' : values.train0,
                    values.train1.length < 1 ? '$$$$$' : values.train1,
                  ],
                },
                virtualTourInfo: {
                  videoUrl: values.videoUrl.length < 1 ? '$$$$$' : values.videoUrl,
                },
              },
            },
          }

          // console.log('🚀 ~ ^^^^^^^^^^^^^^^^^^^^^^^^^^ EDIT HOME DATA TO POST ', dataToPost)

          const response = await axios.post(`${BASE_URL}/admin/updateHome`, dataToPost, {
            headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
          })
          // console.log('$$$$$$$$$$$$$$$$$$$$ HOME  DATA SEND TO API SUCCESS')
          // console.log(response)

          showSuccessToast('Successfully Updated')
          actions.setSubmitting(false)
          setActiveStep(activeStep + 1)
          setMainHomeImage([])
          setHomeImages([])
          setMentorHighlightImage([])
          setMapImageArr([])
          setLoading(false)
          setAvailablePaginated(false)
          setUnavailablePaginated(false)
          setComingSoonPaginated(false)
        } catch (err) {
          if (err && err.response.status === 403) {
            await getRefreshSession()
            showErrorToast('Refreshing your token, Please Submit Again')
            actions.setSubmitting(false)
            setLoading(false)
          } else {
            actions.setSubmitting(false)
            showErrorToast('Edit Home Failed')
            setLoading(false)
          }
        }
      }
      EditHomeData()
    }
  }

  useEffect(() => {
    if (!homeToUpdate || homeToUpdate.length === 0) {
      document.title = 'Add a Home | California Mentor'
      setValues(formInitialValues)
      setFormType('add')
      // setFiles([])
    } else {
      document.title = 'Edit a Home | California Mentor'
      setFormType('edit')
      if (homeToUpdate) {
        const { homeInfo } = homeToUpdate.home
        const { mhInfo } = homeToUpdate.home
        const { mapInfo } = homeToUpdate.home
        const { ptInfo } = homeToUpdate.home
        const homeAddressData = homeToUpdate.SK.S.slice(5).split('$')
        // const numberAddress = homeAddressData[0].concat(' ', homeAddressData[1])

        const editHomeFormData = {
          mainImage: homeInfo.picUrl,
          additionalImages: homeInfo.photoUrls,
          region: region,
          program: program,
          mentorNames: homeInfo.names[0],
          mentorType: homeInfo.mentorType,
          status: homeToUpdate.home.status,
          address: homeAddressData[0].concat(' ', homeAddressData[1]),
          address2: homeAddressData[5].length > 1 ? homeAddressData[5] : '',
          city: homeAddressData[2],
          state: homeAddressData[3],
          zipcode: homeAddressData[4],
          availabilityDate: homeInfo.availabilityDate,
          totalBeds: homeInfo.totalBeds,
          bedsAvailable: homeInfo.bedsAvailable,
          fullBathrooms: homeInfo.fullBaths,
          tier: homeInfo.tier,
          multiplePersons: homeInfo.multpers,
          cats: homeInfo.cats,
          dogs: homeInfo.dogs,
          seperateBaths: homeInfo.separatebath,
          stairs: homeInfo.stairs,
          wheelChair: homeInfo.wheelChair,
          videoUrl: homeToUpdate.home.vtInfo.vTourUrl === '$$$$$' ? '' : homeToUpdate.home.vtInfo.vTourUrl,
          homeDescription: homeInfo.description,
          mhdesc: mhInfo.description,
          mhvid: mhInfo.videoUrl === '$$$$$' ? '' : mhInfo.videoUrl,
          mentorHighLightImage: mhInfo.pictureUrl === '$$$$$' ? '' : mhInfo.pictureUrl,
          mapI: mapInfo.mapImage === '$$$$$' ? '' : mapInfo.mapImage,
          dayProgram: mapInfo.dayProgram === '$$$$$' ? '' : mapInfo.dayProgram,
          nearestHospital: mapInfo.nearesthospital === '$$$$$' ? '' : mapInfo.nearesthospital,
          nearestStore: mapInfo.nearestStore === '$$$$$' ? '' : mapInfo.nearestStore,
          nearestPark: mapInfo.nearestPark === '$$$$$' ? '' : mapInfo.nearestPark,
          bike0: ptInfo.bike[0] === '$$$$$' ? '' : ptInfo.bike[0],
          bike1: ptInfo.bike[1] === '$$$$$' ? '' : ptInfo.bike[1],
          bus0: ptInfo.bus[0] === '$$$$$' ? '' : ptInfo.bus[0],
          bus1: ptInfo.bus[1] === '$$$$$' ? '' : ptInfo.bus[1],
          train0: ptInfo.train[0] === '$$$$$' ? '' : ptInfo.train[0],
          train1: ptInfo.train[1] === '$$$$$' ? '' : ptInfo.train[1],
        }
        setValues(editHomeFormData)
        const mainImageInfo = {
          preview: homeInfo.picUrl,
          imageName: homeInfo.picName,
        }
        const mentorHighLightImageEdit = {
          preview: mhInfo.pictureUrl === '$$$$$' ? '' : mhInfo.pictureUrl,
          imageName: mhInfo.pictureUrlName === '$$$$$' ? '' : mhInfo.pictureUrlName,
        }
        const mapImageEdit = {
          preview: mapInfo.mapImage === '$$$$$' ? '' : mapInfo.mapImage,
          imageName: mapInfo.mapImageName === '$$$$$' ? '' : mapInfo.mapImageName,
        }
        setMainHomeImage([mainImageInfo])
        setMentorHighlightImage([mentorHighLightImageEdit])
        setMapImageArr([mapImageEdit])
        ///Solution to remove Error Handler When on edit
        // formik.setFieldTouched('mainImage', true)

        const convertUrlToBlobFile = (imageinfo) => {
          var blob = new Blob([imageinfo.url])
          var file = new File([blob], imageinfo.picName, { type: 'image/jpeg' })
          file.preview = imageinfo.url
          file.path = imageinfo.picName
          return file
        }
        const convertedPhotosToBlobs = homeInfo?.photoUrls.map((photo) => {
          const result = convertUrlToBlobFile(photo)
          return result
        })
        setHomeImages(convertedPhotosToBlobs)
      }
    }
  }, [homeToUpdate, formType, region])

  return (
    <AdminLayout>
      <React.Fragment>
        {/* <Typography component="h1" variant="h4" align="center">
        Checkout
      </Typography> */}
        <Typography
          style={{ color: 'rgb(66, 66, 66)', fontWeight: 500, fontFamily: 'Roboto' }}
          align="center"
          variant="h3">
          {formType === 'add' ? 'Add Home' : 'Edit Home'}
        </Typography>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {activeStep === steps.length ? (
            <SubmitSuccess setActiveStep={setActiveStep} />
          ) : (
            <Formik
              enableReinitialize={true}
              initialValues={values}
              validationSchema={currentValidationSchema}
              onSubmit={_handleSubmit}>
              {(formik) => (
                <Form id={formId}>
                  {_renderStepContent(
                    activeStep,
                    formik,
                    setMainHomeImage,
                    mainHomeImage,
                    homeImages,
                    setHomeImages,
                    mentorHighlightImage,
                    setMentorHighlightImage,
                    mapImageArr,
                    setMapImageArr,
                    formType
                  )}

                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={_handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}

                    <div className={classes.wrapper}>
                      <Button
                        // disabled={formik.isSubmitting}
                        disabled={loading}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}>
                        {isLastStep ? 'Submit' : 'Next'}
                      </Button>
                      {/* {formik.isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />} */}
                      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </React.Fragment>
      </React.Fragment>
    </AdminLayout>
  )
}

export default AddHomes
