import { toast } from 'react-toastify'
import { FormHelperText } from '@material-ui/core'

export const showErrorToast = (msg) => {
  toast.error(msg, {
    position: toast.POSITION.TOP_LEFT,
  })
}

export const showSuccessToast = (msg) => {
  toast.success(msg, {
    position: toast.POSITION.TOP_LEFT,
  })
}

export const mapByStatus = (arr, status) => {
  let res = []
  arr.forEach((i) => {
    if (i.status === status) {
      res.push(i)
    }
  })
  return res
}

export const convertArrToObject = (arr) => {
  let res = []
  arr.forEach((i) => {
    let obj = {
      value: i,
      label: i,
    }
    res.push(obj)
  })
  return res
}

export const getCasemanagerEmailFromAttrInfo = (arr) => {
  let res = []
  arr.forEach((i) => {
    if (i.Name === 'email') {
      res.push(i.Value)
    }
  })
  return res.toString()
}

export const converRegionsObjectToArray = (arr) => {
  let res = []
  arr.forEach((i) => {
    res.push(i.regId.slice(14))
  })
  return res
}

export const textErrorHelper = (formik, values) => ({
  error: formik.errors[values] && formik.touched[values],
  helperText: formik.errors[values] && formik.touched[values] ? formik.errors[values] : null,
})

export const selectErrorHelper = (formik, values) => {
  if (formik.errors[values] && formik.touched[values]) {
    return <FormHelperText>{formik.errors[values]}</FormHelperText>
  }
  return false
}

export const selectIsError = (formik, values) => {
  return formik.errors[values] && formik.touched[values]
}
