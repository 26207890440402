import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { HeaderLink } from '../../libs/tools'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { IconButton, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Nav from './../Nav'

const HeaderStyles = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1em 2em;
  align-items: center;
  background-color: var(--primary, primary);
  ${({ whiteBg }) =>
    whiteBg &&
    `
    background-color: var(--white, white);
  `}
  .navigation {
    display: flex;
  }
`

const useStyles = makeStyles(() => ({
  button: {
    color: 'white',
    transform: 'translateX(-100px)',
    padding: '0 2rem',
    backgroundColor: 'var(--secondary, secondary)',
    '&:hover': {
      backgroundColor: 'var(--secondary-dark, secondary-dark)',
    },
  },
  arrow: {
    cursor: 'pointer',
    color: 'var(--white, white)',
    fontSize: '2rem',
  },
}))

const Header = ({ ...props }) => {
  const history = useHistory()
  const classes = useStyles()

  const handleGoBack = () => {
    if (history.location.pathname === '/' || history.location.pathname === '/signin') {
      history.push('/welcome')
    } else {
      history.goBack()
    }
  }
  return (
    <HeaderStyles whiteBg={props.white}>
      {props.back ? (
        <div>
          <IconButton onClick={handleGoBack}>
            <ArrowBackIosIcon className={classes.arrow} />
          </IconButton>
        </div>
      ) : (
        <div></div>
      )}
      <HeaderLink
        className={props.className}
        link={props.link}
        href={props.href}
        text={props.title}
        color={props.color}
        size={props.size}></HeaderLink>
      <div className="navigation">
        {props.contact ? (
          <>
            <Button
              className={classes.button}
              onClick={() => history.push('/contact')}
              size="small"
              variant="contained">
              Contact Us
            </Button>
            <Nav />
          </>
        ) : (
          <div>
            <Nav />
          </div>
        )}
      </div>
    </HeaderStyles>
  )
}

export default Header
