import React, { useEffect, useContext, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'

import { getAuthHeader, BASE_URL } from '../libs/tools'
import { converRegionsObjectToArray } from '../libs/Utils'
import Loader from '../libs/loader'

import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'

import { RegionContext } from '../contexts/regionContext'
import { ProgramContext } from '../contexts/programContext'

const RegionSelectApiDropdownAddHome = ({ outlined, label, width200, font18, disabled }) => {
  const [open, setOpen] = useState(false)
  const [isLoadingRegions, setLoadingRegions] = useState(true)
  const { regions, setRegion, setRegions, region, setCurrentRegion } = useContext(RegionContext)
  const { setPrograms, setProgram, setLoadingPrograms } = useContext(ProgramContext)

  useEffect(() => {
    const getRegionsData = async () => {
      try {
        const statusData = {
          orgState: 'ORG#CSS#ST#CA',
        }
        const response = await axios.post(`${BASE_URL}/casemanagers/getRegions`, statusData, getAuthHeader())
        const regionResponseConverted = converRegionsObjectToArray(response.data.regions)
        setRegions(regionResponseConverted)
        setLoadingRegions(false)
      } catch (err) {
        setLoadingRegions(false)
      }
    }

    if (!regions || regions.length === 0) {
      getRegionsData()
    } else {
      setLoadingRegions(false)
    }
    // getRegionsData()
  }, [regions])

  useEffect(() => {
    const getProgramData = async () => {
      try {
        const statusData = {
          region: `ORG#CSS#ST#CA#REG#${region}`,
        }
        const response = await axios.post(`${BASE_URL}/casemanagers/getPrograms`, statusData, getAuthHeader())
        const returnedPrograms = response.data.programs
        setPrograms(returnedPrograms)
        // setProgram(returnedPrograms[0])

        setLoadingPrograms(false)
      } catch (err) {
        setLoadingPrograms(false)
      }
    }
    if (region) {
      getProgramData()
    }
  }, [region])

  const ITEM_HEIGHT = 40
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    // anchorOrigin: {
    //   vertical: 'bottom',
    // },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: `${width200 ? '200' : '500'}`,
        // transform: 'translateY(100px)',
      },
    },
  }
  const regionsSort = regions.sort((a, b) => (a > b ? 1 : -1))

  const handleChange = (event) => {
    setRegion(event.target.value)
    setCurrentRegion(event.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  return (
    <SelectStyles>
      {isLoadingRegions ? (
        <Loader />
      ) : (
        <>
          <FormControl
            style={{
              fontSize: `${font18 ? '1.8rem' : '1.5rem'}`,
              // top: '10%',
              // paddingRight: '102px',
            }}>
            {regions && region && (
              <>
                <InputLabel>{label}</InputLabel>
                <Select
                  fullWidth
                  id="region"
                  name="region"
                  variant={outlined ? 'outlined' : 'standard'}
                  MenuProps={MenuProps}
                  displayEmpty
                  disabled={disabled}
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  value={region}
                  onChange={handleChange}
                  style={{
                    fontSize: `${font18 ? '1.8rem' : '1.5rem'}`,
                  }}>
                  {regionsSort.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={{
                        fontSize: `${font18 ? '1.8rem' : '1.5rem'}`,
                      }}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </FormControl>
        </>
      )}
    </SelectStyles>
  )
}
const SelectStyles = styled.div`
  .MuiSelect-select.MuiSelect-select {
    padding-right: 103px;
  }
`

export default RegionSelectApiDropdownAddHome
