import React, { useEffect, useContext } from 'react'
import styled from 'styled-components'
import Header from '../../components/layout/Header'
import RegionCards from './RegionCards'
import Loader from '../../libs/loader'

import { RegionContext } from '../../contexts/regionContext'
import { ProgramContext } from '../../contexts/programContext'

const Region = () => {
  const { region } = useContext(RegionContext)
  const { programs, isLoadingPrograms } = useContext(ProgramContext)

  useEffect(() => {
    document.title = 'Main Page FHA | California Mentor'
  }, [])

  const regionState = {
    county: ['Serving Alameda and Contra Costa County', 'Serving North and South County', 'Serving Los Angeles County'],
  }

  const renderRegions = () => {
    if (isLoadingPrograms) {
      return (
        <>
          <Loader />
        </>
      )
    }
    return (
      <ul className="card-wrapper">
        {programs &&
          programs.map((program, index) => {
            return <RegionCards program={program} key={index} title={program} county={regionState.county[index]} />
          })}
      </ul>
    )
  }

  return (
    <>
      <Header back color="white" title="California MENTOR - FHA Home Life" size="2rem" />
      <RegionStyles>
        <h1 className="heading">Welcome, {region}!</h1>
        {/* {isLoadingPrograms || currentRegion !== region ? ( */}
        {isLoadingPrograms ? (
          <Loader />
        ) : (
          <>
            {/* <RegionCards /> */}
            {renderRegions()}
          </>
        )}
      </RegionStyles>
    </>
  )
}
const RegionStyles = styled.div`
  position: relative;
  margin: 6rem;
  .heading {
    position: relative;
    margin: 0rem auto;
    margin-bottom: 5rem;
    text-align: center;
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeDisplay, fontSizeDisplay);
    color: var(--black, black);
  }
  .card-wrapper {
    position: relative;
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30ch, 1fr));
    grid-gap: 2.5rem;
    max-width: 100vw;
    width: 130ch;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 2rem auto;
  }
`
export default Region
