import React, { useState, useEffect, useContext, Fragment } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/thumbs/thumbs.min.css'
import SwiperCore, { Lazy, Navigation, Pagination, Thumbs } from 'swiper/core'
import ReactPlayer from 'react-player'
import moment from 'moment'

import Header from '../components/layout/Header'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AccessibleIcon from '@material-ui/icons/Accessible'
import PetsIcon from '@material-ui/icons/Pets'
import LineWeightIcon from '@material-ui/icons/LineWeight'
import RoomIcon from '@material-ui/icons/Room'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'

import { AuthContext } from '../contexts/authContext'
import { HomesContext } from '../contexts/homesContext'

import Youtube from '../resources/images/YouTube.png'

SwiperCore.use([Lazy, Navigation, Pagination, Thumbs])

const HomeDetail = () => {
  const classes = useStyles()
  const thumb = true
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const { homeDetails, setHomeToUpdate } = useContext(HomesContext)
  const { homeInfo } = homeDetails.home
  const { mhInfo } = homeDetails.home
  const { mapInfo } = homeDetails.home
  const { ptInfo } = homeDetails.home
  const { vtInfo } = homeDetails.home
  const { authStatus } = useContext(AuthContext)
  const isAdmin = authStatus && authStatus === 3
  const homeLocationData = homeDetails.SK.S.split('$')
  const cityState = `${homeLocationData[2]}, ${homeLocationData[3]}`
  const fullAddressWithApt = `${homeLocationData[0].slice(5)} ${homeLocationData[1]}, ${homeLocationData[5]}, ${
    homeLocationData[2]
  }, ${homeLocationData[3]} ${homeLocationData[4]}`
  const fullAddress = `${homeLocationData[0].slice(5)} ${homeLocationData[1]}, ${homeLocationData[2]}, ${
    homeLocationData[3]
  } ${homeLocationData[4]}`

  const formatDate =
    homeInfo?.availabilityDate !== '' && typeof homeInfo?.availabilityDate !== 'undefined'
      ? moment(
          homeInfo?.availabilityDate && homeInfo?.availabilityDate !== '' && homeInfo?.availabilityDate?.slice(0, 10)
        ).format('ll')
      : null

  const renderHomeImages = (homeImages, thumb) => {
    return (
      <Fragment>
        {homeImages.map((imageUrl, i) => {
          return (
            <SwiperSlide key={i}>
              <img
                className={thumb ? 'slider-image-thumbnail' : 'slide-inner--image'}
                alt={thumb ? 'thumbnail image' : 'House big Image'}
                src={imageUrl.url}
              />
            </SwiperSlide>
          )
        })}
      </Fragment>
    )
  }

  useEffect(() => {
    document.title = 'Home Details | California Mentor'
  }, [])
  return (
    <>
      <Header back contact />
      <HomeDetailStyles>
        <div className="home-container">
          <div>
            <h1 className="home-owner">{homeInfo.names[0]} Home</h1>
          </div>
          <div className="home-image-slider">
            <Swiper
              style={{
                '--swiper-navigation-color': '#fff',
                '--swiper-pagination-color': '#fff',
              }}
              pagination={{
                dynamicBullets: true,
              }}
              slidesPerView={1}
              loop={true}
              spaceBetween={0}
              navigation={true}
              lazy={true}
              thumbs={{ swiper: thumbsSwiper }}
              className="slider-big-container"
              autoHeight={true}>
              {homeInfo?.photoUrls?.length < 1 ? (
                <SwiperSlide>
                  <img className="slide-inner--image" alt="thumbnail house" src={homeInfo?.picUrl} />
                </SwiperSlide>
              ) : homeInfo?.photoUrls?.length < 2 ? (
                <SwiperSlide>
                  <img className="slide-inner--image" alt="thumbnail house" src={homeInfo?.photoUrls[0]?.url} />
                </SwiperSlide>
              ) : (
                renderHomeImages(homeInfo.photoUrls)
              )}
              {/* <SwiperSlide>
                  <img className="slide-inner--image" alt="thumbnail house" src={homeInfo?.picUrl} />
                </SwiperSlide> */}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              loop={true}
              spaceBetween={0}
              slidesPerView={homeInfo.photoUrls.length > 6 ? 6 : homeInfo.photoUrls.length}
              freeMode={true}
              watchSlidesVisibility={true}
              watchSlidesProgress={true}
              className="slider-thumbnail-container">
              {/* {renderHomeImages(homeInfo.photoUrls, thumb)} */}
              {homeInfo.photoUrls.length < 3 ? <Fragment></Fragment> : renderHomeImages(homeInfo.photoUrls, thumb)}
            </Swiper>
          </div>
          <div className="home-container-low">
            <div className="home-address-container">
              <div className="home-address">
                <h1>
                  {isAdmin && homeLocationData[5].length > 1 ? fullAddressWithApt : isAdmin ? fullAddress : cityState}
                </h1>
                <p>
                  {homeInfo.totalBeds} {homeInfo.totalBeds === '1' ? 'Bed' : 'Beds'} - {homeInfo.bedsAvailable}{' '}
                  {homeInfo.bedsAvailable === '1' ? 'room' : 'rooms'} available
                </p>
                <p>
                  {homeInfo.fullBaths} {homeInfo.fullBaths === '1' ? 'Full Bath' : 'Full Baths'}
                </p>
              </div>
              <div className="home-button-container">
                {vtInfo.vTourUrl === '$$$$$' || !vtInfo.vTourUrl.startsWith('https://') ? (
                  <div>
                    <Button className={classes.button} size="large" disabled variant="contained">
                      Virtual Tour
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Link to="/virtual-tour">
                      <Button className={classes.button} size="large" variant="contained">
                        Virtual Tour
                      </Button>
                    </Link>
                  </div>
                )}

                {ptInfo.bike[0] === '$$$$$' &&
                ptInfo.bus[0] === '$$$$$' &&
                ptInfo.train[0] === '$$$$$' &&
                mapInfo.mapImage === '$$$$$' &&
                mapInfo.nearestPark === '$$$$$' &&
                mapInfo.nearesthospital === '$$$$$' &&
                mapInfo.nearestStore === '$$$$$' &&
                mapInfo.dayProgram === '$$$$$' ? (
                  <div>
                    <Button className={classes.buttonOutline} size="large" disabled variant="outlined">
                      <RoomIcon className={classes.iconMap} />
                      <span>Map</span>
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Link to="/home-detail/map">
                      <Button className={classes.buttonOutline} size="large" variant="outlined">
                        <RoomIcon className={classes.iconMap} />
                        <span>Map</span>
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="home-details-container">
              <div className="home-details-box">
                <AccessibleIcon className={classes.icon} />

                <div className="home-details-box-desc">
                  {homeInfo.wheelChair === 'Y' ? '' : 'Not'} Wheelchair Accessible
                </div>
              </div>
              <div className="home-details-box">
                <PetsIcon className={classes.icon} />
                <div className="home-details-box-desc">
                  {parseInt(homeInfo.cats) + parseInt(homeInfo.dogs) === 0
                    ? 'No Pets'
                    : parseInt(homeInfo.cats) > 0 && parseInt(homeInfo.dogs) > 0
                    ? 'Has Cats & Dogs'
                    : parseInt(homeInfo.cats) > 0
                    ? 'Has Cats'
                    : parseInt(homeInfo.dogs) > 0
                    ? 'Has Dogs'
                    : ''}
                </div>
              </div>
              {/* <div className="home-details-box">
                <ChildCareIcon className={classes.icon} />

                <div className="home-details-box-desc">2 Children</div>
              </div> */}
              {isAdmin ? (
                <div className="home-details-box">
                  <LineWeightIcon className={classes.icon} />

                  <div className="home-details-box-desc">Tier Level {homeInfo.tier}</div>
                </div>
              ) : null}
              {isAdmin ? (
                <div className="home-details-box">
                  <div className="home-details-box-desc">Mentor Type: {homeInfo.mentorType}</div>
                </div>
              ) : null}
              {isAdmin ? (
                <div className="home-details-box">
                  <div className="home-details-box-desc">
                    Multiple Person's: {homeInfo.multpers === 'Y' ? 'Yes' : 'No'}
                  </div>
                </div>
              ) : null}
              {isAdmin ? (
                <div className="home-details-box">
                  <div className="home-details-box-desc">
                    Separate bath: {homeInfo.separatebath === 'Y' ? 'Yes' : 'No'}
                  </div>
                </div>
              ) : null}
              {isAdmin ? (
                <div className="home-details-box">
                  <div className="home-details-box-desc">Stairs: {homeInfo.stairs === 'Y' ? 'Yes' : 'No'}</div>
                </div>
              ) : null}
            </div>
            {homeDetails.home.status === 'IP' && formatDate !== null ? (
              <div className="home-details-box">
                <EventAvailableIcon className={classes.iconAvailable} />

                <div className="home-details-box-desc">Availability Date: {formatDate}</div>
              </div>
            ) : null}
            <div className="home-description-container">
              <p className="home-description">{homeInfo.description}</p>
            </div>
            {isAdmin ? (
              <div>
                <Link to={`/admin-homes/edit`} onClick={() => setHomeToUpdate(homeDetails)}>
                  <Button className={classes.buttonEdit} size="small" variant="contained">
                    Edit
                  </Button>
                </Link>
                {/* <Link to={`/admin-homes/delete/${id}`}>
              <Button className={classes.buttonDelete} size="small" variant="contained">
                Delete
              </Button>
            </Link> */}
              </div>
            ) : null}
          </div>
        </div>
        <div className="mentor-container">
          <div className="title">MENTOR HIGHLIGHT</div>
          <div className="mentor-details">
            <div className="image-container">
              {mhInfo.pictureUrl === '$$$$$' ? (
                <AccountCircleIcon className={classes.userIcon} />
              ) : (
                <img className="mentor-image" src={mhInfo.pictureUrl} alt="" />
              )}
            </div>
            <div className="mentor-description">
              <h1 className="mentor-name">Meet {homeInfo.names[0]}!</h1>
              <p className="mentor-intro">{mhInfo.description}</p>
            </div>
            {mhInfo.videoUrl === '$$$$$' || !mhInfo.videoUrl.startsWith('https://') ? (
              <div className="mentor-placeholder-image">
                <img src={Youtube} alt="" />
              </div>
            ) : (
              <div className="mentor-video-container">
                <ReactPlayer className="mentor-video-intro" url={mhInfo.videoUrl} width="100%" height="100%" />
              </div>
            )}
          </div>
        </div>
      </HomeDetailStyles>
    </>
  )
}
const HomeDetailStyles = styled.div`
  position: relative;
  margin: 5rem auto;
  width: 100%;
  max-width: 1600px;
  justify-content: center;
  /* background-color: green; */

  .home-container {
    border-radius: 0.5rem;
    margin: 0 auto;
    padding: 5rem;
    width: 100%;
    max-width: 1200px;
    /* background-color: var(--lightGrey, lightGrey); */

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .home-owner {
    font-family: var(--fontPrimary, fontPrimary);
    color: var(--black, black);
    font-size: 2.8rem;
    margin-bottom: 3rem;
  }
  
  .home-image-slider {
    /* position: relative;
    overflow: hidden; */
    /* width: 100%; */
    /* padding-top: 12.25%; */

    /* max-height: 500px; */

    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
    height: auto;
    /* margin-bottom: 10rem; */

    @media only screen and (max-width: $bp-largest) {
      width: 80%;
      height: 70vw;
    }
    @media only screen and (max-width: $bp-small) {
      margin: 0px;
      width: 100%;
      height: 70vw;
    }
  }
  .slider-big-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  & img {
    width: 100%;
    object-fit: fill;
    max-height: max(10rem, 54vh);
    aspect-ratio: 4/3;
  }
  /* .slide-inner--image {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(255, 0, 150, 0.3),
      rgba(255, 0, 150, 0.3)
    );
    background-size: contain;
    object-fit: contain;
    background-position: center;
    transform: scale(1.07);
    -webkit-transition: opacity 0.6s ease-in-out, transform 2s ease 0.7s;
    -moz-transition: opacity 0.6s ease-in-out, transform 2s ease 0.7s;
    transition: opacity 0.6s ease-in-out, transform 2s ease 0.7s;
  } */
  .slider-image-thumbnail {
    height: 100%;
    max-height:170px;
    width: 100%;
  }
  .slider-thumbnail-container {
    height: 100%;
    width: 100%;
    max-width: 1000px;
  }
  .home-container-low {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
  .home-address-container {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
  }
  .home-address h1 {
    font-family: var(--fontPrimary, fontPrimary);
    color: var(--black, black);
    font-size: var(--fontSizeDescription, fontSizeDescription);
    margin-bottom: 0.5rem;
  }
  .home-address p {
    font-family: var(--fontPrimary, fontPrimary);
    color: var(--black, black);
    margin: 0.25rem 0;
    font-size: 1.5rem;
  }
  .home-button-container {
    display: flex;
  }

  .home-details-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .home-details-box {
    display: flex;
    align-items: center;
    margin-bottom:2.5rem;
    margin-right: 5rem;
  }
  /* .home-details-box:not(:first-child) {
    margin-left: 5rem;
  }
  .home-details-box:not(:nth-child(6)) {
    margin-left: 5rem;
  } */
  .home-details-box-desc {
    margin-left: 0.5rem;
    font-family: var(--fontPrimary, fontPrimary);
    color: var(--black, black);
    text-transform: capitalize;
    font-weight: 500;
    font-size: 1.5rem;
    
  }
  .home-description-container {
    margin: 3rem 0;
    font-size: var(--fontSizeDescription, fontSizeDescription);
    font-size: 1.5rem;
    font-family: var(--fontPrimary, fontPrimary);
    color: var(--black, black);
  }
  .mentor-container {
    position: relative;
    /* max-height: max(3rem, 15vh); */
    width: 100%;
    padding: 5rem;
    max-width: 1200px;
    /* justify-content: center; */
    margin: 5rem auto;
    /* background-color: grey; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  }
  .mentor-details {
    position: relative;
    display: flex;
    padding: 2.5rem;
    @media (max-width: 800px) {
        flex-direction:column;
        };
    }

    /* justify-content: space-around; */

  .title {
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeDescription, fontSizeDescription);
    font-size: 2.3rem;
    padding: 2rem;
    color: var(--secondary, secondary);
    font-weight: 700;
    @media (max-width: 800px) {
        text-align:center;
        };
      }
  .image-container{
    @media (max-width: 800px) {
    text-align:center;
    };
  }
  .mentor-image {
    width: 100%;
    max-width: 200px;
    object-fit: cover;
    max-height: max(3rem, 15vh);
    /* aspect-ratio: 4/3; */
    border-radius: 50%;
    @media (max-width: 800px) {
        text-align:center;
        /* max-width:100%; */
        };

  }
  .mentor-description {
    padding: 0 2.5rem;
    max-width: 300px;
    @media (max-width: 800px) {
        text-align:center;
        padding:2.5rem;
        max-width:100%;
        };
      }
  }
  .mentor-placeholder-image{
    width:100%;
    transform: translate(-6%,-52%);
    max-width: 634px;
    max-height: 260px;
  }
  .mentor-name {
    font-family: var(--fontPrimary, fontPrimary);
    /* font-size: var(--fontSizeDescription, fontSizeDescription); */
    font-size: 1.7rem;
    margin-bottom: 1rem;
    color: var(--black, black);
  }
  .mentor-intro {
    line-height: 2rem;
    font-family: var(--fontPrimary, fontPrimary);
    color: var(--grey, grey);
  }
  .mentor-video-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 12.25%;
    min-height: 300px;
  }
  .mentor-video-intro {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width:600px;
    height: 100%;
  }
`

const useStyles = makeStyles(() => ({
  button: {
    color: 'white',
    margin: '1rem',
    padding: '.8rem 5rem',
    fontSize: '1.5rem',
    backgroundColor: 'rgb(255,127,39)',
    '&:hover': {
      backgroundColor: 'rgb(243, 134, 56)',
    },
  },
  buttonOutline: {
    color: 'rgb(255,127,39)',
    border: '2px solid rgb(255,127,39)',
    margin: '1rem',
    padding: '.8rem 5rem',
    fontSize: '1.5rem',
    // backgroundColor: "rgb(255,127,39)",
    // "&:hover": {
    //   backgroundColor: "rgb(243, 134, 56)",
    // },
  },
  buttonEdit: {
    color: 'white',

    padding: '.2rem 3rem',
    fontSize: '1.4rem',
    backgroundColor: 'rgb(16,108,103)',
    '&:hover': {
      backgroundColor: 'rgb(23, 145, 139)',
    },
  },
  icon: {
    fontSize: '2.5rem',
  },
  userIcon: {
    // cursor: 'pointer',
    color: 'var(--primary, primary)',
    fontSize: '8.3rem',
  },
  iconMap: {
    fontSize: '2.2rem',
    margin: ' 0 .5rem',
  },
  iconAvailable: {
    fontSize: '2.5rem',
    color: 'rgb(16,108,103)',
  },
}))
export default HomeDetail
