import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import Loader from '../../libs/loader'

import Header from '../../components/layout/Header'
import Hero from '../../components/hero/Hero'
import landingImage from '../../resources/images/FHAunit.jpg'
import HeroBox from '../../components/hero/HeroBox'

import { Button, Dialog, DialogActions, DialogTitle, Slide, CircularProgress } from '@material-ui/core'

import LandingCards from './LandingCards'
import mentorPng from '../../resources/images/Mentor.png'
// import analysePng from '../resources/images/Analyse.png'
import formsPng from '../../resources/images/Forms.png'
import workHomePng from '../../resources/images/WorkHome.png'

import { RegionContext } from '../../contexts/regionContext'
import { ProgramContext } from '../../contexts/programContext'
import { MentorContext } from '../../contexts/mentorContext'
import { AuthContext } from '../../contexts/authContext'
import { CaseManagerInfoContext } from '../../contexts/caseManagerInfoContext'
import { getAuthHeader, BASE_URL } from '../../libs/tools'
import { getCasemanagerEmailFromAttrInfo, showErrorToast } from '../../libs/Utils'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
// let appRender = 0
const Landing = () => {
  // console.log(`appRender = ${appRender++}`)
  // const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const { region, currentRegion, setCurrentRegion, setRegion } = useContext(RegionContext)
  const { program, setProgram } = useContext(ProgramContext)
  const { attrInfo, authStatus, sessionInfo, getRefreshSession } = useContext(AuthContext)
  // const localStorageToken = window.localStorage.getItem('accessToken')
  const { caseManagerInfo, setCaseManagerInfo, setCaseManagerContactEmail, caseManagerEmail } =
    useContext(CaseManagerInfoContext)

  const { setMentorEvents, setProgramContact } = useContext(MentorContext)
  const isCaseManager = authStatus && authStatus === 1
  const isAdmin = authStatus && authStatus === 3

  const boxDetails = {
    title: 'Home Life',
    titleBlue: `${program && program.substring(0, program && program.length - 3)} Area`,
    description:
      program && program === 'East Bay FHA'
        ? 'Located in the Bay Area and serving Contra Costa and Alameda County.'
        : program && program === 'Golden Gate FHA'
        ? 'Located in the Bay Area serving Marin, San Mateo and San Francisco counties.'
        : program && program === 'Orange County FHA'
        ? 'Providing services throughout Orange County.'
        : 'Located in the Bay Area and serving Contra Costa and Alameda County.',
  }

  const landingPageState = {
    title: ['Home Preference', 'List Of Fha Homes', 'Meet our Mentors'],

    description: [
      'Looking For Certain type of home and mentor? Complete a quick 2-minute questionaire to see if we have a home and Mentor for you!',
      `Check out our list of available and upcoming homes across ${
        program && program === 'East Bay FHA'
          ? 'Alameda and Contra Costa County'
          : program && program === 'Golden Gate FHA'
          ? 'Marin, San Mateo and San Francisco counties'
          : program && program === 'Orange County FHA'
          ? 'Orange County'
          : 'Alameda and Contra Costa County'
      }.`,
      ` Wondering what it's like to live with an FHA Mentor? Check out our upcoming virtual events and meet some of our wonderful Mentors.`,
    ],
    img: [formsPng, workHomePng, mentorPng],
    link: ['/home-preference', '/home-list', '/mentors'],
  }
  const handleClose = () => {
    setOpen(false)
  }

  const renderLandingcards = () => {
    return (
      <ul className="card-wrapper">
        {landingPageState.title.map((landing, index) => {
          return (
            <LandingCards
              key={index}
              title={landingPageState.title[index]}
              description={landingPageState.description[index]}
              img={landingPageState.img[index]}
              link={landingPageState.link[index]}
            />
          )
        })}
      </ul>
    )
  }
  useEffect(() => {
    document.title = 'FHA Unit Home | California Mentor'
    const getMentorEventsData = async () => {
      // setLoading(true)
      try {
        const statusData = {
          programId: `ORG#CSS#ST#CA#REG#${region}#PROG#${program}`,
        }
        const response = await axios.post(`${BASE_URL}/casemanagers/getMentorEvents`, statusData, {
          headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
        })
        // console.log(
        //   '%c 🚀 ~ file: LANDING.jsx ~ line 115~ MENTOR EVENT DATA RECEIVED ~ $$$$$$$$$$$$$$$$$$$$  MENTOR EVENT DATA RECEIVED',
        //   'color:green',
        //   response.data.result
        // )
        const mentorEvents = await response.data.result.mentorEvents
        setMentorEvents(mentorEvents)
        setProgramContact(response.data.result.programContact[0])
        setLoading(false)
      } catch (err) {
        if (err && err.response.status === 403) {
          getRefreshSession()
          setLoading(false)
        } else {
          showErrorToast('Error Loading Data')
          setLoading(false)
        }
      }
    }
    // const regionslice = `${caseManagerInfo?.region?.slice(41)} FHA`
    // console.log(
    //   '%c 🚀 ~ file: Landing.jsx ~ line 110 ~ useEffect ~ regionslice',
    //   'color:green',
    //   caseManagerInfo?.region
    // )
    const isCaseMangerInfoEmpty = caseManagerInfo === undefined ? {} : caseManagerInfo

    ;(isAdmin && getMentorEventsData()) ||
      (isCaseManager &&
        Object.keys(isCaseMangerInfoEmpty).length !== 0 &&
        // currentRegion === region &&
        //  regionslice === program &&
        //  region === program &&
        getMentorEventsData())
  }, [program, caseManagerInfo, region, currentRegion, sessionInfo.accessToken])

  useEffect(() => {
    if (isCaseManager) {
      //Get casemanger email from array
      const caseManagerInfoEmail = getCasemanagerEmailFromAttrInfo(attrInfo)
      //Set Case Manager email
      setCaseManagerContactEmail(caseManagerInfoEmail)
    }
    ////////////////////////API CALL TO GET CASE MANGER INFO//////////////////////////////////
    const getCaseManagerInfoData = async () => {
      // setLoading(true)
      try {
        const statusData = {
          emailId: {
            status: 'Appr',
            cmemail: caseManagerEmail && caseManagerEmail,
          },
        }
        const response = await axios.post(`${BASE_URL}/casemanagers/getInfo`, statusData, {
          // headers: { Authorization: `Bearer ${localStorageToken}` },
          headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
        })
        // console.log(
        //   '%c 🚀 ~ file: LANDING.jsx ~ line 54 ~ getCase Manager Info ~ $$$$$$$$$$$$$$$$$$$$  CASE MANAGER INFO RECEIVED',
        //   'color:green',
        //   response.data.response.Items[0]
        // )
        // const isCaseMangerInfoEmpty = caseManagerInfo === undefined ? {} : caseManagerInfo
        setCaseManagerInfo(response.data.response.Items[0])
        // setLoading(false)
      } catch (err) {
        // setLoading(true)
      }
    }
    ////////////////////////API CALL TO GET CASE MANGER INFO//////////////////////////////////
    //Check If the User Is Case Manager
    if (isCaseManager) {
      //If there is no CASE MANAGER Info data, Get the DATA
      if (!caseManagerInfo || caseManagerInfo.length === 0) {
        getCaseManagerInfoData()
      }
      // getCaseManagerInfoData()
    }
  }, [authStatus, caseManagerInfo, sessionInfo.accessToken])

  useEffect(() => {
    if (region && caseManagerInfo && caseManagerInfo.region) {
      if (region !== caseManagerInfo.region.slice(18)) {
        //they dont match set region as the case manager region
        const updatedRegion = `${caseManagerInfo.region.slice(-5)} FHA`
        const restrictedRegion = caseManagerInfo.region.slice(18)
        // console.log('REGIONS DONT MATCH+++++++++++++++++++++++++++++++++++++++++')
        setRegion(restrictedRegion)
        const getRestrictedProgram = async () => {
          // console.log('🚀 ~ file: Landing.jsx ~ line 165 ~ useEffect ~ restrictedRegion', restrictedRegion)
          try {
            const statusData = {
              // region: 'ORG#CSS#ST#CA#REG#Golden Gate Regional Center#PROG#Golden Gate FHA',
              region: `ORG#CSS#ST#CA#REG#${restrictedRegion}`,
            }
            const response = await axios.post(`${BASE_URL}/casemanagers/getPrograms`, statusData, getAuthHeader())
            // console.log(
            //   '🚀 ~ file: Region.jsx ~ line 54 ~ getRegionsData ~ $$$$$$$$$$$$$$$$$$$$  PROGRAM DATA RECEIVED',
            //   response.data.programs
            // )
            const returnedPrograms = response.data.programs
            // console.log('🚀 ~ file: Landing.jsx ~ line 189 ~ getRestrictedProgram ~ Program', program)
            // console.log('🚀 ~ file: Landing.jsx ~ line 189 ~ getRestrictedProgram ~ returnedPrograms', returnedPrograms)
            setProgram(returnedPrograms[0])
            // setCurrentRegion(restrictedRegion)
            // setLoading(false)
          } catch (err) {
            // setLoading(true)
          }
        }
        getRestrictedProgram()
        //SHOW MODAL WITH NOTIFICATION
        setOpen(true)
      }
    }
  }, [caseManagerInfo, region])

  return (
    <>
      {loading ? (
        <div style={{ margin: '50vh auto', width: 'fit-content', textAlign: 'center' }}>
          <CircularProgress size={80} />
        </div>
      ) : (
        <>
          <Header
            contact
            back
            color="#FFB767"
            link={true}
            title="How California MENTOR is responding to COVID-19"
            href="https://www.thementornetwork.com/coronavirus/"
          />
          <Hero flip src={landingImage} alt="Welcome page Hero">
            <HeroBox
              flip
              title={boxDetails.title}
              titleBlue={boxDetails.titleBlue}
              description={boxDetails.description}
            />
          </Hero>
          <LandingStyles>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description">
              <DialogTitle id="alert-dialog-slide-title">{`You've selected ${currentRegion} but unfortunately you don't have access.  You've been automatically redirected to ${region}`}</DialogTitle>
              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            {/* <RegionCards /> */}
            {renderLandingcards()}

            <div className="bottom-container">
              <div className="bottom-container-description">
                At California MENTOR we are committed to making a difference, supporting independence, and providing
                loving homes through our Host Home program. Learn more about our Host Home program and the Mentors who
                have opened their home to support individuals with intellectual and developmental disabilities.
              </div>
              <a href="https://makeadifferenceathome.com/service-location/california/" rel="noreferrer" target="_blank">
                <Button
                  style={{
                    backgroundColor: 'rgb(8, 102, 173)',
                    color: '#FFFFFF',
                    padding: '.5rem 5.5rem',
                    fontSize: '1.4rem',
                    width: 'max-content',
                  }}
                  size="large"
                  variant="contained">
                  LEARN MORE
                </Button>
              </a>
            </div>
          </LandingStyles>
        </>
      )}
    </>
  )
}

const LandingStyles = styled.div`
  position: relative;
  margin: 6rem auto;
  /* max-height: max(3rem, 15vh); */
  width: 100%;
  max-width: 1600px;

  .card-wrapper {
    position: relative;
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30ch, 1fr));
    grid-gap: 2.5rem;
    max-width: 100vw;
    width: 130ch;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 2rem auto;
  }

  .bottom-container {
    margin: 6rem auto;
    border: 2px solid rgb(255, 127, 39);
    padding: 4rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 20px;
    width: 100%;
    max-width: 900px;
    @media (max-width: 800px) {
      max-width: 600px;
    }
  }
  .bottom-container-description {
    line-height: 2.3rem;
    font-family: var(--fontPrimary, fontPrimary);
    color: var(--primary, primary);
    font-size: 1.7rem;
    margin-right: 4rem;
  }
`
export default Landing
