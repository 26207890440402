import React, { useState } from 'react'
import { useLocalStorageState } from '../hooks/useLocalStorageState'

const defaultState = {
  mentorEvents: [],
  mentorEvent: [],
  programContact: [],
}

export const MentorContext = React.createContext(defaultState)

const MentorProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false)
  const [mentorEvents, setMentorEvents] = useLocalStorageState('mentorEvents', [])
  const [mentorEvent, setMentorEvent] = useLocalStorageState('mentorEvent', [])
  const [programContact, setProgramContact] = useLocalStorageState('programContact', [])
  const addMentorEvent = (mentorEvent) => setMentorEvents([...mentorEvents, mentorEvent])

  // Edit Mentor Event
  const editMentorEvent = (mentorEventToUpdate, name) => {
    const newMentorEvent = mentorEvents.map((mentorEvent) =>
      mentorEvent.name === name ? mentorEventToUpdate : mentorEvent
    )
    setMentorEvents(newMentorEvent)
  }
  // const editMentorEvent = (mentorEventToUpdate) => {
  //   const newMentorEvent = mentorEvents.map((mentorEvent) =>
  //     mentorEvent.name === mentorEventToUpdate.name ? mentorEventToUpdate : mentorEvent
  //   )
  //   console.log(newMentorEvent)
  //   setMentorEvents(newMentorEvent)
  // }

  const state = {
    isLoading,
    mentorEvents,
    mentorEvent,
    programContact,
    setMentorEvent,
    setMentorEvents,
    setProgramContact,
    setLoading,
    addMentorEvent,
    editMentorEvent,
  }

  return <MentorContext.Provider value={state}>{children}</MentorContext.Provider>
}

export default MentorProvider
