export const addHomeFormModel = {
  formId: 'addHomeForm',
  formField: {
    mainImage: {
      name: 'mainImage',
      label: 'Main Image',
      requiredErrorMsg: 'Required',
    },
    additionalImages: {
      name: 'additionalImages',
      label: 'Additional Photos',
      requiredErrorMsg: 'Required',
    },
    region: {
      name: 'region',
      label: 'Region',
      requiredErrorMsg: 'Required',
    },
    program: {
      name: 'program',
      label: 'Program',
      requiredErrorMsg: 'Required',
    },
    mentorNames: {
      name: 'mentorNames',
      label: 'Mentor Names',
      requiredErrorMsg: 'Required',
    },
    availabilityDate: {
      name: 'availabilityDate',
      label: 'Home Availability Date',
      requiredErrorMsg: 'Home Availability date is required',
      invalidErrorMsg: 'Home Availability date is not valid',
    },
    mentorType: {
      name: 'mentorType',
      label: 'Mentor Type',
      requiredErrorMsg: 'Required',
    },
    bedsAvailable: {
      name: 'bedsAvailable',
      label: 'Available Bedrooms',
      requiredErrorMsg: 'Required',
    },
    cats: {
      name: 'cats',
      label: 'Number Of Cats',
      requiredErrorMsg: 'Required',
    },
    dogs: {
      name: 'dogs',
      label: 'Number Of Dogs',
      requiredErrorMsg: 'Required',
    },
    fullBathrooms: {
      name: 'fullBathrooms',
      label: 'Number Of Full Baths',
      requiredErrorMsg: 'Required',
    },
    tier: {
      name: 'tier',
      label: 'Tier Level',
      requiredErrorMsg: 'Required',
    },
    totalBeds: {
      name: 'totalBeds',
      label: 'Number Of Bedrooms',
      requiredErrorMsg: 'Required',
    },
    wheelChair: {
      name: 'wheelChair',
      label: 'Wheelchair Accessible?',
      requiredErrorMsg: 'Required',
    },
    multiplePersons: {
      name: 'multiplePersons',
      label: 'Multiple Person(s) Being Served?',
      requiredErrorMsg: 'Required',
    },
    seperateBaths: {
      name: 'seperateBaths',
      label: 'Separate Bath?',
      requiredErrorMsg: 'Required',
    },
    stairs: {
      name: 'stairs',
      label: 'Stairs?',
      requiredErrorMsg: 'Required',
    },
    homeDescription: {
      name: 'homeDescription',
      label: 'Home Description',
      requiredErrorMsg: 'Required',
    },
    videoUrl: {
      name: 'videoUrl',
      label: 'Add a Youtube link of your home tour.',
      requiredErrorMsg: 'Required',
    },
    address: {
      name: 'address',
      label: 'Address',
      requiredErrorMsg: 'Required',
    },
    address2: {
      name: 'address2',
      label: 'Address 2',
      requiredErrorMsg: 'Required',
    },
    city: {
      name: 'city',
      label: 'City',
      requiredErrorMsg: 'Required',
    },
    state: {
      name: 'state',
      label: 'State',
      requiredErrorMsg: 'Required',
    },
    zipcode: {
      name: 'zipcode',
      label: 'Zipcode',
      requiredErrorMsg: 'Required',
      invalidErrorMsg: 'Zipcode is not valid (e.g. 70000)',
    },
    status: {
      name: 'status',
      label: 'Home Status?',
      requiredErrorMsg: 'Required',
    },
    mhdesc: {
      name: 'mhdesc',
      label: 'Mentor Description?',
      requiredErrorMsg: 'Required',
    },
    mentorHighLightImage: {
      name: 'mentorHighLightImage',
      label: 'Mentor Image?',
      requiredErrorMsg: 'Required',
    },
    mhvid: {
      name: 'mhvid',
      label: 'Mentor Video? Add a link to Youtube Video.',
      requiredErrorMsg: 'Required',
    },
    dayProgram: {
      name: 'dayProgram',
      label: 'Day Program',
      requiredErrorMsg: 'Required',
    },
    mapI: {
      name: 'mapI',
      label: 'Map Image',
      requiredErrorMsg: 'Required',
    },
    nearestHospital: {
      name: 'nearestHospital',
      label: 'Nearest Hospital',
      requiredErrorMsg: 'Required',
    },
    nearestPark: {
      name: 'nearestPark',
      label: 'Nearest Park',
      requiredErrorMsg: 'Required',
    },
    nearestStore: {
      name: 'nearestStore',
      label: 'Nearest Store',
      requiredErrorMsg: 'Required',
    },
    bike0: {
      name: 'bike0',
      label: 'Bike Description',
      requiredErrorMsg: 'Required',
    },
    bike1: {
      name: 'bike1',
      label: 'Bike Link',
      requiredErrorMsg: 'Required',
    },
    bus0: {
      name: 'bus0',
      label: 'Bus Description',
      requiredErrorMsg: 'Required',
    },
    bus1: {
      name: 'bus1',
      label: 'Bus Link',
      requiredErrorMsg: 'Required',
    },
    train0: {
      name: 'train0',
      label: 'Train Description',
      requiredErrorMsg: 'Required',
    },
    train1: {
      name: 'train1',
      label: 'Train Link',
      requiredErrorMsg: 'Required',
    },
  },
}
