import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import AdminLayout from '../../../Hoc/AdminLayout'
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Paper,
  CircularProgress,
  Box,
  Typography,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { BASE_URL, textErrorHelper, selectErrorHelper, selectIsError } from '../../../libs/tools'
import { showErrorToast, showSuccessToast } from '../../../libs/Utils'
import { CaseManagersContext } from '../../../contexts/caseManagersContext'
import { RegionContext } from '../../../contexts/regionContext'
import { AuthContext } from '../../../contexts/authContext'

const defaultValues = {
  email: '',
  fName: '',
  lName: '',
  organization: '',
  region: '',
  status: '',
  phone: '',
  phoneExt: '',
}

const CaseManagers = (props) => {
  const classes = useStyles()
  const [formType, setFormType] = useState('add')

  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState(defaultValues)
  // const { phone, setPhone, phoneIsValid } = useValidPhone('')
  // console.log('🚀 ~ file: editCaseManagers.jsx ~ line 38 ~ CaseManagers ~ phone', phone)
  const { caseManagerToUpdate } = useContext(CaseManagersContext)
  const { regions } = useContext(RegionContext)
  const { sessionInfo, getRefreshSession } = useContext(AuthContext)

  // const localStorageToken = window.localStorage.getItem('accessToken')
  const history = useHistory()

  const regionsSort = regions.sort((a, b) => (a > b ? 1 : -1))

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values,
    validationSchema: Yup.object({
      email: Yup.string().email().required('Email is required'),
      fName: Yup.string()
        .min(2)
        .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed for this field ')
        .required('Required'),
      lName: Yup.string()
        .min(2)
        .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed for this field ')
        .required('Required'),
      organization: Yup.string()
        .min(2)
        .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field ')
        .required('Required'),
      region: Yup.string().required('Required'),
      status: Yup.string().required('Required'),
      phone: Yup.string()
        .min(2)
        .max(12)
        .matches(/^[0-9-+()]*$/, 'Only Numbers Are Allowed')
        .required('Phone  is not valid (e.g. +19998884242)'),
      phoneExt: Yup.string()
        .max(7)
        .matches(/^[0-9-+()]*$/, 'Only Numbers Are Allowed'),
    }),
    onSubmit: (values) => {
      submitForm(values)
    },
  })
  // console.log("🚀 ~ file: editCaseManagers.jsx ~ line 53 ~ CaseManagers ~ formik", formik)

  const submitForm = (values) => {
    setLoading(true)
    const combinePhoneWithExt = values.phoneExt.length > 0 ? values.phone.concat(values.phoneExt) : values.phone
    const updatedCaseManager = {
      email: values.email,
      fName: values.fName,
      lName: values.lName,
      organization: values.organization,
      region: `ORG#CSS#ST#CA#REG#${values.region}`,
      phone: combinePhoneWithExt,
      status: values.status,
      passwd: caseManagerToUpdate.passwd,
    }
    const UpdateCaseManagerData = async () => {
      try {
        const dataToPost = {
          currentCaseManager: caseManagerToUpdate,
          updatedCaseManager: updatedCaseManager,
          group: 'CASEMANAGERS',
        }
        // console.log('🚀 ~ file: editCaseManagers.jsx ~ line 101 ~ UpdateCaseManagerData ~ dataToPost', dataToPost)

        const response = await axios.post(`${BASE_URL}/admin/updateCaseManager`, dataToPost, {
          headers: { Authorization: `Bearer ${sessionInfo.accessToken}` },
        })
        // console.log('$$$$$$$$$$$$$$$$$$$$ CASE MANAGER DATA SEND TO API SUCCESS')
        showSuccessToast('Update Successful')

        if (response.status === 200) {
          history.push('/admin-case-managers')
        }
        setLoading(false)
      } catch (err) {
         if (err && err.response.status === 403) {
          await getRefreshSession()
          showErrorToast('Refreshing your token, Please Submit Again')
          setLoading(false)
        } else {
          showErrorToast('Update Failed')
          setLoading(false)
        }
      }
    }

    UpdateCaseManagerData()
    ////SET  caseManagerToUpdate TO BLANK
    // setCaseManagerToUpdate()
  }

  useEffect(() => {
    if (!caseManagerToUpdate || caseManagerToUpdate.length === 0) {
      setValues(defaultValues)
      setFormType('add')
    } else {
      document.title = 'Edit Case Manager | California Mentor'
      setFormType('edit')
      if (caseManagerToUpdate) {
        const convertedRegion = caseManagerToUpdate.region.slice(18)
        const convertedPhone =
          caseManagerToUpdate.phone.length > 12 ? caseManagerToUpdate.phone.slice(0, 12) : caseManagerToUpdate.phone
        const convertedPhoneExt = caseManagerToUpdate.phone.length > 12 ? caseManagerToUpdate.phone.slice(12) : ''
        const caseManagerToUpdatePrefillData = {
          email: caseManagerToUpdate.email,
          fName: caseManagerToUpdate.fName,
          lName: caseManagerToUpdate.lName,
          organization: caseManagerToUpdate.organization,
          region: convertedRegion,
          phone: convertedPhone,
          phoneExt: convertedPhoneExt,
          status: caseManagerToUpdate.status,
        }
        // setPhone(caseManagerToUpdate.phone)
        setValues(caseManagerToUpdatePrefillData)
      }
    }
  }, [caseManagerToUpdate])

  return (
    <AdminLayout>
      <Paper style={{ width: '60%', padding: 16, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
        <Box m={4}>
          <Typography
            style={{ color: 'rgb(66, 66, 66)', fontWeight: 500, fontFamily: 'Roboto' }}
            variant="h3">{`Edit Case Manager `}</Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box m={2}>
            <FormControl style={{ width: '60%', padding: 10 }}>
              {formType === 'edit' ? (
                <TextField
                  fullWidth
                  label="Email"
                  id="email"
                  disabled
                  name="email"
                  variant="outlined"
                  placeholder="Edit Email"
                  {...formik.getFieldProps('email')}
                  {...textErrorHelper(formik, 'email')}
                />
              ) : (
                <TextField
                  fullWidth
                  label="Email"
                  id="email"
                  name="email"
                  disabled
                  variant="outlined"
                  placeholder="Edit Email"
                  {...formik.getFieldProps('email')}
                  {...textErrorHelper(formik, 'email')}
                />
              )}
            </FormControl>
          </Box>
          <Box m={2}>
            <FormControl style={{ width: '60%', padding: 10 }}>
              <TextField
                fullWidth
                label="First Name"
                id="fName"
                name="fName"
                variant="outlined"
                placeholder="Edit First Name"
                {...formik.getFieldProps('fName')}
                {...textErrorHelper(formik, 'fName')}
              />
            </FormControl>
          </Box>

          <Box m={2}>
            <FormControl style={{ width: '60%', padding: 10 }}>
              <TextField
                id="lName"
                name="lName"
                label="Last Name"
                variant="outlined"
                placeholder="Edit Last Name"
                {...formik.getFieldProps('lName')}
                {...textErrorHelper(formik, 'lName')}
              />
            </FormControl>
          </Box>
          <Box m={2}>
            <FormControl style={{ width: '60%', padding: 10 }}>
              <TextField
                id="organization"
                name="organization"
                label="Organization"
                variant="outlined"
                placeholder="Edit Organization"
                {...formik.getFieldProps('organization')}
                {...textErrorHelper(formik, 'organization')}
              />
            </FormControl>
          </Box>
          <Box m={2}>
            <FormControl error={selectIsError(formik, 'region')} style={{ width: '60%', padding: 10 }}>
              <Select id="region" name="region" variant="outlined" displayEmpty {...formik.getFieldProps('region')}>
                <MenuItem disabled value="">
                  Select Region
                </MenuItem>
                {regionsSort.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={{
                      fontSize: '1.8rem',
                    }}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              {selectErrorHelper(formik, 'region')}
            </FormControl>
          </Box>
          <Box m={2}>
            <FormControl style={{ width: '60%', padding: 10 }}>
              <TextField
                id="phone"
                name="phone"
                label="Phone"
                variant="outlined"
                placeholder="Edit Phone"
                {...formik.getFieldProps('phone')}
                {...textErrorHelper(formik, 'phone')}
              />
            </FormControl>
          </Box>
          <Box m={2}>
            <FormControl style={{ width: '60%', padding: 10 }}>
              <TextField
                id="phoneExt"
                name="phoneExt"
                label="Phone Extension"
                variant="outlined"
                placeholder="Edit Phone Extension"
                {...formik.getFieldProps('phoneExt')}
                {...textErrorHelper(formik, 'phoneExt')}
              />
            </FormControl>
          </Box>
          {/* <Box m={2}>
            <FormControl style={{ width: '60%', padding: 10 }}>
              <Phone phoneIsValid={phoneIsValid} setPhone={(phone) => setPhone(phone)} phone={phone} />
            </FormControl>
          </Box> */}

          <Box m={2}>
            <FormControl error={selectIsError(formik, 'status')} style={{ width: '60%', padding: 10 }}>
              <>
                {caseManagerToUpdate.status === 'Den' ? (
                  <>
                    <Select
                      id="status"
                      name="status"
                      variant="outlined"
                      displayEmpty
                      {...formik.getFieldProps('status')}>
                      {/* <MenuItem value="" disabled>
                  Select Status
                </MenuItem> */}

                      <MenuItem disabled value="Den">
                        Denied
                      </MenuItem>
                      {/* <MenuItem value="PD">Pending</MenuItem> */}
                      {/* <MenuItem value="Appr">Approved</MenuItem>
                <MenuItem value="Den">Denied</MenuItem> */}
                    </Select>
                    {selectErrorHelper(formik, 'status')}
                  </>
                ) : (
                  <>
                    <Select
                      id="status"
                      name="status"
                      variant="outlined"
                      displayEmpty
                      {...formik.getFieldProps('status')}>
                      {/* <MenuItem value="" disabled>
                  Select Status
                </MenuItem> */}

                      <MenuItem disabled value="PD">
                        Pending Decision
                      </MenuItem>
                      {/* <MenuItem value="PD">Pending</MenuItem> */}
                      <MenuItem value="Appr">Approved</MenuItem>
                      <MenuItem value="Den">Denied</MenuItem>
                    </Select>
                    {selectErrorHelper(formik, 'status')}
                  </>
                )}
              </>
            </FormControl>
          </Box>

          <Box m={1}>
            <Button
              className={classes.buttonEdit}
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}>
              Save
              {loading && (
                <>
                  <CircularProgress style={{ color: 'white', marginLeft: '10px' }} size={24} />
                </>
              )}
            </Button>
            <Button
              onClick={() => history.goBack()}
              className={classes.buttonDelete}
              size="large"
              variant="contained"
              disabled={loading}>
              Cancel
            </Button>
          </Box>
        </form>
      </Paper>
    </AdminLayout>
  )
}

const useStyles = makeStyles(() => ({
  buttonEdit: {
    color: 'white',
    margin: '2rem 1rem 2rem 1rem',
    padding: '.5rem 3rem',
    fontSize: '1.4rem',
    // backgroundColor: 'rgb(8, 102, 173)',
    // '&:hover': {
    //   backgroundColor: 'rgb(16, 81, 130)',
    // },
  },
  buttonDelete: {
    color: 'white',
    margin: '2rem 1rem 2rem 1rem',
    padding: '.5rem 2rem',
    fontSize: '1.4rem',
    backgroundColor: 'rgb(199, 36, 42)',
    '&:hover': {
      backgroundColor: 'rgb(236,28,36)',
    },
  },
}))
export default CaseManagers
