import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AuthContext } from '../../contexts/authContext'
import { ProgramContext } from '../../contexts/programContext'
import PeopleCollaborating from '../../resources/images/PeopleCollaboratingBlue.png'

const RegionCards = ({ title, county, program }) => {
  const { authStatus } = useContext(AuthContext)
  const { setProgram } = useContext(ProgramContext)

  return (
    <RegionCardStyles>
      {(authStatus && authStatus === 3) || (authStatus && authStatus === 1) ? (
        <Link to="/" onClick={() => setProgram(program)}>
          <img src={PeopleCollaborating} alt="" />
          <h3 className="title">{title}</h3>
          <p className="county">{county}</p>
        </Link>
      ) : (
        <Link to="/signin" onClick={() => setProgram(program)}>
          <img src={PeopleCollaborating} alt="" />
          <h3 className="title">{title}</h3>
          <p className="county">{county}</p>
        </Link>
      )}
    </RegionCardStyles>
  )
}

const RegionCardStyles = styled.li`
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-bottom: 1rem;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s;
  &:hover {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  }
  & img {
    border-radius: 0.5rem 0.5rem 0 0;
    width: 100%;
    object-fit: cover;
    max-height: max(10rem, 30vh);
    aspect-ratio: 4/3;
  }
  & > :last-child {
    margin-bottom: 0;
  }

  .title {
    margin: 1rem 1.3rem;
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeTitle, fontSizeTitle);
    color: var(--black, black);
  }
  .county {
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeDescription, fontSizeDescription);
    margin: 1rem;
    color: var(--black, black);
  }
`

export default RegionCards
