import React from 'react'

import { TextField, Tooltip } from '@material-ui/core/'
import MaterialUiPhoneNumber from 'material-ui-phone-number'

export const Email: React.FunctionComponent<{ emailIsValid: boolean; setEmail: (_: string) => void }> = ({
  emailIsValid,
  setEmail,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={emailIsValid ? 'Email' : 'Invalid Email'}
      error={!emailIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEmail(evt.target.value)
      }}
    />
  )
}

export const Password: React.FunctionComponent<{
  label: string
  toolTip: boolean
  passwordIsValid: boolean
  setPassword: (_: string) => void
}> = ({ toolTip, label, passwordIsValid, setPassword }) => {
  return (
    <>
      {toolTip ? (
        <Tooltip
          placement="right"
          title={
            <span>
              <p>Password should be 9-64 characters and include the following:</p>
              <p> • At least one upper case letter</p>
              <p> • At least one lower case letter</p>
              <p> • At least one numeral</p>
              <p>• At least one special character</p>
            </span>
          }>
          <TextField
            fullWidth
            type="password"
            variant="outlined"
            label={passwordIsValid ? label : 'Invalid'}
            error={!passwordIsValid}
            onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
              setPassword(evt.target.value)
            }}
          />
        </Tooltip>
      ) : (
        <TextField
          fullWidth
          type="password"
          variant="outlined"
          label={passwordIsValid ? label : 'Invalid'}
          error={!passwordIsValid}
          onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
            setPassword(evt.target.value)
          }}
        />
      )}
    </>
  )
}

export const Firstname: React.FunctionComponent<{ firstnameIsValid: boolean; setFirstname: (_: string) => void }> = ({
  firstnameIsValid,
  setFirstname,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={firstnameIsValid ? 'First Name' : 'Invalid'}
      error={!firstnameIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFirstname(evt.target.value)
      }}
    />
  )
}

export const Lastname: React.FunctionComponent<{ lastnameIsValid: boolean; setLastname: (_: string) => void }> = ({
  lastnameIsValid,
  setLastname,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={lastnameIsValid ? 'Last Name' : 'Invalid'}
      error={!lastnameIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setLastname(evt.target.value)
      }}
    />
  )
}

export const Organization: React.FunctionComponent<{
  organizationIsValid: boolean
  setOrganization: (_: string) => void
}> = ({ organizationIsValid, setOrganization }) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={organizationIsValid ? 'Organization' : 'Invalid'}
      error={!organizationIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setOrganization(evt.target.value)
      }}
    />
  )
}


export const Phone: React.FunctionComponent<{
  phone: string
  phoneIsValid: boolean
  setPhone: (_: string) => void
}> = ({ phoneIsValid, setPhone, phone }) => {
  return (
    <MaterialUiPhoneNumber
      countryCodeEditable={false}
      defaultCountry="us"
      variant="outlined"
      value={phone}
      label={phoneIsValid ? 'Phone' : 'Required'}
      error={!phoneIsValid}
      onChange={setPhone}
      // onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
      //   console.log(evt)
      //   setPhone(evt.target.value)
      // }}
    />
    // <TextField
    //   type="number"
    //   inputProps={{
    //     maxLength: 10,
    //   }}
    //   fullWidth
    //   variant="outlined"
    //   label={phoneIsValid ? 'Phone' : 'Required'}
    //   error={!phoneIsValid}
    //   onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    //     setPhone(evt.target.value)
    //   }}
    // />
  )
}
export const PhoneExt: React.FunctionComponent<{ phoneExtIsValid: boolean; setPhoneExt: (_: string) => void }> = ({
  phoneExtIsValid,
  setPhoneExt,
}) => {
  return (
    <div style={{ marginLeft: '30px', display: 'flex', alignItems: 'center' }}>
      Ext.
      <TextField
        // fullWidth
        // type="number"
        style={{ marginLeft: '10px' }}
        variant="outlined"
        label={phoneExtIsValid ? 'Ext' : 'Maximum 7 characters'}
        error={!phoneExtIsValid}
        onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
          setPhoneExt(evt.target.value)
          // const onlyNums = evt.target.value.replace(/[^0-9]/g, '')
          // if (onlyNums.length < 10) {
          //   setPhoneExt(onlyNums)
          // } else if (onlyNums.length === 10) {
          //   const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
          //   setPhoneExt(number)
          // }
        }}
      />
    </div>
  )
}

export const Username: React.FunctionComponent<{ usernameIsValid: boolean; setUsername: (_: string) => void }> = ({
  usernameIsValid,
  setUsername,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={usernameIsValid ? 'Username' : 'Enter Valid Username'}
      error={!usernameIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setUsername(evt.target.value)
      }}
    />
  )
}

export const Code: React.FunctionComponent<{ codeIsValid: boolean; setCode: (_: string) => void }> = ({
  codeIsValid,
  setCode,
}) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={codeIsValid ? 'Code' : 'Minimum 6 characters'}
      error={!codeIsValid}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCode(evt.target.value)
      }}
    />
  )
}
