import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
// import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import { AuthContext } from '../contexts/authContext'
import { MentorContext } from '../contexts/mentorContext'
import { HomesContext } from '../contexts/homesContext'
import { ProgramContext } from '../contexts/programContext'

const Nav = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const { setMentorEvent } = useContext(MentorContext)
  const { setHomeToUpdate } = useContext(HomesContext)
  const { programs } = useContext(ProgramContext)

  const auth = useContext(AuthContext)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    setAnchorEl(null)
  }

  const history = useHistory()

  function signOutClicked() {
    auth.signOut()
    history.push('/welcome')
  }

  return (
    <div>
      <IconButton
        className={classes.root}
        aria-controls="fade-menu"
        aria-haspopup="true"
        aria-label="more"
        onClick={handleClick}>
        <AccountCircleIcon className={classes.icon} />
      </IconButton>

      {(auth.authStatus && auth.authStatus === 1) || (auth.authStatus && auth.authStatus === 3) ? (
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}>
          {auth.authStatus && auth.authStatus === 3 ? (
            <ul>
              <Link to="/welcome" onClick={signOutClicked}>
                <MenuItem className={classes.menuItem} onClick={handleClose}>
                  Logout
                </MenuItem>
              </Link>
              <Link to="/admin-homes/add">
                <MenuItem
                  className={classes.menuItem}
                  onClick={(event) => {
                    setAnchorEl(null)
                    setHomeToUpdate([])
                  }}>
                  Add Home
                </MenuItem>
              </Link>
              <Link to="/admin-mentor-events/add">
                {/* SOLUTION 2 */}
                <MenuItem
                  className={classes.menuItem}
                  onClick={(event) => {
                    setAnchorEl(null)
                    setMentorEvent([])
                  }}>
                  {/* SOLUTION 1 */}
                  {/* <MenuItem className={classes.menuItem} onClick={handleClose}> */}
                  Add Event
                </MenuItem>
              </Link>
              <Link to="/admin-case-managers">
                <MenuItem className={classes.menuItem} onClick={handleClose}>
                  Case Managers
                </MenuItem>
              </Link>
              <Link to="/">
                <MenuItem className={classes.menuItem} onClick={handleClose}>
                  Landing
                </MenuItem>
              </Link>
              <Link to="/welcome">
                <MenuItem className={classes.menuItem} onClick={handleClose}>
                  Select Region
                </MenuItem>
              </Link>
            </ul>
          ) : (
            <Link to="/welcome" onClick={signOutClicked}>
              <MenuItem className={classes.menuItem} onClick={handleClose}>
                Logout
              </MenuItem>
            </Link>
          )}
        </Menu>
      ) : (
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}>
          {programs && programs.length > 1 ? (
            <Link to="/region">
              <MenuItem className={classes.menuItem} onClick={handleClose}>
                Sign In
              </MenuItem>
            </Link>
          ) : (
            <Link to="/signin">
              <MenuItem className={classes.menuItem} onClick={handleClose}>
                Sign In
              </MenuItem>
            </Link>
          )}
          <Link to="/register">
            <MenuItem className={classes.menuItem} onClick={handleClose}>
              Register
            </MenuItem>
          </Link>
        </Menu>
      )}
    </div>
  )
}
const useStyles = makeStyles(() => ({
  root: {
    padding: '5px',
  },
  menuItem: {
    fontSize: 'var(--fontDescription, fontDescription)',
  },
  icon: {
    cursor: 'pointer',
    color: 'var(--white, white)',
    fontSize: '3.3rem',
  },
}))
export default Nav
