import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import FacebookIcon from '@material-ui/icons/Facebook'
import YouTubeIcon from '@material-ui/icons/YouTube'
import TwitterIcon from '@material-ui/icons/Twitter'

const GlobalFooter = () => {
  const classes = useStyles()
  return (
    <GlobalFooterStyles>
      <div className="footer-container-top">
        <div className="footer-top-links">
          <a href="https://makeadifferenceathome.com/get-started/" rel="noreferrer" target="_blank">
            Get Started
          </a>
          <a href="https://www.thementornetwork.com/" rel="noreferrer" target="_blank">
            The MENTOR Network
          </a>
          <a href="https://www.thementornetwork.com/privacy-policy/" rel="noreferrer" target="_blank">
            Privacy Policy
          </a>
          <a href="https://www.thementornetwork.com/legal-statement/" rel="noreferrer" target="_blank">
            Legal
          </a>
          <a href="https://jobs.thementornetwork.com/us/en" rel="noreferrer" target="_blank">
            Careers
          </a>
        </div>
        <div className="social-icons">
          <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/The_MENTOR_Ntwk">
            <div className="icon-container">
              <TwitterIcon className={classes.icon} />
            </div>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/likementor">
            <div className="icon-container">
              <FacebookIcon className={classes.icon} />
            </div>
          </a>

          <a target="_blank" rel="noopener noreferrer" href="http://www.youtube.com/user/TheMENTORNetwork">
            <div className="icon-container">
              <YouTubeIcon className={classes.icon} />
            </div>
          </a>
        </div>
      </div>
      <div className="footer-container-bottom">
        <div className="sevita-container">
          <div className="rights">
            The MENTOR Network is becoming Sevita. Learn more at {' '}
            <a href="https://sevitahealth.com/" rel="noreferrer" target="_blank">
              SevitaHealth.com
            </a>
          </div>
        </div>
        <div className="email-container">
          <div className="rights">Email us</div>
          <div className="privacy-container left-border">
            <a href="mailto:changealife@thementornetwork.com" rel="noreferrer" target="_blank">
              changealife@thementornetwork.com
            </a>
          </div>
        </div>
        <div className="copy-container">
          <div className="rights">© 2021 Make a Difference at Home. All Rights Reserved.</div>
        </div>
      </div>
    </GlobalFooterStyles>
  )
}

const GlobalFooterStyles = styled.div`
  position: relative;
  .footer-container-top {
    position: relative;
    display: flex;
    flex-direction: row;
    background: #525251 url(../images/footer.png) 0 0 repeat;
    justify-content: space-around;
    align-items: center;
    padding: 2rem 0;
    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  .footer-top-links a {
    font-family: var(--fontPrimary, fontPrimary);
    color: #ababa1;
    padding: 0 10px 0 10px;
    transition: all 0.2s;
    &:hover {
      color: var(--white, white);
    }
  }
  .social-icons {
    display: flex;
    @media (max-width: 800px) {
      margin-top: 2rem;
    }
  }

  .footer-container-bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    background: #616161 url(../images/footer.png) 0 0 repeat;
  }
  .sevita-container {
    width: 100%;
    max-width: 1240px;
    display: flex;
    flex-direction: row;
    margin: 3rem auto;
    margin-top: 5rem;
    justify-content: left;
    align-content: center;
    font-size:1.8rem;
  }
  .sevita-container a {
    font-family: var(--fontPrimary, fontPrimary);
    color: var(--white, white);
    padding: 0 5px 0 5px;
    transition: all 0.2s;
    &:hover {
      color: var(--secondary, secondary);
    }
  }
  .email-container {
    width: 100%;
    max-width: 1240px;
    display: flex;
    flex-direction: row;
    margin: 1rem auto;
    margin-top: 0;
    justify-content: left;
    align-content: center;
  }
  .copy-container {
    width: 100%;
    max-width: 1240px;
    display: flex;
    flex-direction: row;
    margin: 5rem auto;
    margin-top: 1rem;
    justify-content: left;
    align-content: center;
  }
  .rights {
    color: #ababa1;
  }
  .privacy-container {
    margin-left: 0.5rem;
  }
  .privacy-container a {
    font-family: var(--fontPrimary, fontPrimary);
    color: var(--white, white);
    padding: 0 5px 0 5px;
    transition: all 0.2s;
    &:hover {
      color: var(--secondary, secondary);
    }
  }
  .left-border {
    border-left: 1px solid #878780;
    transition: all 0.2s;
    &:hover {
      color: var(--secondary, secondary);
    }
    padding: 0 1px 0 1px;
  }
`
const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '2rem',
    margin: ' 0 1rem',
    color: 'rgb(254,254,254)',
    transition: '0.3s',
    '&:hover': {
      color: 'rgb(255,127,39)',
    },
  },
}))

export default GlobalFooter
