import React, { useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import { AuthContext } from '../../../contexts/authContext'

const useStyles = makeStyles(() => ({
  link: {
    color: 'rgb(254,254,254)',
    fontWeight: 400,
    borderBottom: '1px solid #353535',
    padding: '18px 48px',
    fontSize: '16px',
  },
}))

const AdminNav = (props) => {
  const classes = useStyles()
  const auth = useContext(AuthContext)
  const history = useHistory()

  const links = [
    {
      title: 'Case Managers',
      linkTo: '/admin-case-managers',
    },
    {
      title: 'Homes',
      linkTo: '/home-list',
    },
    {
      title: 'Landing',
      linkTo: '/',
    },
  ]
  function signOutClicked() {
    auth.signOut()
    history.push('/welcome')
  }

  const renderItems = () =>
    links.map((link) => (
      <Link to={link.linkTo} key={link.title}>
        <ListItem button className={classes.link}>
          {link.title}
        </ListItem>
      </Link>
    ))

  return (
    <div>
      {renderItems()}
      <ListItem button className={classes.link} onClick={signOutClicked}>
        Log out
      </ListItem>
    </div>
  )
}

export default withRouter(AdminNav)
