import React, { Fragment, useEffect, useContext } from 'react'

import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// import RoomIcon from '@material-ui/icons/Room'
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus'
import TrainIcon from '@material-ui/icons/Train'
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import StoreIcon from '@material-ui/icons/Store'
import NatureIcon from '@material-ui/icons/Nature'
// import LocalMallIcon from '@material-ui/icons/LocalMall'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import Navigation from '../resources/images/Navigation.png'
import { HomesContext } from '../contexts/homesContext'

import Header from '../components/layout/Header'

const Map = () => {
  const classes = useStyles()
  const { homeDetails } = useContext(HomesContext)
  const { mapInfo } = homeDetails.home
  const { ptInfo } = homeDetails.home

  useEffect(() => {
    document.title = 'Map | California Mentor'
  }, [])
  return (
    <Fragment>
      <Header back />
      <HomeDetailMapStyles>
        <div className="title-container">
          <h1 className="title-container-heading">Surrounding Area</h1>
        </div>
        <div className="map-container">
          <div className="map-image">
            <img src={mapInfo.mapImage === '$$$$$' ? Navigation : mapInfo.mapImage} alt="" />
          </div>
          <div className="map-details">
            <div className="location-details">
              <div className="icon-container">
                <LocalHospitalIcon className={classes.icon} style={{ color: 'rgb(199, 36, 42)' }} />
              </div>

              <p className="location-distance">
                Nearest Hospital -{' '}
                {mapInfo.nearesthospital === '$$$$$' ? 'Pending' : `${mapInfo.nearesthospital} miles`}
              </p>
            </div>
            <div className="location-details">
              <div className="icon-container">
                <StoreIcon className={classes.icon} style={{ color: 'rgb(255,127,39)' }} />
              </div>
              <p className="location-distance">
                Nearest Stores - {mapInfo.nearestStore === '$$$$$' ? 'Pending' : `${mapInfo.nearestStore} miles`}
              </p>
            </div>
            <div className="location-details">
              <div className="icon-container">
                <NatureIcon className={classes.icon} style={{ color: 'rgb(16,108,103)' }} />
              </div>
              <p className="location-distance">
                Nearest park - {mapInfo.nearestPark === '$$$$$' ? 'Pending' : `${mapInfo.nearestPark} miles`}
              </p>
            </div>
            <div className="location-details">
              <div className="icon-container">
                <LocationCityIcon className={classes.icon} style={{ color: 'rgb(66, 66, 66)' }} />
              </div>
              <p className="location-distance">
                Day Program - {mapInfo.dayProgram === '$$$$$' ? 'Pending' : `${mapInfo.dayProgram} miles`}
              </p>
            </div>
          </div>
        </div>
        {ptInfo.bike[0] === '$$$$$' && ptInfo.bus[0] === '$$$$$' && ptInfo.train[0] === '$$$$$' ? (
          <div style={{ marginBottom: '20px' }}></div>
        ) : (
          <div className="transportation-container">
            <div className="title">Public Transportation</div>

            <div className="transportation-options">
              {(ptInfo.bus[0] === '$$$$$' && ptInfo.bus[1] === '$$$$$') ||
              ptInfo.bus[1] === '$$$$$' ||
              ptInfo.bus[0] === '$$$$$' ? null : (
                <div className="transportation-type-container">
                  <h3 className="transportation-type-name">Bus</h3>
                  <p className="transportation-type-desc">{ptInfo.bus[0]}</p>
                  <div>
                    {ptInfo.bus[1].startsWith('https://') ? (
                      <a href={ptInfo.bus[1]} rel="noreferrer" target="_blank">
                        <Button className={classes.buttonOutline} color="primary" size="large" variant="contained">
                          <DirectionsBusIcon className={classes.iconMap} />
                          <span>AC Transit</span>
                        </Button>
                      </a>
                    ) : (
                      <Button
                        className={classes.buttonOutline}
                        color="primary"
                        disabled
                        size="large"
                        variant="contained">
                        <DirectionsBusIcon className={classes.iconMap} />
                        <span>AC Transit</span>
                      </Button>
                    )}
                  </div>
                </div>
              )}
              {(ptInfo.train[0] === '$$$$$' && ptInfo.train[1] === '$$$$$') ||
              ptInfo.train[1] === '$$$$$' ||
              ptInfo.train[0] === '$$$$$' ? null : (
                <div className="transportation-type-container">
                  <h3 className="transportation-type-name">Train</h3>
                  <p className="transportation-type-desc">{ptInfo.train[0]}</p>
                  <div>
                    {ptInfo.train[1].startsWith('https://') ? (
                      <a href={ptInfo.train[1]} rel="noreferrer" target="_blank">
                        <Button className={classes.buttonOutline} color="primary" size="large" variant="contained">
                          <TrainIcon className={classes.iconMap} />
                          <span>Bart</span>
                        </Button>
                      </a>
                    ) : (
                      <Button
                        className={classes.buttonOutline}
                        color="primary"
                        size="large"
                        disabled
                        variant="contained">
                        <TrainIcon className={classes.iconMap} />
                        <span>Bart</span>
                      </Button>
                    )}
                  </div>
                </div>
              )}
              {(ptInfo.bike[0] === '$$$$$' && ptInfo.bike[1] === '$$$$$') ||
              ptInfo.bike[1] === '$$$$$' ||
              ptInfo.bike[0] === '$$$$$' ? null : (
                <div className="transportation-type-container">
                  <h3 className="transportation-type-name">Bike Rental</h3>
                  <p className="transportation-type-desc">{ptInfo.bike[0]} </p>
                  <div>
                    {ptInfo.bike[1].startsWith('https://') ? (
                      <a href={ptInfo.bike[1]} rel="noreferrer" target="_blank">
                        <Button color="primary" size="large" variant="outlined">
                          <DirectionsBikeIcon className={classes.iconMap} />
                          <span>Bike Rental</span>
                        </Button>
                      </a>
                    ) : (
                      <Button color="primary" size="large" disabled variant="outlined">
                        <DirectionsBikeIcon className={classes.iconMap} />
                        <span>Bike Rental</span>
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </HomeDetailMapStyles>
    </Fragment>
  )
}

const HomeDetailMapStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1000px;
  margin: 5rem auto;
  padding: 0 5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  .title-container {
    margin: 3rem 0;
    text-align: left;
  }
  .title-container-heading {
    font-family: var(--fontPrimary, fontPrimary);
    font-size: 3rem;
    color: var(--black, black);
  }

  .map-container {
    display: flex;
    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }
  & img {
    border-radius: 0.5rem 0.5rem 0 0;
    width: 100%;
    object-fit: contain;
    max-height: max(10rem, 30vh);
    /* aspect-ratio: 4/3; */
  }
  .map-details {
    /* padding: 2rem; */
  }
  .location-details {
    display: flex;
    flex-direction: row;
    align-content: center;
    margin: 2rem;
  }

  .location-distance {
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeDescription, fontSizeDescription);
    color: var(--black, black);
    line-height: 1.6;
  }
  .transportation-container {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    /* padding:2rem; */
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  }
  .title {
    font-family: var(--fontPrimary, fontPrimary);
    font-size: 2.5rem;
    color: var(--black, black);
    font-weight: 700;
  }
  .transportation-options {
    display: flex;
    margin: 2rem 0;
    justify-content: space-between;
  }
  .transportation-type-container {
    max-width: 300px;
  }
  .transportation-type-container:not(:first-child) {
    margin-left: 5rem;
  }
  .transportation-type-name {
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeDescription, fontSizeDescription);
    color: var(--black, black);
    margin: 1rem 0;
  }
  .transportation-type-desc {
    font-family: var(--fontPrimary, fontPrimary);
    font-size: 1.5rem;
    color: var(--black, black);
    margin: 1rem 0;
  }
  .program-details {
    background-color: var(--lightGrey, lightGrey);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 10px;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .program-name {
    font-family: var(--fontPrimary, fontPrimary);
    font-size: var(--fontSizeDescription, fontSizeDescription);
    color: var(--primary, primary);
    margin-bottom: 1rem;
  }
  .program-info {
    font-family: var(--fontPrimary, fontPrimary);
    font-size: 1.5rem;
    color: var(--black, black);
    margin: 0.2rem 0;
  }
`
const useStyles = makeStyles(() => ({
  button: {
    color: 'white',
    margin: '1rem',
    padding: '.8rem 5rem',
    fontSize: '1.5rem',
    backgroundColor: 'rgb(255,127,39)',
    '&:hover': {
      backgroundColor: 'rgb(243, 134, 56)',
    },
  },
  buttonOutline: {
    margin: '1rem 0',
    padding: '.5rem 4rem',
    fontSize: '1.5rem',
    // backgroundColor: "rgb(255,127,39)",
    // "&:hover": {
    //   backgroundColor: "rgb(243, 134, 56)",
    // },
  },
  icon: {
    fontSize: '3rem',
    margin: ' 0 1rem',
  },
  iconMap: {
    fontSize: '2.2rem',
    margin: ' 0 .5rem',
  },
}))
export default Map
