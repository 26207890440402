import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { Box, CircularProgress, Grid, Button, Typography, Paper } from '@material-ui/core/'
import loginImage from '../../resources/images/Password.png'

import { useValidPassword, useValidUsername } from '../../hooks/useAuthHooks'
import { Password, Username } from '../../components/authComponents'

import { AuthContext } from '../../contexts/authContext'
import { useValidCode } from '../../hooks/useAuthHooks'
import { Code } from '../../components/authComponents'

const SignIn: React.FunctionComponent<{}> = () => {
  const classes = useStyles()
  const [isLoading, setLoading] = useState(false)
  const { code, setCode, codeIsValid } = useValidCode('')
  const { username, setUsername, usernameIsValid } = useValidUsername('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const [error, setError] = useState('')
  const [getCode, setGetCode] = useState(false)

  const isValid = !usernameIsValid || username.length === 0 || !passwordIsValid || password.length === 0

  const history = useHistory()
  const authContext = useContext(AuthContext)

  useEffect(() => {
    document.title = 'Sign In | California Mentor'
  }, [])
  /////////////////////// CUSTOM MFA CONFIG NEEDS TO BE FINISHED/////////////////////////////
  // const getMfaCode =  () => {
  //   console.log("🚀 ~ file: signIn.tsx ~ line 75 ~ getMfaCode ~ getMfaCode")
  //   setGetCode(true)
  // }
  // const setMfaCode = async () => {
  //   // setCode(code)
  //   console.log("🚀 ~ file: signIn.tsx ~ line 98 ~ setMfaCode ~ setMfaCode")
  //   try {
  //     console.log("🚀 ~ file: signIn.tsx ~ line 98 ~  CODE SENT START", code)
  //     await authContext.sendMfaCode( code)
  //     console.log("🚀 ~ file: signIn.tsx ~ line 98 ~  CODE SENT FINISH", code)
  //       // history.push('landing')
  //   } catch (err) {
  //     if (err.code === 'UserNotConfirmedException') {
  //       // history.push('verify')
  //     } else {
  //       setError(err.message)
  //     }
  //   }
  // }
  // const signInClicked = async () => {
  //   try {
  //     console.log("🚀 ~ file: signIn.tsx ~ line 122 ~ START SIGN IN CLICKED ~ signInClicked")
  //     await authContext.signInWithEmail(username, password, getMfaCode)
  //     // setGetCode(true)
  //     console.log("🚀 ~ file: signIn.tsx ~ line 122 ~ FINISH SIGN IN CLICKED ~ signInClicked")
  //       history.push('landing')
  //   } catch (err) {
  //     if (err.code === 'UserNotConfirmedException') {
  //       // history.push('verify')
  //     } else {
  //       setError(err.message)
  //     }
  //   }
  // }
  /////////////////////// CUSTOM MFA CONFIG NEEDS TO BE FINISHED/////////////////////////////
  // useEffect(() => {
  //   const listener = (event: { code: string; preventDefault: () => void }) => {
  //     if (isValid) {
  //       if (event.code === 'Enter' || event.code === 'NumpadEnter') {
  //         console.log('Enter key was pressed. Run your function.')
  //         event.preventDefault()
  //         signInClicked()
  //       }
  //     }
  //     document.addEventListener('keydown', listener)
  //     return () => {
  //       document.removeEventListener('keydown', listener)
  //     }
  //   }
  // }, [isValid])
  // const pressEnter = (event: any) => {
  //   console.log('enter', event)
  //   if (event.keyCode === 13) {
  //     console.log('enter')
  //   }
  // }

  const signInClicked = async (e: any) => {
    e.preventDefault()
    try {
      setLoading(true)
      const lowercaseUsername = username.toLowerCase()
      await authContext.signInWithEmail(lowercaseUsername, password)
      setLoading(false)

      history.push('/')
    } catch (err: any) {
      if (err.code === 'UserNotConfirmedException') {
        history.push('verify')
      } else {
        setError(err.message)
        setLoading(false)
      }
    }
    setLoading(false)
  }

  // const codeClicked = async () => {
  //   try {
  //     await authContext.signInWithEmail(username, password, code)
  //      history.push('home')
  //   } catch (err) {
  //     setError(err.message)
  //   }
  // }

  const passwordResetClicked = async () => {
    history.push('requestcode')
  }
  const signInRender = (
    <>
      <Box m={1}>
        <Typography className={classes.title} variant="h3">
          California MENTOR
        </Typography>
      </Box>
      <Box m={1}>
        <Typography className={classes.subtitle} variant="h4">
          FHA HOME LIFE
        </Typography>
      </Box>
      <Box m={1}>
        <LoginImageStyles>
          <img src={loginImage} alt="" />
        </LoginImageStyles>
      </Box>

      {/* Sign In Form */}

      <form style={{ width: '80%' }} onSubmit={signInClicked}>
        <Box width="100%" m={1}>
          {/* <Email emailIsValid={emailIsValid} setEmail={setEmail} /> */}
          <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
        </Box>
        <Box width="100%" m={1}>
          <Password toolTip={false} label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Box onClick={passwordResetClicked} mt={2}>
              <Typography className={classes.hover} variant="body2">
                Forgot Password?
              </Typography>
            </Box>
          </Grid>
        </Box>

        {/* Error */}
        <Box mt={2}>
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        </Box>

        {/* Buttons */}
        <Box mt={2}>
          <Grid container direction="row" justifyContent="center">
            <Box m={1}>
              <Button color="secondary" variant="contained" onClick={() => history.goBack()}>
                Cancel
              </Button>
            </Box>
            <Box m={1}>
              {/* <Button disabled={isValid} color="primary" variant="contained" onClick={signInClicked}> */}
              <Button
                type="submit"
                // onKeyDown={(e) => pressEnter(e)}
                disabled={isValid}
                color="primary"
                variant="contained"
                onClick={signInClicked}>
                {isLoading && (
                  <>
                    <h4 style={{ marginRight: '10px', fontFamily: 'Roboto', fontSize: '15px' }}>Sign In</h4>{' '}
                    <CircularProgress style={{ color: 'white' }} size={24} />{' '}
                  </>
                )}
                {!isLoading && 'Sign In'}
              </Button>
            </Box>
          </Grid>
        </Box>
      </form>
      <Box mt={2}>
        <Box onClick={() => history.push('register')}>
          <Typography className={classes.hover} variant="body1">
            Register a new account
          </Typography>
        </Box>
      </Box>
    </>
  )

  const enterCode = (
    <>
      <Box m={3}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Typography style={{ color: 'rgb(66, 66, 66)', fontWeight: 500, fontFamily: 'Roboto' }} variant="h3">
            Enter Code
          </Typography>
        </Grid>
      </Box>
      <Box width="80%" m={1}>
        <Code codeIsValid={codeIsValid} setCode={setCode} />
      </Box>

      {/* Error */}
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>
      {/* 
      <Box m={1}>
 
        <Button disabled={isValid} color="primary" variant="contained" onClick={setMfaCode}>
          Confirm
        </Button>
      </Box> */}
    </>
  )
  return (
    <Grid className={classes.root} container direction="row" justifyContent="center" alignItems="center">
      <Grid xs={11} sm={6} lg={4} container direction="row" justifyContent="center" alignItems="center" item>
        <Paper className={classes.paper} style={{ width: '100%', padding: 32 }}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            {/* Title */}

            {!getCode ? signInRender : enterCode}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles({
  root: {
    // height: '100vh',
    // overflow: "scroll",
    margin: '5rem 0',
  },
  paper: {
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  },
  hover: {
    '&:hover': { cursor: 'pointer' },
  },
  title: {
    color: 'rgb(66, 66, 66)',
    fontWeight: 700,
    fontFamily: 'Roboto',
  },
  subtitle: {
    color: 'rgb(8, 102, 173)',
    fontWeight: 700,
    fontFamily: 'Roboto',
  },
})
const LoginImageStyles = styled.div`
  /* background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-bottom: 1rem;
  overflow: hidden;
  text-align: center; */

  & img {
    /* border-radius: 0.5rem 0.5rem 0 0; */
    width: 100%;
    object-fit: contain;
    max-height: max(10rem, 40vh);
    aspect-ratio: 4/3;
  }
`
export default SignIn
