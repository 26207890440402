import React, { useEffect, useContext, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'

import { getAuthHeader, BASE_URL } from '../libs/tools'
import { converRegionsObjectToArray } from '../libs/Utils'
import Loader from '../libs/loader'

import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'

import { RegionContext } from '../contexts/regionContext'
import { ProgramContext } from '../contexts/programContext'
import { HomesContext } from '../contexts/homesContext'
import { CaseManagerInfoContext } from '../contexts/caseManagerInfoContext'
import { AuthContext } from '../contexts/authContext'

const RegionSelectApiDropdown = ({ outlined, label, width200, font18 }) => {
  const [open, setOpen] = useState(false)
  const [isLoadingRegions, setLoadingRegions] = useState(true)
  const { regions, setRegion, setRegions, region, setCurrentRegion } = useContext(RegionContext)
  const { setPrograms, setProgram, setLoadingPrograms } = useContext(ProgramContext)
  const { caseManagerInfo } = useContext(CaseManagerInfoContext)
  const { authStatus } = useContext(AuthContext)
  const {
    setAvailablePaginated,
    setComingSoonPaginated,
    setUnavailablePaginated,
    setPreferenceSearch,
    setHomePreferences,
    homePreferences,
  } = useContext(HomesContext)
  const isAdmin = authStatus && authStatus === 3
  const isCaseManager = authStatus && authStatus === 1

  useEffect(() => {
    const getRegionsData = async () => {
      try {
        const statusData = {
          orgState: 'ORG#CSS#ST#CA',
        }
        const response = await axios.post(`${BASE_URL}/casemanagers/getRegions`, statusData, getAuthHeader())
        const regionResponseConverted = converRegionsObjectToArray(response.data.regions)
        setRegions(regionResponseConverted)
        setRegion(regionResponseConverted[0])
        setCurrentRegion(regionResponseConverted[0])

        setLoadingRegions(false)
      } catch (err) {
        setLoadingRegions(true)
      }
    }

    if (!regions || regions.length === 0) {
      getRegionsData()
    } else {
      setLoadingRegions(false)
    }
    // getRegionsData()
  }, [regions])

  useEffect(() => {
    const getProgramData = async () => {
      try {
        const statusData = {
          // region: 'ORG#CSS#ST#CA#REG#Golden Gate Regional Center,
          region: `ORG#CSS#ST#CA#REG#${region}`,
        }

        const response = await axios.post(`${BASE_URL}/casemanagers/getPrograms`, statusData, getAuthHeader())
        const returnedPrograms = response.data.programs
        setPrograms(returnedPrograms)
        setProgram(returnedPrograms[0])
        setLoadingPrograms(false)
        // if (homePreferences && homePreferences.length > 1 || homePreferences !== undefined) {
        //   setHomePreferences([])
        //   setPreferenceSearch(false)
        // }
        if (isAdmin || isCaseManager) {
          setHomePreferences([])
          setPreferenceSearch(false)
          setAvailablePaginated(false)
          setComingSoonPaginated(false)
          setUnavailablePaginated(false)
        }
      } catch (err) {
        setLoadingPrograms(true)
      }
    }
    if (region) {
      getProgramData()
    }
  }, [region])

  const ITEM_HEIGHT = 40
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    // anchorOrigin: {
    //   vertical: 'bottom',
    // },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: `${width200 ? '200' : '500'}`,
        // transform: 'translateY(100px)',
      },
    },
  }

  const regionsSort = regions.sort((a, b) => (a > b ? 1 : -1))

  const handleChange = (event) => {
    setRegion(event.target.value)
    setCurrentRegion(event.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  return (
    <SelectStyles>
      {isLoadingRegions ? (
        <Loader />
      ) : (
        <>
          {caseManagerInfo && caseManagerInfo.length !== 0 ? (
            <FormControl
              style={{
                fontSize: `${font18 ? '1.8rem' : '1.5rem'}`,
                // top: '10%',
                // paddingRight: '102px',
              }}>
              {regions && region && (
                <>
                  <InputLabel>{label}</InputLabel>
                  <Select
                    id="region"
                    name="region"
                    variant={outlined ? 'outlined' : 'standard'}
                    MenuProps={MenuProps}
                    displayEmpty
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={region}
                    onChange={handleChange}
                    style={{
                      fontSize: `${font18 ? '1.8rem' : '1.5rem'}`,
                    }}>
                    <MenuItem disabled value={region}>
                      <em>{region}</em>
                    </MenuItem>
                  </Select>
                </>
              )}
            </FormControl>
          ) : (
            <FormControl
              style={{
                fontSize: `${font18 ? '1.8rem' : '1.5rem'}`,
                // top: '10%',
                // paddingRight: '102px',
              }}>
              {regions && region && (
                <>
                  <InputLabel>{label}</InputLabel>
                  <Select
                    fullWidth
                    id="region"
                    name="region"
                    variant={outlined ? 'outlined' : 'standard'}
                    MenuProps={MenuProps}
                    displayEmpty
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={region}
                    onChange={handleChange}
                    style={{
                      fontSize: `${font18 ? '1.8rem' : '1.5rem'}`,
                    }}>
                    {regionsSort.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={{
                          fontSize: `${font18 ? '1.8rem' : '1.5rem'}`,
                        }}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </FormControl>
          )}
        </>
      )}
    </SelectStyles>
  )
}
const SelectStyles = styled.div`
  .MuiSelect-select.MuiSelect-select {
    padding-right: 103px;
  }
`

export default RegionSelectApiDropdown
